import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import Countdown, { zeroPad } from 'react-countdown'

import { MeetContext } from '../MeetContext'
import * as actions from '../../../store/actions/meeting'
import toastNotification from '../../utils/Notification/Notification'
import getIcon from '../../../utils/getIcon'
import { ONE_ROLE_TIME_SLOT_IN_MILLISECONDS } from '../../../constants/vars'
import './timer.css'

let currentTime = Date.now()

const timerRenderer = ({ minutes, seconds, completed, api }) => {
    if (completed) {
        // when countdown is ended
        return null
    } else {
        //when countdown is going on
        if (api.isStopped()) {
            api.start()
        }

        return (
            <Typography variant="body1" className="timer__text timer">
                {zeroPad(minutes)}:{zeroPad(seconds)}
            </Typography>
        )
    }
}

const Timer = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const { interviewStartTime, isFlip, roleFlipTime } = useSelector(
        (state) => state.meeting
    )
    const { questionsSidebar } = useSelector((state) => state.ui)

    const { userVideoRef, userScreenRef, meetInfo } = useContext(MeetContext)
    const [timeRemaining, setTimeRemaining] = useState(0)

    // setting the remaining time of the interview
    useEffect(() => {
        let timeLeft = 0
        currentTime = Date.now()

        if (isFlip) {
            const secondRoleEndTime = roleFlipTime + ONE_ROLE_TIME_SLOT_IN_MILLISECONDS
            timeLeft = secondRoleEndTime - Date.now()
        } else {
            const firstRoleEndTime =
                interviewStartTime + ONE_ROLE_TIME_SLOT_IN_MILLISECONDS
            timeLeft = firstRoleEndTime - Date.now()
        }

        setTimeRemaining(timeLeft)
    }, [isFlip, roleFlipTime, interviewStartTime])

    const handleMeetEnd = () => {
        if (userVideoRef.current && userVideoRef.current.srcObject) {
            userVideoRef.current.srcObject.getTracks().forEach((track) => {
                track.stop()
            })
            userVideoRef.current.srcObject = null
        }
        if (userScreenRef.current && userScreenRef.current.srcObject) {
            userScreenRef.current.srcObject.getTracks().forEach((track) => {
                track.stop()
            })
            userScreenRef.current.srcObject = null
        }
        dispatch(actions.expireMeet(meetInfo.roomID))
        history.push('/feedback')
    }

    // to show 5 minutes remaining notification for each role slot
    const handleCountdownTick = ({ minutes, seconds }) => {
        if (parseInt(minutes) === 5 && parseInt(seconds) === 0) {
            if (isFlip) {
                // when the 2nd role slot is about to end
                toastNotification(
                    'The interview will end in 5 minutes! Your feedback will be saved automatically.'
                )
            } else {
                // when the 1st role slot is about to end
                toastNotification(
                    'You are about to switch roles! Please wrap up the interview. Your feedback will be saved automatically in 5 minutes.'
                )
            }
        }
    }

    // when countdown is ended
    const handleCountdownEnd = () => {
        if (isFlip) {
            // when both roles slot is ended
            toastNotification('Time is up! Submit your feedback.')
            handleMeetEnd()
        } else {
            // when first role slot is ended
            dispatch(actions.swapRole(meetInfo))
        }
    }

    return (
        <div className={`timer ${questionsSidebar && 'shiftTimer'}`}>
            {getIcon('CLOCK_TIMER')}
            {Number(timeRemaining) > 0 ? (
                <>
                    <Typography
                        variant="body1"
                        className="timer__text mobile-hide"
                        marginRight="8px"
                    >
                        Time Left :
                    </Typography>
                    <Countdown
                        date={currentTime + timeRemaining}
                        renderer={timerRenderer}
                        onTick={handleCountdownTick}
                        onComplete={handleCountdownEnd}
                    />
                </>
            ) : (
                <Typography variant="body1" className="timer__text">
                    Time Up!
                </Typography>
            )}
        </div>
    )
}

export default Timer

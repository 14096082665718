import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './Notification.css'

const NotificationContainer = () => {
    return (
        <ToastContainer
            position="top-right"
            autoClose={10000}
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            transition={Slide}
            role="Notification"
            theme="light"
            toastClassName="notificationToast"
            // pauseOnHover
            // limit={1}
            // pauseOnFocusLoss
            // draggable
        />
    )
}

export default NotificationContainer

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
    LinearProgress,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Typography,
    Dialog,
} from '@mui/material'
import { enGB } from 'date-fns/locale'
import { DatePicker } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'
import Grid from '@mui/material/Grid'
import moment from 'moment'

import ButtonComp from '../../../utils/ButtonComp/ButtonComp'
import toastNotification from '../../../utils/Notification/Notification'
import { toggleSchedulerModal } from '../../../../store/actions/ui'
import * as actions from '../../../../store/actions/interview'
import { scheduleInterview } from '../../../../store/actions/scheduler'
import { INTERVIEW_TYPES } from '../../../../constants/vars'
import './Scheduler.css'

const getMaxTimeForUser = () => {
    const maxhour = Math.max(
        moment.utc('2021-12-15T14:00:00Z').local().format('HH'),
        moment.utc('2021-12-15T20:00:00Z').local().format('HH'),
        moment.utc('2021-12-15T02:00:00Z').local().format('HH'),
        moment.utc('2021-12-15T08:00:00Z').local().format('HH')
    )
    if (String(maxhour) === moment.utc('2021-12-15T14:00:00Z').local().format('HH'))
        return moment.utc('2021-12-15T14:00:00Z').local().format('HH:mm')
    else if (String(maxhour) === moment.utc('2021-12-15T20:00:00Z').local().format('HH'))
        return moment.utc('2021-12-15T20:00:00Z').local().format('HH:mm')
    else if (
        String(maxhour) === moment.utc('2021-12-15T02:00:00Z').local().format('HH')
    ) {
        return moment.utc('2021-12-15T02:00:00Z').local().format('HH:mm')
    } else {
        return moment.utc('2021-12-15T08:00:00Z').local().format('HH:mm')
    }
}

const offsetTimezone = (offset) => {
    return moment.utc(`2021-12-15T${offset}:00:00Z`).local().format('HH:mm')
}

const comparator = (a, b) => {
    if (a.timezone < b.timezone) return -1
    if (a.timezone > b.timezone) return 1
    return 0
}

const redirectToLoginPage = () => {
    setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_AUTHDOM}/signin?to=${window.location.href}profile`
    }, 3000)
}

const today = new Date()
const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1))

const Scheduler = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { schedulerModal } = useSelector((state) => state.ui)
    const { loading: schedulerLoading } = useSelector((state) => state.scheduler)
    const { upcomingInterviews, fetchInterviewsLoading } = useSelector(
        (state) => state.interview
    )
    const { loading: fetchAllMeetingsLoading } = useSelector((state) => state.meetings)
    const { user } = useSelector((state) => state.userInfo)
    const [selectTime, setSelectTime] = useState('')
    const [interviewType, setInterviewType] = useState('')
    const [ignoreExp, setIgnoreExp] = useState(false)
    const [date, setDate] = useState(
        String(moment().add('1', 'hour').format('HH:mm')) < getMaxTimeForUser()
            ? today
            : tomorrow
    )
    const [minDate] = useState(
        String(moment().add('1', 'hour').format('HH:mm')) < getMaxTimeForUser()
            ? today
            : tomorrow
    )
    const [maxDate] = useState(new Date(new Date().setDate(new Date().getDate() + 30)))
    useEffect(() => {
        if (schedulerModal) {
            if (user && user.email && user.yoe && user.verified && user.phoneVerified) {
                dispatch(actions.getUpcomingInterviews())
            } else {
                dispatch(toggleSchedulerModal())
            }

            if (!user || !user.email) {
                toastNotification(
                    'Please login to schedule an interview! Redirecting to login...'
                )
                redirectToLoginPage()
                return
            }

            if (!user.yoe) {
                toastNotification(
                    'Please add your years of experience to schedule an interview!'
                )
                history.push('/profile')
                return
            }

            if (!user.verified) {
                toastNotification('Please verify your email to schedule an interview!')
                history.push('/profile')
                return
            }

            if (!user.phoneVerified) {
                toastNotification(
                    'Please verify your mobile number to schedule an interview!'
                )
                history.push('/mobile-number')
                return
            }
        }
    }, [schedulerModal, user, dispatch, history])
    const getISOTime = () =>{
        let startTime = date.toDateString().substring(0, 16)
        switch (selectTime) {
            case 0: {
                startTime += ' ' + offsetTimezone('20') + ':00'
                break
            }
            case 1: {
                startTime += ' ' + offsetTimezone('02') + ':00'
                break
            }
            case 2: {
                startTime += ' ' + offsetTimezone('08') + ':00'
                break
            }
            case 3: {
                startTime += ' ' + offsetTimezone(14) + ':00'
                break
            }
            default:
                break
        }
        return startTime
    }
    const handleScheduleButtonClick = async () => {
        if (selectTime === null || selectTime === '') {
            toastNotification('Error: Please select a slot for your interview')
            return
        }

        if (interviewType === null || interviewType === '') {
            toastNotification('Error: Please select an interview type')
            return
        }

        if (upcomingInterviews.length >= 5) {
            toastNotification("Error: You can't request for more than 5 interviews")
            return
        }

        let startTime = getISOTime()

        const reqBody = {
            StartTime: new Date(startTime).toISOString(),
            Type: interviewType,
            IgnoreExp: ignoreExp,
            TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
        dispatch(scheduleInterview(reqBody)).then(_res=>{
            if (user.calendar_access){
                toastNotification(
                    'Successfully added event to Google Calendar.'
                )
            }
        })
        setDate(minDate)
        setSelectTime('')
    }
    useEffect(() => {
        if (!schedulerModal) {
            setSelectTime('')
            setInterviewType('')
            setIgnoreExp(true)
        }
    }, [schedulerModal])
    
    const isSlotDisabled = (ele) => {
        var startTime = date?.toDateString().substring(0, 16) + ' ' + ele
        let b = moment(startTime).format('DD-MM-YYYY HH:mm')
        for (var i = 0; i < upcomingInterviews.length; i++) {
            let a = moment(upcomingInterviews[i].starttime * 1000).format(
                'DD-MM-YYYY HH:mm'
            )
            if (upcomingInterviews[i].status === 1 && a.toString() === b.toString())
                return true
        }
        return false
    }
    const momentUTC = {
        '20:00': '2021-12-15T20:00:00Z',
        '02:00': '2021-12-15T02:00:00Z',
        '14:00': '2021-12-15T14:00:00Z',
        '08:00': '2021-12-15T08:00:00Z',
    }
    const onDateChange = (date) => {
        if (selectTime !== '') {
            let selectedTime = momentUTC[getTimeSlots()[selectTime].props.children[0]]
            if (
                isSlotDisabled(
                    moment.utc(selectedTime).local().format('HH:mm') + ':00'
                ) ||
                (minDate.getDay() === today.getDay() &&
                    date.getDay() === minDate.getDay() &&
                    moment().add('1', 'hour').format('HH:mm') >
                        moment.utc('2021-12-15T20:00:00Z').local().format('HH:mm'))
            ) {
                setSelectTime('')
            }
        }
        setDate(date)
    }

    const getTimeSlots = () => {
        let content = []
        let timeArray = [
            {
                value: 0,
                timezone: String(
                    moment.utc('2021-12-15T20:00:00Z').local().format('HH:mm')
                ),
            },
            {
                value: 1,
                timezone: String(
                    moment.utc('2021-12-15T02:00:00Z').local().format('HH:mm')
                ),
            },
            {
                value: 2,
                timezone: String(
                    moment.utc('2021-12-15T08:00:00Z').local().format('HH:mm')
                ),
            },
            {
                value: 3,
                timezone: String(
                    moment.utc('2021-12-15T14:00:00Z').local().format('HH:mm')
                ),
            },
        ]
        timeArray.sort(comparator)
        timeArray.forEach((slot) => {
            if (
                String(slot.timezone) ===
                moment.utc('2021-12-15T20:00:00Z').local().format('HH:mm')
            ) {
                content.push(
                    <MenuItem
                        key={slot.value}
                        value={slot.value}
                        disabled={
                            isSlotDisabled(
                                moment
                                    .utc('2021-12-15T20:00:00Z')
                                    .local()
                                    .format('HH:mm') + ':00'
                            ) ||
                            (minDate.getDay() === today.getDay() &&
                                date.getDay() === minDate.getDay() &&
                                moment().add('1', 'hour').format('HH:mm') >
                                    moment
                                        .utc('2021-12-15T20:00:00Z')
                                        .local()
                                        .format('HH:mm'))
                        }
                    >
                        {moment.utc('2021-12-15T20:00:00Z').local().format('HH:mm')} -
                        {moment.utc('2021-12-15T21:30:00Z').local().format('HH:mm')}{' '}
                        {new Date()
                            .toTimeString()
                            .match(new RegExp('[A-Z](?!.*[(])', 'g'))
                            .join('')}
                    </MenuItem>
                )
            } else if (
                String(slot.timezone) ===
                moment.utc('2021-12-15T02:00:00Z').local().format('HH:mm')
            ) {
                content.push(
                    <MenuItem
                        key={slot.value}
                        value={slot.value}
                        disabled={
                            isSlotDisabled(
                                moment
                                    .utc('2021-12-15T02:00:00Z')
                                    .local()
                                    .format('HH:mm') + ':00'
                            ) ||
                            (minDate.getDay() === today.getDay() &&
                                date.getDay() === minDate.getDay() &&
                                moment().add('1', 'hour').format('HH:mm') >
                                    moment
                                        .utc('2021-12-15T02:00:00Z')
                                        .local()
                                        .format('HH:mm'))
                        }
                    >
                        {moment.utc('2021-12-15T02:00:00Z').local().format('HH:mm')} -
                        {moment.utc('2021-12-15T03:30:00Z').local().format('HH:mm')}{' '}
                        {new Date()
                            .toTimeString()
                            .match(new RegExp('[A-Z](?!.*[(])', 'g'))
                            .join('')}
                    </MenuItem>
                )
            } else if (
                String(slot.timezone) ===
                moment.utc('2021-12-15T08:00:00Z').local().format('HH:mm')
            ) {
                content.push(
                    <MenuItem
                        key={slot.value}
                        value={slot.value}
                        disabled={
                            isSlotDisabled(
                                moment
                                    .utc('2021-12-15T08:00:00Z')
                                    .local()
                                    .format('HH:mm') + ':00'
                            ) ||
                            (minDate.getDay() === today.getDay() &&
                                date.getDay() === minDate.getDay() &&
                                moment().add('1', 'hour').format('HH:mm') >
                                    moment
                                        .utc('2021-12-15T08:00:00Z')
                                        .local()
                                        .format('HH:mm'))
                        }
                    >
                        {moment.utc('2021-12-15T08:00:00Z').local().format('HH:mm')} -
                        {moment.utc('2021-12-15T09:30:00Z').local().format('HH:mm')}{' '}
                        {new Date()
                            .toTimeString()
                            .match(new RegExp('[A-Z](?!.*[(])', 'g'))
                            .join('')}
                    </MenuItem>
                )
            } else if (
                String(slot.timezone) ===
                moment.utc('2021-12-15T14:00:00Z').local().format('HH:mm')
            ) {
                content.push(
                    <MenuItem
                        key={slot.value}
                        value={slot.value}
                        disabled={
                            isSlotDisabled(
                                moment
                                    .utc('2021-12-15T14:00:00Z')
                                    .local()
                                    .format('HH:mm') + ':00'
                            ) ||
                            (minDate.getDay() === today.getDay() &&
                                date.getDay() === minDate.getDay() &&
                                moment().add('1', 'hour').format('HH:mm') >
                                    moment
                                        .utc('2021-12-15T14:00:00Z')
                                        .local()
                                        .format('HH:mm'))
                        }
                    >
                        {moment.utc('2021-12-15T14:00:00Z').local().format('HH:mm')} -
                        {moment.utc('2021-12-15T15:30:00Z').local().format('HH:mm')}{' '}
                        {new Date()
                            .toTimeString()
                            .match(new RegExp('[A-Z](?!.*[(])', 'g'))
                            .join('')}
                    </MenuItem>
                )
            }
        })
        return content
    }

    return (
        <Dialog open={schedulerModal} className="scheduler__modal">
            <div className="scheduler__schedulerBox">
                {(schedulerLoading ||
                    fetchInterviewsLoading ||
                    fetchAllMeetingsLoading) && <LinearProgress />}
                <div className="scheduler__paddingBox">
                    <Typography
                        variant="h6"
                        color="grey.dark"
                        letterSpacing="-0.025em"
                        fontWeight="400"
                        mb="2rem"
                        className="mobile-heading"
                    >
                        Enter the following details to schedule an interview
                    </Typography>
                    <Grid container justifyContent="space-around" spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                date={date}
                                onDateChange={onDateChange}
                                minimumDate={minDate}
                                maximumDate={maxDate}
                                locale={enGB}
                            >
                                {({ inputProps, focused }) => (
                                    <input
                                        className={'input' + (focused ? ' -focused' : '')}
                                        {...inputProps}
                                    />
                                )}
                            </DatePicker>
                        </Grid>

                        <Grid item xs={12} sm={6} className="schedule__scheduleSlot">
                            <FormControl fullWidth>
                                <InputLabel>Slot</InputLabel>
                                <Select
                                    data-cy="demo-simple-select-standard"
                                    value={selectTime}
                                    onChange={(e) => setSelectTime(e.target.value)}
                                    label="Slot"
                                >
                                    {getTimeSlots()}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <div className="scheduler__interviewType">
                        <FormControl autoFocus fullWidth margin="dense">
                            <InputLabel>What type of interview will this be?</InputLabel>
                            <Select
                                data-cy="demo-simple-select"
                                value={interviewType}
                                onChange={(e) => setInterviewType(e.target.value)}
                                label="What type of interview will this be?"
                            >
                                <MenuItem value={INTERVIEW_TYPES.systemDesign}>
                                    System Design
                                </MenuItem>
                                <MenuItem value={INTERVIEW_TYPES.systemDesignLLD}>
                                    System Design (Low-Level Design)
                                </MenuItem>
                                <MenuItem
                                    data-cy="front-end-interview"
                                    value={INTERVIEW_TYPES.frontend}
                                >
                                    Front-end Interview
                                </MenuItem>
                                <MenuItem value={INTERVIEW_TYPES.coding}>
                                    Coding Interview
                                </MenuItem>
                                <MenuItem value={INTERVIEW_TYPES.behavioral}>
                                    Behavioral Interview
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="scheduler__IgnoreExp">
                        <FormControl autoFocus fullWidth margin="dense">
                            <InputLabel>
                                Do you want an equally experienced candidate?
                            </InputLabel>
                            <Select
                                value={ignoreExp}
                                onChange={(e) => setIgnoreExp(e.target.value)}
                                label="Do you want an equally experienced candidate?"
                            >
                                <MenuItem value={false} selected>
                                    Yes, of course{' '}
                                </MenuItem>
                                <MenuItem value={true}>No, does not matter</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="scheduler__actionButtons">
                        <ButtonComp
                            variant="secondary"
                            handleOnClick={() => {
                                dispatch(toggleSchedulerModal())
                                setDate(minDate)
                                setSelectTime('')
                            }}
                        >
                            Cancel
                        </ButtonComp>
                        <ButtonComp
                            handleOnClick={handleScheduleButtonClick}
                            disabled={
                                fetchInterviewsLoading ||
                                schedulerLoading ||
                                fetchAllMeetingsLoading
                            }
                        >
                            <span data-cy="schedule-interview">Schedule</span>
                        </ButtonComp>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default Scheduler

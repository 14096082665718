import { useState, useEffect } from 'react'
import { TextField, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import Countdown from 'react-countdown'

import LoaderScreen from '../utils/Loader/Loader'
import LogoText from '../utils/Logo/LogoText'
import ButtonComp from '../utils/ButtonComp/ButtonComp'
import * as actions from '../../store/actions/otp'
import toastNotification from '../utils/Notification/Notification'
import { OTP_RESENDING_TIMER } from '../../constants/vars'
import './OTPVerify.css'

const currentTime = Date.now()

const OTPVerify = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const { phoneNumber, loading, verifySuccess, count } = useSelector(
        (state) => state.otp
    )

    const [otp, setOTP] = useState('')

    const handleOTPVerify = () => {
        if (otp.length !== 6) {
            toastNotification('Error: Invalid OTP!')
            return
        }

        dispatch(actions.verifyOTP(otp))
    }

    const handleOTPResend = () => {
        dispatch(actions.sendOTP(phoneNumber))
    }

    const handlePhoneEdit = () => {
        dispatch(actions.editPhone())
        history.push('/mobile-number')
    }

    const timerRenderer = ({ seconds, completed, api }) => {
        if (completed) {
            // Render a completed state
            return (
                <div className="otpVerify__resendWrapper">
                    <button className="otpVerify__resendButton" onClick={handleOTPResend}>
                        Resend OTP
                    </button>
                    <p className="remainingCount">
                        (remaining: <b>{3 - count}</b>)
                    </p>
                </div>
            )
        } else {
            api.start()

            // Render a countdown
            return (
                <Typography
                    variant="body2"
                    color="grey.dark"
                    align="center"
                    letterSpacing="-0.025em"
                    fontSize="0.9rem"
                    lineHeight="1.25rem"
                    fontWeight="500"
                    marginLeft="auto"
                    marginTop="0.5rem"
                    marginBottom="1rem"
                    paddingRight="0.4rem"
                >
                    resend OTP in <span className="otpVerify__timer">{seconds}</span> sec
                </Typography>
            )
        }
    }

    // to redirect to profile page after the OTP is verified successfully
    useEffect(() => {
        if (verifySuccess) {
            history.push('/profile')
        }
    }, [verifySuccess, history])

    if (!phoneNumber) {
        history.push('/404')
        return null
    }

    return (
        <div className="custom-bg otpVerify">
            {loading ? (
                <LoaderScreen />
            ) : (
                <div className="otpVerify__wrapper">
                    <Link className="otpVerify__logoWrapper" to="/">
                        <LogoText />
                    </Link>

                    <Typography
                        variant="h1"
                        color="grey.dark"
                        align="center"
                        letterSpacing="-0.025em"
                        fontSize="1.3rem"
                        lineHeight="1.75rem"
                        fontWeight="500"
                    >
                        OTP Verification
                    </Typography>
                    <Typography
                        variant="body1"
                        color="grey.main"
                        align="center"
                        mt="0.8rem"
                        mb="2rem"
                        fontSize="1rem"
                        lineHeight="1.5rem"
                    >
                        Please type the OTP sent to you on{' '}
                        <span className="otpVerify__phone">{phoneNumber}</span>{' '}
                        <button
                            className="otpVerify__editButton"
                            onClick={handlePhoneEdit}
                        >
                            (Edit)
                        </button>
                    </Typography>

                    <TextField
                        label="Enter your OTP"
                        value={otp}
                        onChange={(e) => {
                            setOTP(e.target.value)
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handleOTPVerify()
                            }
                        }}
                        type="text"
                        variant="outlined"
                    />

                    {count >= 3 ? (
                        <Typography
                            variant="body2"
                            color="grey.dark"
                            align="center"
                            letterSpacing="-0.025em"
                            fontSize="0.9rem"
                            lineHeight="1.25rem"
                            fontWeight="500"
                            marginLeft="auto"
                            marginTop="0.5rem"
                            marginBottom="1rem"
                            paddingRight="0.4rem"
                        >
                            max OTP requests exceeded.
                        </Typography>
                    ) : (
                        <Countdown
                            date={currentTime + OTP_RESENDING_TIMER} // 1 min countdown to resend OTP
                            renderer={timerRenderer}
                            autoStart={false}
                        />
                    )}

                    <ButtonComp handleOnClick={handleOTPVerify}>Verify</ButtonComp>
                </div>
            )}
        </div>
    )
}

export default OTPVerify

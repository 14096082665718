export const MOCK_INTERVIEW_UI = 'https://mock.interviewready.io'
export const DISCUSSIONS_FORUM = 'https://discussion.interviewready.io'
export const MAIN_UI = 'https://get.interviewready.io'
export const SOCIAL_URLS = {
    YOUTUBE: 'https://www.youtube.com/channel/UCRPMAqdtSgd0Ipeef7iFsKw/',
    LINKEDIN: 'https://www.linkedin.com/company/interview-ready/',
    DISCORD: 'https://discord.gg/YByFvURXe3',
    TWITTER: 'https://twitter.com/InterviewReady2',
    INSTAGRAM: 'https://www.instagram.com/interviewready_/',
}
export const CDN_BASE_URL = 'https://d34xlmh0rv7u44.cloudfront.net'
export const PROFILE_IMAGES_BUCKET_BASE_URL =
    'https://ir-profile-images.s3.ap-south-1.amazonaws.com'

import { useEffect, useState } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import LogRocket from 'logrocket'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useDispatch, useSelector } from 'react-redux'

import { fetchUser } from './store/actions/userInfo'
import history from './history/history'
import PrivateRoute from './routes/private_route'
import ContextRoute from './routes/context_route'
import Landing from './components/Landing/Landing'
import ScrollToTop from './components/utils/ScrollToTop/ScrollToTop'
import NotificationContainer from './components/utils/Notification/NotificationContainer'
import MeetingConnection from './components/Meeting/MeetingConnection'
import MeetVerify from './components/MeetVerify/MeetVerify.jsx'
import MeetingGateway from './components/MeetingGateway/MeetingGateway'
import Error404 from './components/Error404/Error404'
import Profile from './components/Profile/Profile'
import FeedbackForm from './components/FeedbackForm/FeedbackForm'
import MobileUpdate from './components/MobileUpdate/MobileUpdate'
import OTPVerify from './components/OTPVerify/OTPVerify'
import Loader from './components/utils/Loader/Loader'
import PlatformRating from './components/PlatformRating/PlatformRating'
import Instant from './components/Instant/Instant.jsx'
import ScheduleAnotherMeetingModal from './components/MeetVerify/ScheduleAnotherMeetingModal/ScheduleAnotherMeetingModal'
import { MeetContextProvider } from './components/Meeting/MeetContext'
import { ProfileContextProvider } from './components/Profile/ProfileContext'
import LinearLoader from './components/utils/LinearLoader/LinearLoader'
import './App.css'

const App = () => {
    const dispatch = useDispatch()
    const { loading } = useSelector((state) => state.rating)
    const { user } = useSelector((state) => state.userInfo)

    const preLoader = document.getElementById('preLoader')
    const [authLoading, setAuthLoading] = useState(true)

    useEffect(() => {
        dispatch(fetchUser())
            .then(() => {
                setAuthLoading(false)
            })
            .catch(() => {
                setAuthLoading(false)
            })
    }, [dispatch])

    useEffect(() => {
        AOS.init()

        if (preLoader) {
            preLoader.remove()
        }
    }, [preLoader])

    useEffect(() => {
        const isProdEnv = process.env.REACT_APP_ENV === 'PROD'
        if (user && user.email && isProdEnv) {
            LogRocket.init(process.env.REACT_APP_LOG_ROCKET_PROJECT_ID)

            let deviceType = 'laptop/desktop'
            if (
                window.innerWidth < 500 ||
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                deviceType = 'mobile/tablet'
            }

            LogRocket.identify(user.id, {
                name: user.firstName + ' ' + user.lastName,
                email: user.email,
                device: deviceType,
            })
        }
    }, [user])

    if (authLoading) return <Loader />

    return (
        <>
            {loading && <LinearLoader />}
            <Router history={history}>
                <ScrollToTop />
                <NotificationContainer />
                <Switch>
                    <Route exact path="/" component={Landing} />
                    <ContextRoute
                        exact
                        contextComponent={MeetContextProvider}
                        path="/feedback"
                        component={FeedbackForm}
                    />
                    <ContextRoute
                        exact
                        contextComponent={ProfileContextProvider}
                        path="/profile"
                        component={Profile}
                    />
                    <PrivateRoute exact path="/mobile-number" component={MobileUpdate} />
                    <PrivateRoute exact path="/mobile-verify" component={OTPVerify} />
                    <ContextRoute
                        exact
                        contextComponent={MeetContextProvider}
                        path="/interview/:roomID"
                        component={MeetingConnection}
                    />
                    <ContextRoute
                        exact
                        contextComponent={MeetContextProvider}
                        path="/interview-gateway/:roomID"
                        component={MeetingGateway}
                    />
                    <ContextRoute
                        exact
                        contextComponent={MeetContextProvider}
                        path="/interview-verify/:roomID"
                        component={MeetVerify}
                    />
                    <PrivateRoute exact path="/instant" component={Instant} />

                    <Route path="*" exact component={Error404} />
                </Switch>
            </Router>
            <PlatformRating />
        </>
    )
}

export default App

import FEEDBACK_PARAMS from '../constants/feedbackParams'
import toastNotification from '../components/utils/Notification/Notification'

export const getFeedbackParams = (interviewType = '') => {
    if (FEEDBACK_PARAMS[interviewType]) {
        return FEEDBACK_PARAMS[interviewType]
    } else {
        console.error('[Invalid interview type!]: ', interviewType)
        return []
    }
}

export const getFeedbackCommentInitial = (interviewType = '') => {
    let feedbackCommentInitial = {}

    if (getFeedbackParams(interviewType).length > 0) {
        getFeedbackParams(interviewType).forEach((item) => {
            feedbackCommentInitial = {
                ...feedbackCommentInitial,
                [item.type]: '',
            }
        })
    } else {
        console.error('[Invalid interview type!]: ', interviewType)
    }

    return feedbackCommentInitial
}

export const getFeedbackRatingInitial = (interviewType = '') => {
    let feedbackRatingInitial = {}

    if (getFeedbackParams(interviewType).length > 0) {
        getFeedbackParams(interviewType).forEach((item) => {
            // if there is a field for other comments then we don't need ratings for that
            if (item.type !== 'other') {
                feedbackRatingInitial = {
                    ...feedbackRatingInitial,
                    [item.type]: 0,
                }
            }
        })
    } else {
        console.error('[Invalid interview type!]: ', interviewType)
    }

    return feedbackRatingInitial
}

// function to convert the feedback frontend data ---> desired request body
export const parseFeedbackData = ({ comments = {}, ratings = {}, meetInfo = {} }) => {
    const interviewType = meetInfo.type
    const meeting_id = meetInfo.roomID
    const feedback_for = meetInfo.remoteUser.id

    let feedbackReqBody = {
        meeting_id,
        feedback_for,
    }
    let paramCount = 1

    const useComments = { ...getFeedbackCommentInitial(interviewType), ...comments }
    const useRatings = { ...getFeedbackRatingInitial(interviewType), ...ratings }

    getFeedbackParams(interviewType).forEach((param) => {
        Object.keys(useComments).forEach((commentKey) => {
            if (param.type === commentKey) {
                feedbackReqBody = {
                    ...feedbackReqBody,
                    [`param_${paramCount}`]: {
                        ...feedbackReqBody[`param_${paramCount}`],
                        type: param.type,
                        name: param.title,
                        comment: useComments[commentKey].trim(),
                    },
                }
            }
        })

        Object.keys(useRatings).forEach((ratingKey) => {
            if (param.type === ratingKey) {
                feedbackReqBody = {
                    ...feedbackReqBody,
                    [`param_${paramCount}`]: {
                        ...feedbackReqBody[`param_${paramCount}`],
                        rating: useRatings[ratingKey],
                    },
                }
            }
        })

        paramCount += 1
    })

    return feedbackReqBody
}

// function to convert the feedback response body ---> desired frontend data
export const retrieveFeedbackData = ({ responseBody = [], meetInfo = {} }) => {
    const interviewType = meetInfo.type
    const feedback_for = meetInfo.remoteUser.id

    try {
        const givenFeedback = responseBody.filter(
            (feedbackData) => String(feedbackData.feedback_for) === String(feedback_for)
        )[0]

        let feedbackComments = {}

        Object.keys(givenFeedback).forEach((key) => {
            if (key.includes('param_')) {
                feedbackComments = {
                    ...feedbackComments,
                    [givenFeedback[key].type]: givenFeedback[key].comment
                        ? givenFeedback[key].comment
                        : '',
                }
            }
        })

        return feedbackComments
    } catch (err) {
        console.log('[retrieveFeedbackData()] Error: ', err.message)
        return getFeedbackCommentInitial(interviewType)
    }
}

// function to convert the feedback response body ---> desired frontend data for interview history table
export const retrieveReceivedFeedbackData = ({ responseBody = [], userID = 0 }) => {
    const feedback_for = userID

    try {
        const receivedFeedback = responseBody.filter(
            (feedbackData) => String(feedbackData.feedback_for) === String(feedback_for)
        )[0]

        if (!receivedFeedback) {
            throw new Error('Error: No feedback found for this interview!')
        }

        let feedback = []

        Object.keys(receivedFeedback).forEach((key) => {
            if (key.includes('param_')) {
                feedback.push({
                    skill: receivedFeedback[key].name,
                    comment: receivedFeedback[key].comment,
                    score: receivedFeedback[key].rating,
                })
            }
        })

        return feedback
    } catch (err) {
        toastNotification(err.message)
        console.log('[retrieveReceivedFeedbackData()] Error: ', err.message)
        return []
    }
}

import * as actionTypes from '../actionTypes'

export const toggleSchedulerModal = () => (dispatch) => {
    dispatch({ type: actionTypes.TOGGLE_SCHEDULER_MODAL })
}

export const toggleProfileSidebar = () => (dispatch) => {
    dispatch({ type: actionTypes.TOGGLE_PROFILE_SIDEBAR })
}

export const toggleQuestionsSidebar = () => (dispatch) => {
    dispatch({ type: actionTypes.TOGGLE_QUESTIONS_SIDEBAR })
}

export const showLoading = () => (dispatch) => {
    dispatch({ type: actionTypes.SHOW_LOADING })
}

export const toggleProfileModal = () => (dispatch) => {
    dispatch({ type: actionTypes.TOGGLE_PROFILE_MODAL })
}

export const toggleCodeResultPanel = (isOpen) => (dispatch) => {
    dispatch({ type: actionTypes.TOGGLE_CODE_RESULT_PANEL, payload: isOpen })
}

export const toggleScheduleAnotherMeetingModal = () => (dispatch) => {
    dispatch({ type: actionTypes.TOGGLE_SCHEDULEANOTHERMEETING_MODAL })
}


import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import Auth from '../../../../middleware'
import { PROFILE_IMAGES_BUCKET_BASE_URL } from '../../../../constants/urls'
import { MAIN_UI } from '../../../../constants/urls'

const AccountMenu = () => {
    const history = useHistory()
    const { user } = useSelector((state) => state.userInfo)

    const [anchorEl, setAnchorEl] = useState(null)
    const isOpen = Boolean(anchorEl)

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 1 }}
                data-cy="user-circle"
            >
                <Avatar
                    sx={{
                        width: 42,
                        height: 42,
                        bgcolor: (theme) => theme.palette.indigo[600],
                    }}
                    src={
                        user?.image !== ''
                            ? `${PROFILE_IMAGES_BUCKET_BASE_URL}/${user?.image}`
                            : null
                    }
                >
                    <Typography
                        variant="h1"
                        color="white"
                        fontSize="1.1rem"
                        fontWeight="600"
                    >
                        {user.firstName[0].toUpperCase()}
                        {user.lastName && user.lastName[0].toUpperCase()}
                    </Typography>
                </Avatar>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={isOpen}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        borderRadius: '6px',
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem data-cy="profile-link" onClick={() => history.push('/profile')}>
                    <Typography
                        variant="body1"
                        color="grey.main"
                        fontSize="1rem"
                        lineHeight="1.5rem"
                        width="150px"
                    >
                        Your Profile
                    </Typography>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        window.open(MAIN_UI, '_blank')
                    }}
                >
                    <Typography
                        variant="body1"
                        color="grey.main"
                        fontSize="1rem"
                        lineHeight="1.5rem"
                        width="150px"
                    >
                        Go To Course
                    </Typography>
                </MenuItem>
                <MenuItem onClick={() => Auth.signOut()}>
                    <Typography
                        data-cy="sign-out"
                        variant="body1"
                        color="grey.main"
                        fontSize="1rem"
                        lineHeight="1.5rem"
                        width="150px"
                    >
                        Sign out
                    </Typography>
                </MenuItem>
            </Menu>
        </>
    )
}

export default AccountMenu

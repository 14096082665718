import Axios from '../../service/axios'
import * as actionTypes from '../actionTypes'
import { BANNER_TYPES } from '../../constants/bannerTypes'

export const fetchPromotionBanner = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.get('/api/v1/banner')
            .then((res) => {
                const allBanners = res.data.banners
                let payload = {}

                allBanners.forEach((bannerData) => {
                    if (bannerData.type === BANNER_TYPES.mockInterviewsNonSignedIn) {
                        payload = {
                            ...payload,
                            nonSignedIn: {
                                content: bannerData.message,
                                isActive: bannerData.active,
                            },
                        }
                    }
                    if (bannerData.type === BANNER_TYPES.mockInterviewsSignedIn) {
                        payload = {
                            ...payload,
                            signedIn: {
                                content: bannerData.message,
                                isActive: bannerData.active,
                            },
                        }
                    }
                })

                dispatch({
                    type: actionTypes.SET_PROMOTION_BANNERS,
                    payload,
                })
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const closePromotionBanner = () => (dispatch) => {
    dispatch({ type: actionTypes.CLOSE_PROMOTION_BANNER })
}

import openSocket from 'socket.io-client'

let socket

const iceServers = {
    iceServers: [
        { urls: 'stun:stun.l.google.com:19302' },
        { urls: 'stun:stun1.l.google.com:19302' },
        { urls: 'stun:stun2.l.google.com:19302' },
        { urls: 'stun:stun3.l.google.com:19302' },
        { urls: 'stun:stun4.l.google.com:19302' },
    ],
}

export const connectSocket = () => {
    socket = openSocket(process.env.REACT_APP_SERVERDOM)
}

export const sendUserId = (eventName, token) => {
    socket.emit(eventName, token)
}

export const kickOut = (eventName, callback = () => {}) => {
    socket.off(eventName).on(eventName, async () => {
        callback()
    })
}

export const onNewMessage = (
    eventName,
    callback = (userID, message, timestamp, senderName) => {}
) => {
    socket
        .off(eventName)
        .on(eventName, async (userID, message, timestamp, senderName) => {
            callback(userID, message, timestamp, senderName)
        })
}

export function onEditText(eventName, callback = (userID, text) => {}) {
    socket.off(eventName).on(eventName, async (userID, text) => {
        callback(userID, text)
    })
}
export function onDrawingBoardImage(eventName, callback = (drawingImage) => {}) {
    socket.off(eventName).on(eventName, async (drawingImage) => {
        callback(drawingImage)
    })
}
export const onVideo = (eventName, token) => {
    socket.emit(eventName, token)
}

export const myVideo = (eventName, token, video) => {
    socket.emit(eventName, token, video)
}

export function userVideo(eventName, callback = (videoVar) => {}) {
    socket.off(eventName).on(eventName, async (UserID, videoVar) => {
        callback(videoVar)
    })
}

export const myAudio = (eventName, token, audio) => {
    socket.emit(eventName, token, audio)
}
export function userAudio(eventName, callback = (audioVar) => {}) {
    socket.off(eventName).on(eventName, async (UserID, audioVar) => {
        callback(audioVar)
    })
}

export const emitCodeCompile = (eventName, token) => {
    socket.emit(eventName, token)
}

export function onCodeCompile(eventName, callback = () => {}) {
    socket.off(eventName).on(eventName, async () => {
        callback()
    })
}

export const emitCodeStoreSync = (eventName, token, codeStoreData) => {
    socket.emit(eventName, token, codeStoreData)
}

export function onSyncCodeStore(eventName, callback = (codeStoreData) => {}) {
    socket.off(eventName).on(eventName, async (codeStoreData) => {
        callback(codeStoreData)
    })
}

export const sendDrawingBoardSignal = (eventName, token, boardVar) => {
    socket.emit(eventName, token, boardVar)
}

export function onDrawingBoardSignal(eventName, callback = (boardVar) => {}) {
    socket.off(eventName).on(eventName, async (boardVar) => {
        callback(boardVar)
    })
}

export const myScreen = (eventName, token) => {
    socket.emit(eventName, token)
}

export function userScreen(eventName, callback = () => {}) {
    socket.off(eventName).on(eventName, async (UserID) => {
        callback()
    })
}

export function onLangChange(eventName, callback = (userID, lang) => {}) {
    socket.off(eventName).on(eventName, async (userID, lang) => {
        callback(userID, lang)
    })
}

export const sendMessage = (eventName, token, message, senderName) => {
    socket.emit(eventName, token, message, senderName)
}

export const sendEditorText = (eventName, token, text, codeSaveId) => {
    socket.emit(eventName, token, text, codeSaveId)
}

export const sendDrawingBoardImage = (
    eventName,
    token,
    drawingBoardImage,
    drawingBoardId
) => {
    socket.emit(eventName, token, drawingBoardImage, drawingBoardId)
}

export const sendLang = (eventName, token, lang) => {
    socket.emit(eventName, token, lang)
}

export const onRoomCreate = (eventName, callback = (_) => {}) => {
    socket.off(eventName).on(eventName, async () => {
        callback()
    })
}

export const onRoomCreateScreen = (eventName, callback = (_) => {}) =>
    onRoomCreate(eventName, callback)

export const onRoomJoin = (eventName, callback = (_) => {}) => {
    socket.off(eventName).on(eventName, async () => {
        callback()
    })
}

export const onRoomJoinScreen = (eventName, callback = (_) => {}) =>
    onRoomJoin(eventName, callback)

export const startCall = (eventName, token) => {
    socket.emit(eventName, token)
}

export const startCallScreen = (eventName, token) => startCall(eventName, token)

export const onStartCall = (eventName, isRoomCreator, callback = (iceServers) => {}) => {
    socket.off(eventName).on(eventName, async () => {
        if (!isRoomCreator) {
            callback(iceServers)
        }
    })
}

export const onStartCallScreen = (
    eventName,
    isRoomCreator,
    callback = (iceServers) => {}
) => onStartCall(eventName, isRoomCreator, callback)

export const onWebRTCOffer = (
    eventName,
    isRoomCreator,
    callback = (iceServers) => {}
) => {
    socket.off(eventName).on(eventName, async (event) => {
        callback(event, iceServers)
    })
}

export const onWebRTCOfferScreen = (
    eventName,
    isRoomCreator,
    callback = (iceServers) => {}
) => onWebRTCOffer(eventName, isRoomCreator, callback)

export const sendWebRTCAnswer = async (eventName, rtcPeerConnection, token) => {
    try {
        let sessionDescription = await rtcPeerConnection.createAnswer()
        rtcPeerConnection.setLocalDescription(sessionDescription)
        socket.emit(eventName, {
            type: eventName,
            sdp: sessionDescription,
            token,
        })
    } catch (error) {
        console.error(error)
    }
}

export const sendWebRTCAnswerScreen = async (eventName, rtcPeerConnection, token) =>
    await sendWebRTCAnswer(eventName, rtcPeerConnection, token)

export const sendWebRTCOffer = async (eventName, rtcPeerConnection, token) => {
    try {
        let sessionDescription = await rtcPeerConnection.createOffer()
        rtcPeerConnection.setLocalDescription(sessionDescription)
        socket.emit(eventName, {
            type: eventName,
            sdp: sessionDescription,
            token,
        })
    } catch (error) {
        console.error(error)
    }
}

export const sendWebRTCOfferScreen = async (eventName, rtcPeerConnection, token) =>
    await sendWebRTCOffer(eventName, rtcPeerConnection, token)

export const sendIceCandidate = (eventName, event, token) => {
    if (event.candidate) {
        socket.emit(eventName, {
            token,
            label: event.candidate.sdpMLineIndex,
            candidate: event.candidate.candidate,
        })
    }
}

export const sendIceCandidateScreen = (eventName, event, token) =>
    sendIceCandidate(eventName, event, token)

export const onIceCandidate = (eventName, callback = (event) => {}) => {
    socket.on(eventName, (event) => {
        callback(event)
    })
}

export const onIceCandidateScreen = (eventName, callback = (event) => {}) =>
    onIceCandidate(eventName, callback)

export const onWebRTCAnswer = (eventName, callback = (event) => {}) => {
    socket.off(eventName).on(eventName, (event) => {
        callback(event)
    })
}

export const onWebRTCAnswerScreen = (eventName, callback = (event) => {}) =>
    onWebRTCAnswer(eventName, callback)

export const onInvalid = (eventName, callback = (_) => {}) => {
    socket.off(eventName).on(eventName, () => {
        callback()
    })
}

export const joinRoom = (eventName, token) => {
    socket.emit(eventName, token)
}

export const sendFlipRoleRequest = (eventName, token) => {
    socket.emit(eventName, token)
}

export const getFlipRoleRequest = (eventName, callback = () => {}) => {
    socket.off(eventName).on(eventName, () => {
        callback()
    })
}

export const sendFlipAction = (eventName, token, flipValue) => {
    socket.emit(eventName, token, flipValue)
}

export const getFlipAction = (eventName, callback = (userID, flipValue) => {}) => {
    socket.off(eventName).on(eventName, async (userID, flipValue) => {
        callback(userID, flipValue)
    })
}

export const onLeaveRoom = (eventName, callback) => {
    socket.off(eventName).on(eventName, (userID) => {
        callback(userID)
    })
}

export const onClientDisconnect = (eventName, callback) => {
    socket.off(eventName).on(eventName, () => {
        callback()
    })
}
export const onClientJoinToRoom = (eventName, callback) => {
    socket.off(eventName).on(eventName, (usersCount) => {
        callback(usersCount)
    })
}

export const leaveRoom = (eventName, token, disconnect = true) => {
    socket.emit(eventName, token)
    if (disconnect) {
        socket.disconnect()
    }
}

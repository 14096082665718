import Auth from '../../middleware'
import Axios from '../../service/axios'
import * as actionTypes from '../actionTypes'

export const fetchUser = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.get('/api/v1/users')
            .then((res) => {
                dispatch({
                    type: actionTypes.SAVE_USER,
                    payload: res.data,
                })
                resolve()
            })
            .catch((error) => {
                let status = error?.response?.status
                if (status === 401 || status === 404) {
                    Auth.signOut()
                }
                reject(error)
            })
    })
}

export const signOutUser = () => (dispatch) => {
    dispatch({
        type: actionTypes.SIGN_OUT,
    })
}

import { useEffect, useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AceEditor from 'react-ace'

import 'ace-builds/src-noconflict/mode-java'
import 'ace-builds/src-noconflict/mode-python'
import 'ace-builds/src-noconflict/mode-javascript'
import 'ace-builds/src-noconflict/mode-c_cpp'
import 'ace-builds/src-noconflict/theme-monokai'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/src-noconflict/ext-language_tools'

import { MeetContext } from '../MeetContext'
import { sendEditorText, onEditText } from '../socket'
import { getInterviewCode } from '../../../store/actions/code'
import CodeResult from './CodeResult'
import CodeEditorNavbar from './CodeEditorNavbar'
import { CODE_EDITOR_DEFAULT_MESSAGE } from '../../../constants/vars'
import './CodeEditor.css'

const CodeEditor = () => {
    const dispatch = useDispatch()
    const { meetInfo } = useContext(MeetContext)

    const {
        testerId,
        events,
        token,
        shareScreen,
        userShareScreen,
        drawingBoard,
        isBehavioralInterview,
    } = useSelector((state) => state.meeting)
    const {
        code,
        loading: loadingCode,
        codeTheme,
        codeLanguage,
        clearCode,
    } = useSelector((state) => state.code)
    const { codeResultPanel } = useSelector((state) => state.ui)

    const [editorText, setEditorText] = useState('')

    const codeSaveId =
        meetInfo.userID === testerId ? meetInfo.remoteUser.id : meetInfo.userID

    useEffect(() => {
        onEditText(events.textEdit, (userID, text) => {
            setEditorText(text)
        })
    }, [events.textEdit])

    useEffect(() => {
        dispatch(
            getInterviewCode({
                meetingID: meetInfo.roomID,
                userID: codeSaveId,
            })
        )
    }, [codeSaveId, dispatch, meetInfo.roomID])

    useEffect(() => {
        if (loadingCode) {
            setEditorText(
                isBehavioralInterview
                    ? 'Please wait! Text is loading...'
                    : '// Please wait! Code is loading...'
            )
        } else {
            setEditorText(code)
        }
    }, [code, loadingCode, isBehavioralInterview])

    // to clear code editor on role swap
    useEffect(() => {
        if (clearCode) {
            setEditorText('')
        }
    }, [clearCode, token, codeSaveId])

    const isCodeEditorHidden = shareScreen || userShareScreen || drawingBoard

    return (
        <div
            style={{
                visibility: isCodeEditorHidden ? 'hidden' : 'visible',
                zIndex: isCodeEditorHidden ? -1 : 5,
            }}
            className="codeEditor"
        >
            <div
                className={`codeEditor__wrapper ${codeResultPanel && 'open'} ${
                    isBehavioralInterview && 'full-height'
                }`}
            >
                <AceEditor
                    key={codeResultPanel ? 'abc' : 'xyz'}
                    height="100%"
                    width="100%"
                    theme={codeTheme}
                    mode={isBehavioralInterview ? '' : codeLanguage}
                    name="ace__editor"
                    className="ace__editor"
                    placeholder={
                        isBehavioralInterview
                            ? 'want to write something ?'
                            : CODE_EDITOR_DEFAULT_MESSAGE
                    }
                    value={editorText}
                    fontSize={16}
                    showPrintMargin={false}
                    showGutter
                    wrapBehavioursEnabled
                    editorProps={{ $blockScrolling: true }}
                    setOptions={{
                        showLineNumbers: true,
                        tabSize: 2,
                    }}
                    onChange={(e) => {
                        setEditorText(e)
                        sendEditorText(events.textEdit, token, e, codeSaveId)
                    }}
                />
            </div>

            <CodeEditorNavbar editorText={editorText} />
            {!isBehavioralInterview && <CodeResult />}
        </div>
    )
}

export default CodeEditor

import * as actionTypes from '../actionTypes'

const initialState = {
    showBanner: true,
    banners: { nonSignedIn: null, signedIn: null },
}

const promotionBanner = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_PROMOTION_BANNERS:
            return {
                ...state,
                banners: action.payload,
            }

        case actionTypes.CLOSE_PROMOTION_BANNER:
            return { ...state, showBanner: false }

        default:
            return state
    }
}

export default promotionBanner

import React from 'react'
import { Typography, Grid } from '@mui/material'

import FlowItem from './FlowItem'
import { SCHEDULE_INTERVIEW_FLOW_DATA } from '../../../constants/flowData'
import './Flow.css'

const Flow = () => {
    return (
        <section className="flow">
            <div className="flow__wrapper">
                <Typography
                    variant="h1"
                    color="indigo.600"
                    align="center"
                    typography={{ textTransform: 'uppercase' }}
                    fontWeight="600"
                    letterSpacing="0.05em"
                    fontSize="1rem"
                    lineHeight="1.5rem"
                >
                    The Flow
                </Typography>
                <Typography
                    variant="h2"
                    color="grey.dark"
                    align="center"
                    fontSize="2.25rem"
                    lineHeight="2.5rem"
                    letterSpacing="-0.025em"
                    fontWeight="800"
                    mt="0.5rem"
                    className="flow__heading"
                >
                    Four simple steps
                </Typography>

                <Grid container mt="4rem" className="flow__stepsWrapper">
                    {SCHEDULE_INTERVIEW_FLOW_DATA.map((data) => (
                        <Grid item key={data.id} lg={3}>
                            <FlowItem {...data} />
                        </Grid>
                    ))}
                </Grid>
            </div>
        </section>
    )
}

export default Flow

import { useSelector } from 'react-redux'
import { Typography, Avatar } from '@mui/material'

import ProfileEdit from '../ProfileEdit/ProfileEdit'
import { PROFILE_IMAGES_BUCKET_BASE_URL } from '../../../constants/urls'
import './UserProfile.css'

const UserProfile = () => {
    const { user } = useSelector((state) => state.userInfo)

    return (
        <div className="userProfile">
            <div className="userProfile__avatarSection">
                <Avatar
                    sx={{
                        width: 160,
                        height: 160,
                        bgcolor: (theme) => theme.palette.indigo[600],
                        marginBottom: 2,
                    }}
                    src={
                        user?.image !== ''
                            ? `${PROFILE_IMAGES_BUCKET_BASE_URL}/${user.image}`
                            : null
                    }
                >
                    <Typography
                        variant="h1"
                        color="white"
                        fontSize="3rem"
                        fontWeight="600"
                    >
                        {user.firstName && user.firstName[0].toUpperCase()}
                        {user.lastName && user.lastName[0].toUpperCase()}
                    </Typography>
                </Avatar>
            </div>

            <div className="userProfile__detailsSection">
                <Typography
                    variant="h1"
                    color="grey.dark"
                    fontSize="1.4rem"
                    fontWeight="500"
                    mb="0.5rem"
                >
                    {user.firstName} {user.lastName}
                </Typography>
                <Typography
                    variant="h2"
                    color="grey.dark"
                    fontSize="1.2rem"
                    fontWeight="400"
                    mb="0.5rem"
                >
                    {user.email}
                </Typography>
                <Typography
                    variant="h2"
                    color="grey.dark"
                    fontSize="1.2rem"
                    fontWeight="400"
                    mb="0.5rem"
                >
                    Experience: {user.yoe}
                </Typography>
                <Typography
                    variant="h2"
                    color="grey.dark"
                    fontSize="1.2rem"
                    fontWeight="400"
                    mb="1rem"
                >
                    Phone: {user.phone ? user.phone : 'XXX-XXX-XXXX'}{' '}
                </Typography>

                <ProfileEdit />
            </div>
        </div>
    )
}

export default UserProfile

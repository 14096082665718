import * as actionTypes from '../actionTypes'
import Axios from '../../service/axios'
import toastNotification from '../../components/utils/Notification/Notification'

export const getUserRatings = (dateRange) => async (dispatch) => {
    try {
        dispatch({ type: actionTypes.GET_USER_RATING_REQUEST })

        const { data } = await Axios.post('/api/v1/ratings', dateRange)

        dispatch({ type: actionTypes.GET_USER_RATING_SUCCESS, payload: data })
    } catch (err) {
        const errorMessage = err?.response?.data?.message
            ? err?.response?.data?.message
            : "Can't fetch your ratings! Try refreshing the page."

        dispatch({
            type: actionTypes.GET_USER_RATING_FAIL,
            payload: errorMessage,
        })

        toastNotification(errorMessage)
    }
}

import React from 'react'

import Navbar from './Navbar/Navbar'
import Hero from './Hero/Hero'
import Features from './Features/Features'
import WhyUs from './WhyUs/WhyUs'
import Flow from './Flow/Flow'
import CallToAction from './CallToAction/CallToAction'
import Footer from './Footer/Footer'
import CookiesBanner from '../utils/CookiesBanner/CookiesBanner'
import PromotionBanner from '../utils/PromotionBanner/PromotionBanner'
import './Landing.css'

const Landing = () => {
    return (
        <div className="landing">
            <PromotionBanner />
            <Navbar />
            <Hero />
            <Features />
            <WhyUs />
            <Flow />
            <CallToAction />
            <Footer />
            <CookiesBanner />
        </div>
    )
}

export default Landing

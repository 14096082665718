import React from 'react'
import { Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import ButtonComp from '../../utils/ButtonComp/ButtonComp'
import { CDN_BASE_URL } from '../../../constants/urls'
import { toggleSchedulerModal } from '../../../store/actions/ui'
import './Hero.css'

const Hero = () => {
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.userInfo)

    return (
        <section className="hero">
            <video autoPlay muted loop>
                <source src={`${CDN_BASE_URL}/SystemDesignhero-cover-gkcs.mp4`} type="video/mp4" />
            </video>

            <div className="hero__overlay">
                <div className="hero__overlayContentWrapper">
                    <Typography
                        variant="h1"
                        color="white"
                        fontWeight="700"
                        fontSize="2.75rem"
                        lineHeight="1"
                        align="center"
                        pb="1rem"
                    >
                        Free Mock Interviews
                    </Typography>
                    <Typography
                        variant="body1"
                        color="grey.light"
                        fontWeight="325"
                        fontSize="1.35rem"
                        lineHeight="1"
                        align="center"
                        pb="1rem"
                    >
                        Get close to the real life experience with our two-way
                    </Typography>
                    <Typography
                        variant="body1"
                        color="grey.light"
                        fontWeight="325"
                        fontSize="1.35rem"
                        lineHeight="1"
                        align="center"
                        pb="1rem"
                    >
                        rated mock interviews.
                    </Typography>
                    <ButtonComp
                        disabled={user?.mockInterviewBan}
                        size="lg"
                        handleOnClick={() => {
                            dispatch(toggleSchedulerModal())
                        }}
                    >
                        Schedule Mock Interview
                    </ButtonComp>
                </div>
            </div>
        </section>
    )
}

export default Hero

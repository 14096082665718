import { useContext, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles'
import {
    Box,
    List,
    CssBaseline,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import TimelineIcon from '@mui/icons-material/Timeline'
import HistoryIcon from '@mui/icons-material/History'
import AddBoxIcon from '@mui/icons-material/AddBox'

import { toggleProfileSidebar } from '../../../store/actions/ui'
import { ProfileContext } from '../ProfileContext'
import './Sidebar.css'

const drawerWidth = 190

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
})

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    })
)

const Sidebar = ({ children }) => {
    const dispatch = useDispatch()
    const { profileRef, slotsRef, ratingRef, historyRef } = useContext(ProfileContext)

    const { profileSidebar } = useSelector((state) => state.ui)
    const { user } = useSelector((state) => state.userInfo)

    const handleSidebarToggle = () => {
        dispatch(toggleProfileSidebar())
    }

    const handleNavigation = (ref) => {
        if (ref && ref.current) {
            ref.current.scrollIntoView()
        }
    }

    useEffect(() => {
        if (!profileSidebar) {
            dispatch(toggleProfileSidebar())
        }

        return () => {
            if (profileSidebar) {
                dispatch(toggleProfileSidebar())
            }
        }
    }, [dispatch, profileSidebar])

    return (
        <Box
            className={`sidebar ${!profileSidebar && 'closed'}`}
            sx={{ display: 'flex' }}
        >
            <CssBaseline />
            <Drawer
                variant="permanent"
                open={profileSidebar}
                className="sidebar__sidebar"
            >
                <DrawerHeader>
                    <IconButton onClick={handleSidebarToggle}>
                        <MenuIcon className="sidebar__menuIcon" color="secondary" />
                    </IconButton>
                </DrawerHeader>
                <List>
                    <ListItem
                        button
                        key={'profile'}
                        onClick={() => handleNavigation(profileRef)}
                    >
                        <ListItemIcon>
                            <AccountCircleIcon color="secondary" />
                        </ListItemIcon>
                        <ListItemText primary={'Profile'} />
                    </ListItem>
                    {!user.mockInterviewBan && (
                        <ListItem
                            button
                            key={'slots'}
                            onClick={() => handleNavigation(slotsRef)}
                        >
                            <ListItemIcon>
                                <AddBoxIcon color="secondary" />
                            </ListItemIcon>
                            <ListItemText primary={'Slots'} />
                        </ListItem>
                    )}
                    <ListItem
                        button
                        key={'ratings'}
                        onClick={() => handleNavigation(ratingRef)}
                    >
                        <ListItemIcon>
                            <TimelineIcon color="secondary" />
                        </ListItemIcon>
                        <ListItemText primary={'Ratings'} />
                    </ListItem>
                    <ListItem
                        button
                        key={'history'}
                        onClick={() => handleNavigation(historyRef)}
                    >
                        <ListItemIcon>
                            <HistoryIcon color="secondary" />
                        </ListItemIcon>
                        <ListItemText primary={'History'} />
                    </ListItem>
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                {children}
            </Box>
        </Box>
    )
}

export default Sidebar

import axios from 'axios'

import * as actionTypes from '../actionTypes'
import toastNotification from '../../components/utils/Notification/Notification'
import axiosInstance from '../../service/axios'
import {
    CODE_LANGUAGE_MAPPING,
    CODE_COMPILE_RESULT_FETCH_INTERVAL,
} from '../../constants/vars'

export const getInterviewCode =
    ({ meetingID, userID }) =>
    async (dispatch) => {
        try {
            dispatch({ type: actionTypes.GET_INTERVIEW_CODE_REQUEST })

            const { data } = await axios.get(
                `${process.env.REACT_APP_SERVERDOM}/mock-interview-code/getCode/${meetingID}/${userID}`,
                { withCredentials: true }
            )

            dispatch({
                type: actionTypes.GET_INTERVIEW_CODE_SUCCESS,
                payload: data.code || '',
            })
        } catch (err) {
            dispatch({
                type: actionTypes.GET_INTERVIEW_CODE_FAIL,
                payload: err.message,
            })

            toastNotification(
                'Error in fetching interview code for this interview! Try again later.'
            )
        }
    }

export const runInterviewCode =
    ({ code, input }) =>
    async (dispatch, getState) => {
        const { codeLanguage } = getState().code
        const { user } = getState().userInfo

        try {
            dispatch({ type: actionTypes.RUN_INTERVIEW_CODE_REQUEST })
            dispatch({ type: actionTypes.TOGGLE_CODE_RESULT_PANEL, payload: true })

            const reqBody = {
                code,
                language: CODE_LANGUAGE_MAPPING[codeLanguage],
                user_id: user.id,
                stdin: input,
            }

            const { data } = await axiosInstance.post('api/v1/remote-code', reqBody)

            if (!data.id) throw new Error('Server Error [500]')

            dispatch({
                type: actionTypes.RUN_INTERVIEW_CODE_SUCCESS,
                payload: data.id,
            })
        } catch (err) {
            dispatch({
                type: actionTypes.RUN_INTERVIEW_CODE_FAIL,
                payload: err.message,
            })

            toastNotification(
                'Not able to compile your code at the moment! Try again later.'
            )
        }
    }

export const getCodeCompileResult = () => async (dispatch, getState) => {
    const { resultID } = getState().code

    try {
        dispatch({ type: actionTypes.GET_CODE_COMPILE_RESULT_REQUEST })

        let reqCount = 1
        const intervalID = setInterval(async () => {
            const { data } = await axiosInstance.get(`api/v1/remote-code/${resultID}`)

            if (data?.code_status !== '') {
                clearInterval(intervalID)

                let result = [data.output, data.stderr]
                result = result.map((string) => string?.replaceAll(/\n/g, '<br />'))

                dispatch({
                    type: actionTypes.GET_CODE_COMPILE_RESULT_SUCCESS,
                    payload: {
                        status: data.code_status || data.status,
                        result: result || null,
                        compileCodeLoading: !Boolean(data.code_status),
                    },
                })
            }

            // automatic timeout after 10 seconds (i.e. 2 GET requests)
            if (reqCount > 2) {
                clearInterval(intervalID)
                dispatch({
                    type: actionTypes.SET_CODE_COMPILE_REQUEST_TIMEOUT,
                })
            }
            reqCount++
        }, CODE_COMPILE_RESULT_FETCH_INTERVAL)
    } catch (err) {
        dispatch({
            type: actionTypes.GET_CODE_COMPILE_RESULT_FAIL,
            payload: err.message,
        })

        toastNotification('Not able to compile your code at the moment! Try again later.')
    }
}

export const toggleCodeTheme = () => (dispatch) => {
    dispatch({
        type: actionTypes.TOGGLE_CODE_THEME,
    })
}

export const codeLanguageChange = (newLanguage) => (dispatch) => {
    dispatch({ type: actionTypes.CODE_LANGUAGE_CHANGE, payload: newLanguage })
}

export const syncCodeStoreData = (codeStoreData) => (dispatch) => {
    dispatch({ type: actionTypes.SET_SYNCED_CODE_STORE_DATA, payload: codeStoreData })
}

import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IconButton } from '@mui/material'

import getIcon from '../../../utils/getIcon'
import {
    fetchPromotionBanner,
    closePromotionBanner,
} from '../../../store/actions/promotionBanner'
import './PromotionBanner.css'

const PromotionBanner = () => {
    const dispatch = useDispatch()
    const { banners, showBanner } = useSelector((state) => state.promotionBanner)
    const { user } = useSelector((state) => state.userInfo)

    useEffect(() => {
        dispatch(fetchPromotionBanner())
    }, [dispatch])

    const bannerToDisplay = () => {
        if (user.email) {
            return banners.signedIn || { content: '', isActive: false }
        } else {
            return banners.nonSignedIn || { content: '', isActive: false }
        }
    }

    const isBannerHidden = !(showBanner && bannerToDisplay().isActive)

    return (
        <div className={`promotionBanner ${isBannerHidden ? 'hidden' : ''}`}>
            <div className="promotionBanner__content">
                <div className="promotionBanner__gramophoneIcon">
                    {getIcon('GRAMOPHONE')}
                </div>
                <div
                    dangerouslySetInnerHTML={{ __html: bannerToDisplay().content }}
                ></div>
            </div>
            <IconButton
                className="promotionBanner__closeButton"
                onClick={() => {
                    dispatch(closePromotionBanner())
                }}
            >
                {getIcon('CROSS')}
            </IconButton>
        </div>
    )
}

export default PromotionBanner

import * as actionTypes from '../actionTypes'
import axiosInstance from '../../service/axios'
import parseChats from '../../utils/parseChats'
import axios from 'axios'
import moment from 'moment'

export const messageUnreadCount = (count) => (dispatch) => {
    dispatch({ type: actionTypes.UNREAD_MESSAGE_COUNT, payload: count })
}

export const changeTesterId = (id) => (dispatch) => {
    dispatch({ type: actionTypes.TESTER_ID, payload: id })
}
export const messagePush = (newMessage) => (dispatch) => {
    dispatch({ type: actionTypes.CHAT_MESSAGE_PUSH, payload: newMessage })
}

export const updateRoomUsersCount = (count) => (dispatch) => {
    dispatch({ type: actionTypes.ROOM_USERS_COUNT, payload: count })
}
export const toggleRolesFlip = (roleFlipTime) => (dispatch) => {
    dispatch({ type: actionTypes.TOGGLE_FLIP_ROLE, payload: roleFlipTime })
}

export const toggleAudio = () => (dispatch) => {
    dispatch({
        type: actionTypes.TOGGLE_AUDIO,
    })
}
export const toggleFreezedScreen = () => (dispatch) => {
    dispatch({
        type: actionTypes.TOGGLE_FREEZED_SCREEN,
    })
}

export const toggleVideo = () => (dispatch) => {
    dispatch({
        type: actionTypes.TOGGLE_VIDEO,
    })
}

export const toggleShareScreen = () => (dispatch) => {
    dispatch({
        type: actionTypes.TOGGLE_SHARE_SCREEN,
    })
}

export const toggleUserShareScreen = () => (dispatch) => {
    dispatch({
        type: actionTypes.TOGGLE_USER_SHARE_SCREEN,
    })
}

export const toggleDrawingBoard = (drawingBoardVar) => (dispatch) => {
    dispatch({
        type: actionTypes.TOGGLE_DRAWING_BOARD,
        payload: drawingBoardVar,
    })
}

export const toggleShowRoleModal = () => (dispatch) => {
    dispatch({
        type: actionTypes.SHOW_ROLE_MODAL,
    })
}

export const toggleTester = () => (dispatch) => {
    dispatch({
        type: actionTypes.TOGGLE_TESTER,
    })
}

export const toggleBackgroundBlur = () => (dispatch) => {
    dispatch({
        type: actionTypes.TOGGLE_BACKGROUND_BLUR,
    })
}

export const enterInterview = (data) => (dispatch) => {
    dispatch({
        type: actionTypes.ENTER_INTERVIEW,
        payload: data,
    })
}

export const swapRole = (meetInfo) => async (dispatch, getState) => {
    try {
        const { testerId } = getState().meeting
        const newTesterID =
            meetInfo.userID === testerId ? meetInfo.remoteUser.id : meetInfo.userID
        dispatch(changeTesterId(newTesterID))

        dispatch(toggleFreezedScreen())

        const response = await axiosInstance.post('/api/v1/meeting/swap', {
            meeting_id: meetInfo.roomID,
        })

        dispatch({
            type: actionTypes.TOGGLE_TESTER,
        })

        dispatch({
            type: actionTypes.TOGGLE_FLIP_ROLE,
            payload: response.data * 1000,
        })

        dispatch({
            type: actionTypes.TOGGLE_CLEAR_CODE,
        })

        setTimeout(() => {
            dispatch({
                type: actionTypes.TOGGLE_CLEAR_CODE,
            })
            dispatch(toggleShowRoleModal())
        }, 5000)
    } catch (error) {
        console.log(error)
    }
}
export const expireMeet = (meetingID) => async (dispatch) => {
    try {
        await axiosInstance.post('/api/v1/meeting/expire', { meeting_id: meetingID })

        dispatch({ type: actionTypes.SET_INTERVIEW_ENDED })
    } catch (err) {
        console.log('[meetEnd()] Error: ', err.message)
    }
}

export const chatInitialFetch =
    (meetingID = '') =>
    async (dispatch) => {
        try {
            const { data } = await axiosInstance.get(`api/v1/chats/${meetingID}`)

            dispatch({
                type: actionTypes.CHAT_INITIAL_FETCH,
                payload: parseChats({ chatsData: data }),
            })
        } catch (err) {
            console.log('Error in fetching initial chats! ---> ', err.message)
        }
    }

export const getSocketData = (data) => () => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${process.env.REACT_APP_SERVERDOM}/socketData/${data.MeetId}/${data.UserId}`,
                {
                    withCredentials: true,
                }
            )
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => reject(err))
    })
}

export const getDrawingImage = (data) => () => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${process.env.REACT_APP_SERVERDOM}/drawingImage/${data.meetingId}/${data.drawingSaveId}`,
                {
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log(res.data)
                resolve(res.data)
            })
            .catch((err) => reject(err))
    })
}

export const getQuestion = (questionID) => async (dispatch) => {
    if (Number(questionID) === 0) {
        dispatch({
            type: actionTypes.SET_INTERVIEW_QUESTION,
            payload: { id: 0, name: 'No question found!', description: '' },
        })
        return
    }

    try {
        dispatch({ type: actionTypes.TOGGLE_LOADING })
        const { data } = await axiosInstance.get(`/api/v1/question/${questionID}`)
        dispatch({ type: actionTypes.SET_INTERVIEW_QUESTION, payload: data })
    } catch (err) {
        console.log(err)
    } finally {
        dispatch({ type: actionTypes.TOGGLE_LOADING })
    }
}

export const saveUserAction = (user_id, meeting_id, type) => () => {
    const data = {
        user_id,
        meeting_id,
        type,
        action_time: moment().unix(),
    }
    axiosInstance.post('/api/v1/activity', data)
}

export const SCHEDULE_INTERVIEW_FLOW_DATA = [
    {
        id: 1,
        title: 'Login',
        content: 'Login to your account',
        icon: 'LOGIN',
    },
    {
        id: 2,
        title: 'Schedule',
        content: 'Select a date and time',
        icon: 'CLOCK',
    },
    {
        id: 3,
        title: 'Smart Match',
        content: 'Wait to get matched',
        icon: 'SWAP_ARROWS',
    },
    {
        id: 4,
        title: "Let's Go!",
        content: 'Ace the interview',
        icon: 'COMPUTER',
    },
]

export const INSTANT_INTERVIEW_FLOW_DATA = [
    {
        id: 1,
        title: 'Details',
        content: 'Enter your Interview details',
        icon: 'LOGIN',
    },
    {
        id: 2,
        title: 'Smart Match',
        content: 'We match you with a peer and you enter the room',
        icon: 'CLOCK',
    },
    {
        id: 3,
        title: 'Swap',
        content: 'The 90-minute meeting is split into two parts of 45 minutes each',
        icon: 'SWAP_ARROWS',
    },
    {
        id: 4,
        title: "Let's Go!",
        content:
            'You will be assigned a role of an interviewer or interviewee in the first half. Roles flip after 45 minutes',
        icon: 'COMPUTER',
    },
]

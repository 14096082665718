const ScreenShareVideo = ({ isVisible, videoRef }) => {
    return (
        <video
            className={`meeting__screenVideo ${
                isVisible && 'meeting__screenVideo--show'
            }`}
            playsInline
            muted
            autoPlay
            ref={videoRef}
        />
    )
}

export default ScreenShareVideo

import moment from 'moment'

import * as actionTypes from '../actionTypes'
import Axios from '../../service/axios'
import { getAllMeetings } from './meetings'
import toastNotification from '../../components/utils/Notification/Notification'

export const getUpcomingInterviews = () => async (dispatch, getState) => {
    try {
        await dispatch(getAllMeetings())
        const { activeMeetings } = getState().meetings

        dispatch({ type: actionTypes.GET_UPCOMING_INTERVIEWS_REQUEST })

        const { data } = await Axios.get('api/v1/mock-interviews')

        const unmatchedInterviews = []
        data.interview_submissions.forEach((submission) => {
            if (
                moment(submission.starttime * 1000).isAfter(moment()) &&
                submission.status === 0
            ) {
                unmatchedInterviews.push({
                    id: submission.id,
                    starttime: submission.starttime,
                    status: 0,
                    type: submission.type,
                    event_id : submission.event_id
                })
            }
        })

        const matchedInterviews = []
        activeMeetings.forEach((interview) => {
            if (moment(interview.end_time * 1000).isAfter(moment())) {
                matchedInterviews.push({
                    id: interview.id,
                    starttime: interview.start_time,
                    status: 1,
                    type: interview.type,
                })
            }
        })

        let upcomingInterviews = [...unmatchedInterviews, ...matchedInterviews]
        upcomingInterviews.sort((a, b) => {
            return a.starttime - b.starttime
        })

        dispatch({
            type: actionTypes.GET_UPCOMING_INTERVIEWS_SUCCESS,
            payload: upcomingInterviews,
        })
    } catch (err) {
        dispatch({
            type: actionTypes.GET_UPCOMING_INTERVIEWS_FAIL,
            payload: err?.message,
        })

        toastNotification(
            "Can't fetch your upcoming interviews! Try refreshing the page."
        )
    }
}

export const cancelInterview =
    ({ interviewID, interviewStatus, cancelReason }) =>
    async (dispatch) => {
        try {
            dispatch({
                type: actionTypes.CANCEL_INTERVIEW_REQUEST,
                payload: { interviewID, interviewStatus },
            })

            if (interviewStatus === 0) {
                await Axios.delete(`api/v1/mock-interviews/${interviewID}`)
            } else {
                await Axios.post('api/v1/meeting/cancel', {
                    meeting_id: interviewID,
                    cancel_reason: cancelReason
                })
            }

            dispatch({ type: actionTypes.CANCEL_INTERVIEW_SUCCESS })

            dispatch({
                type: actionTypes.REMOVE_CANCELLED_INTERVIEW,
                payload: interviewID,
            })
        } catch (err) {
            const errorMessage = err?.response?.data?.message
                ? err?.response?.data?.message
                : "Can't cancel your interview! Please try again."

            dispatch({
                type: actionTypes.CANCEL_INTERVIEW_FAIL,
                payload: errorMessage,
            })

            toastNotification(errorMessage)
        }
    }

import { useEffect, useState } from 'react'
import isFastNet from 'isfastnet'
import { Typography } from '@mui/material'
import SignalWifiStatusbarConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4'

import {
    INTERNET_SPEED_CHECK_INTERVAL,
    INTERNET_SPEED_LATENCY_THRESHOLD,
} from '../../../constants/vars'
import './InternetSpeedPopup.css'

const InternetSpeedPopup = () => {
    const [showPopup, setShowPopup] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => {
            isFastNet(
                (isFast) => {
                    setShowPopup(!isFast)
                },
                {
                    verbose: false,
                    threshold: INTERNET_SPEED_LATENCY_THRESHOLD,
                    timesToTest: 2,
                    allowEarlyExit: true,
                }
            )
        }, INTERNET_SPEED_CHECK_INTERVAL)

        return () => {
            clearInterval(interval)
        }
    }, [])

    return (
        <div className={`internetSpeedPopup ${showPopup && 'internetSpeedPopup--show'}`}>
            <SignalWifiStatusbarConnectedNoInternet4Icon color="error" />
            <Typography variant="body1" className="internetSpeedPopup__message">
                Your internet speed is slow! You may experience issues with audio or
                video.
            </Typography>
        </div>
    )
}

export default InternetSpeedPopup

import { toast } from 'react-toastify'
import { Typography } from '@mui/material'

const ChatNotification = ({ sender, message }) => {
    return (
        <div className="chatNotification">
            <Typography
                variant="h1"
                color="grey.dark"
                letterSpacing="-0.025em"
                fontSize="0.85rem"
                lineHeight="1rem"
                fontWeight="500"
            >
                {sender}
            </Typography>
            <Typography
                variant="body1"
                color="grey.dark"
                fontSize="1rem"
                lineHeight="1.4rem"
                className="chatNotification__message"
            >
                {message}
            </Typography>
        </div>
    )
}

const toastChatNotification = ({ senderName, message }) =>
    toast(<ChatNotification sender={senderName} message={message} />, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 6000,
    })

export default toastChatNotification

import { INTERVIEW_TYPES } from './vars'

const FEEDBACK_PARAMS = {
    [INTERVIEW_TYPES.coding]: [
        {
            type: 'coding1',
            title: 'Coding',
            description: 'Was the code readable, correct and efficient?',
        },
        {
            type: 'coding2',
            title: 'Problem Solving',
            description:
                'Was there clarity of thought, did they pick up hints and have a flow to the solution?',
        },
        {
            type: 'coding3',
            title: 'Communication',
            description:
                'Did the candidate clarify requirements, communicate effectively and explain their solutions?',
        },
    ],
    [INTERVIEW_TYPES.frontend]: [
        {
            type: 'frontend1',
            title: 'Coding',
            description: 'Was the code readable, correct and efficient?',
        },
        {
            type: 'frontend2',
            title: 'Technical skills',
            description:
                'Did the candidate modularise the code, use design patterns, follow software and UX principles?',
        },
        {
            type: 'frontend3',
            title: 'Communication',
            description:
                'Did the candidate clarify requirements, communicate effectively and explain their solutions?',
        },
    ],
    [INTERVIEW_TYPES.systemDesign]: [
        {
            type: 'systemDesign1',
            title: 'Design skills',
            description:
                'Did the candidate correctly design the services, data schema, and end to end interactions?',
        },
        {
            type: 'systemDesign2',
            title: 'Technical skills',
            description: 'Did they discuss tradeoffs, fault tolerance, system internals?',
        },
        {
            type: 'systemDesign3',
            title: 'Communication',
            description:
                'Were the requirements clarified, did they use precise technical terms and explain their thought process?',
        },
    ],
    [INTERVIEW_TYPES.systemDesignLLD]: [
        {
            type: 'systemDesignLLD1',
            title: 'Design skills',
            description:
                'Did the candidate correctly design the services, data schema, and end to end interactions?',
        },
        {
            type: 'systemDesignLLD2',
            title: 'Technical skills',
            description: 'Did they discuss tradeoffs, fault tolerance, system internals?',
        },
        {
            type: 'systemDesignLLD3',
            title: 'Communication',
            description:
                'Were the requirements clarified, did they use precise technical terms and explain their thought process?',
        },
    ],
    [INTERVIEW_TYPES.behavioral]: [
        {
            type: 'behavioral1',
            title: 'Work experience quality',
            description:
                'Did the candidate describe their methods with past examples, and were the stories sufficiently detailed?',
        },
        {
            type: 'behavioral2',
            title: 'Team Fit',
            description:
                'Is the candidate someone you would like to have on the team? Why or why not?',
        },
        {
            type: 'behavioral3',
            title: 'Communication',
            description: 'Did the candidate communicate clearly and effectively?',
        },
    ],
}

export default FEEDBACK_PARAMS

import React from 'react'
const getIcon = (iconName) => {
    switch (iconName) {
        case 'VIDEO_CAMERA':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#fff">
                    <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
                </svg>
            )
        case 'SETTINGS':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                    />
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                </svg>
            )

        case 'BEST_MATCH':
            return (
                <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="15 15 63 63"
                    fill="#fff"
                    strokeWidth={3}
                >
                    <g>
                        <path
                            d="M55.6,45.1l-4.9-1.6l-0.6,1.9l2.8,0.9c-2.4,1.1-5.1,1.5-7.7,0.9c-4.3-0.9-7.7-4.2-8.9-8.4l-1.9,0.5
c0.2,0.7,0.4,1.3,0.7,1.9c-2.4,1-5.2,0.1-6.7-2.1c-1.6-2.6-0.9-6,1.7-7.6c0.9-0.5,1.8-0.8,2.8-0.9l0-2c-1.4,0-2.7,0.4-3.9,1.2
c-3.5,2.2-4.5,6.9-2.3,10.4c1.4,2.2,3.9,3.5,6.3,3.5c1,0,2-0.2,3-0.6c2,3.1,5.1,5.3,8.8,6.1c1,0.2,2,0.3,3,0.3c2,0,4-0.4,5.8-1.3
l-0.8,2.4l1.9,0.6l1.6-4.9C56.4,45.8,56.2,45.3,55.6,45.1z"
                        />
                        <path
                            d="M38.6,34.2c-0.6-0.3-1.2-0.6-1.9-0.8c-2.3-0.5-4.7,0.4-6.1,2.3l1.6,1.2c1-1.3,2.6-1.8,4.1-1.5
c1.1,0.2,2,0.9,2.6,1.8c0.6,0.9,0.8,2,0.5,3.1l2,0.4c0.4-1.9-0.1-3.7-1.2-5.1c0.7-1.4,2.3-2.2,3.9-1.9c0.2,0,0.4,0.1,0.6,0.2
l0.8-1.8c-0.3-0.1-0.6-0.2-1-0.3C42.1,31.2,39.8,32.3,38.6,34.2z"
                        />
                        <path
                            d="M64,33.1c-2-3.1-6-4.3-9.3-2.8c-2-3.1-5.1-5.3-8.8-6.1c-3-0.6-6-0.3-8.8,1l0.8-2.4l-1.9-0.6L34.4,27
c-0.1,0.3-0.1,0.5,0.1,0.8s0.3,0.4,0.6,0.5l4.9,1.6l0.6-1.9l-2.8-0.9c2.4-1.1,5.1-1.5,7.7-0.9c4.3,0.9,7.7,4.2,8.9,8.4l1.9-0.5
c-0.2-0.7-0.4-1.3-0.7-1.9c2.4-1,5.2-0.1,6.7,2.1c0.8,1.2,1.1,2.7,0.7,4.2c-0.3,1.4-1.2,2.7-2.4,3.5c-0.9,0.5-1.8,0.8-2.8,0.9l0,2
c1.4,0,2.7-0.4,3.9-1.2C65.2,41.3,66.3,36.6,64,33.1z"
                        />
                        <path
                            d="M46.7,39.7c-0.2,0-0.4-0.1-0.6-0.2l-0.8,1.8c0.3,0.1,0.6,0.2,1,0.3c0.4,0.1,0.8,0.1,1.2,0.1
c1.9,0,3.7-1,4.7-2.6c0.6,0.3,1.2,0.6,1.9,0.8c0.4,0.1,0.9,0.1,1.3,0.1c1.9,0,3.7-0.9,4.8-2.4l-1.6-1.2c-1,1.3-2.6,1.8-4.1,1.5
c-1.1-0.2-2-0.9-2.6-1.8c-0.6-0.9-0.8-2-0.5-3.1l-2-0.4c-0.4,1.9,0.1,3.7,1.2,5.1C49.9,39.2,48.3,40,46.7,39.7z"
                        />
                        <path
                            d="M20.9,60.4v-9.6c0-0.5-0.3-0.9-0.8-1L15.9,49l4.8-9.7c0.1-0.1,0.1-0.3,0.1-0.4c0-11,9-20,20-20
c5.4,0,10.4,2.1,14.2,5.9l1.4-1.4c-4.2-4.2-9.7-6.5-15.6-6.5c-12.1,0-21.9,9.7-22,21.8l-5.3,10.6c-0.1,0.3-0.1,0.6,0,0.9
c0.1,0.3,0.4,0.5,0.7,0.5l4.6,0.9v8.8c0,2.3,1.9,4.2,4.2,4.2h3.2v8.3h2v-8.3h3.5v-2H23C21.8,62.6,20.9,61.6,20.9,60.4z"
                        />
                        <path d="M37.2,54.7l-1.4,1.4l7.9,7.9v8.9h2v-9.3c0-0.3-0.1-0.5-0.3-0.7L37.2,54.7z" />
                        <path
                            d="M77.2,49.2l-5.3-10.6c-0.1-9.6-6.3-17.9-15.5-20.8l-0.6,1.9c8.4,2.6,14.1,10.3,14.1,19.1c0,0.2,0,0.3,0.1,0.4
l4.8,9.7l-4.1,0.8c-0.5,0.1-0.8,0.5-0.8,1v9.6c0,1.2-1,2.2-2.2,2.2h-8.7v2h3.5v8.3h2v-8.3h3.2c2.3,0,4.2-1.9,4.2-4.2v-8.8l4.6-0.9
c0.3-0.1,0.6-0.3,0.7-0.5C77.3,49.9,77.3,49.5,77.2,49.2z"
                        />
                    </g>
                </svg>
            )

        case 'CODE_EDITOR':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#fff">
                    <path
                        fillRule="evenodd"
                        d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                    />
                </svg>
            )

        case 'MONITOR_ROOM':
            return (
                <svg
                    fill="#fff"
                    xmlns="http://www.w3.org/2000/svg"
                    space="preserve"
                    viewBox="0 0 24 24"
                    x="0px"
                    y="0px"
                    fillRule="evenodd"
                    clipRule="evenodd"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="#fff"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2.2"
                            d="M14 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-9 7h14a7 7"
                        />
                    </svg>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="-19 -16.3 43 43"
                        stroke="#fff"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.3"
                            d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                        />
                    </svg>
                </svg>
            )

        case 'RATING':
            return (
                <svg
                    version="1.1"
                    fill="#fff"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                >
                    <path d="M15.393 18l0.173 3.516-3.556-2.471-3.576 2.474 0.173-3.518h-1.502l-0.259 5.25h1.72l3.441-2.38 3.425 2.38h1.72l-0.259-5.25h-1.502z"></path>
                    <path d="M19.472 7.262l-0.103-2.253-1.899-1.215-1.215-1.899-2.253-0.103-2.002-1.037-2.002 1.037-2.253 0.103-1.215 1.899-1.899 1.215-0.103 2.253-1.037 2.002 1.037 2.002 0.103 2.253 1.899 1.215 1.215 1.899 2.253 0.103 2.002 1.037 2.002-1.037 2.253-0.103 1.215-1.899 1.899-1.215 0.103-2.253 1.037-2.002zM17.989 10.869l-0.082 1.805-1.522 0.974-0.974 1.522-1.805 0.082-1.605 0.831-1.605-0.831-1.805-0.082-0.974-1.522-1.522-0.974-0.082-1.805-0.831-1.605 0.831-1.605 0.082-1.805 1.522-0.974 0.974-1.522 1.805-0.083 1.605-0.831 1.605 0.831 1.805 0.083 0.974 1.522 1.522 0.974 0.082 1.805 0.831 1.605z"></path>
                </svg>
            )

        case 'FREE':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#fff">
                    <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                </svg>
            )

        case 'DETAILED_FEEDBACK':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#fff">
                    <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                    <path
                        fillRule="evenodd"
                        d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                        clipRule="evenodd"
                    />
                </svg>
            )

        case 'LINKEDIN':
            return (
                <svg viewBox="0 0 382 382" space="preserve" fill="#9ca3af">
                    <path
                        d="M347.445,0H34.555C15.471,0,0,15.471,0,34.555v312.889C0,366.529,15.471,382,34.555,382h312.889
              C366.529,382,382,366.529,382,347.444V34.555C382,15.471,366.529,0,347.445,0z M118.207,329.844c0,5.554-4.502,10.056-10.056,10.056
              H65.345c-5.554,0-10.056-4.502-10.056-10.056V150.403c0-5.554,4.502-10.056,10.056-10.056h42.806
              c5.554,0,10.056,4.502,10.056,10.056V329.844z M86.748,123.432c-22.459,0-40.666-18.207-40.666-40.666S64.289,42.1,86.748,42.1
              s40.666,18.207,40.666,40.666S109.208,123.432,86.748,123.432z M341.91,330.654c0,5.106-4.14,9.246-9.246,9.246H286.73
              c-5.106,0-9.246-4.14-9.246-9.246v-84.168c0-12.556,3.683-55.021-32.813-55.021c-28.309,0-34.051,29.066-35.204,42.11v97.079
              c0,5.106-4.139,9.246-9.246,9.246h-44.426c-5.106,0-9.246-4.14-9.246-9.246V149.593c0-5.106,4.14-9.246,9.246-9.246h44.426
              c5.106,0,9.246,4.14,9.246,9.246v15.655c10.497-15.753,26.097-27.912,59.312-27.912c73.552,0,73.131,68.716,73.131,106.472
              L341.91,330.654L341.91,330.654z"
                    />
                </svg>
            )

        case 'DISCORD':
            return (
                <svg
                    fill="#9ca3af"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 245 240"
                >
                    <path d="M104.4 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1.1-6.1-4.5-11.1-10.2-11.1zM140.9 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1s-4.5-11.1-10.2-11.1z" />
                    <path d="M189.5 20h-134C44.2 20 35 29.2 35 40.6v135.2c0 11.4 9.2 20.6 20.5 20.6h113.4l-5.3-18.5 12.8 11.9 12.1 11.2 21.5 19V40.6c0-11.4-9.2-20.6-20.5-20.6zm-38.6 130.6s-3.6-4.3-6.6-8.1c13.1-3.7 18.1-11.9 18.1-11.9-4.1 2.7-8 4.6-11.5 5.9-5 2.1-9.8 3.5-14.5 4.3-9.6 1.8-18.4 1.3-25.9-.1-5.7-1.1-10.6-2.7-14.7-4.3-2.3-.9-4.8-2-7.3-3.4-.3-.2-.6-.3-.9-.5-.2-.1-.3-.2-.4-.3-1.8-1-2.8-1.7-2.8-1.7s4.8 8 17.5 11.8c-3 3.8-6.7 8.3-6.7 8.3-22.1-.7-30.5-15.2-30.5-15.2 0-32.2 14.4-58.3 14.4-58.3 14.4-10.8 28.1-10.5 28.1-10.5l1 1.2c-18 5.2-26.3 13.1-26.3 13.1s2.2-1.2 5.9-2.9c10.7-4.7 19.2-6 22.7-6.3.6-.1 1.1-.2 1.7-.2 6.1-.8 13-1 20.2-.2 9.5 1.1 19.7 3.9 30.1 9.6 0 0-7.9-7.5-24.9-12.7l1.4-1.6s13.7-.3 28.1 10.5c0 0 14.4 26.1 14.4 58.3 0 0-8.5 14.5-30.6 15.2z" />
                </svg>
            )

        case 'YOUTUBE':
            return (
                <svg
                    fill="#9ca3af"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="m.522 17.874c.49 1.738 1.989 2.056 2.089 2.117 2.467.672 16.295.674 18.799 0 1.715-.496 2.03-2.017 2.089-2.117.653-3.474.696-8.003-.03-11.945l.03.196c-.49-1.738-1.989-2.056-2.089-2.117-2.434-.661-16.298-.686-18.799 0-1.715.497-2.03 2.017-2.089 2.117-.699 3.651-.734 7.84 0 11.749zm9.086-2.223v-7.293l6.266 3.652z" />
                </svg>
            )

        case 'TWITTER':
            return (
                <svg fill="#9ca3af" viewBox="0 0 24 24">
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
            )

        case 'INSTAGRAM':
            return (
                <svg fill="#9ca3af" viewBox="0 0 24 24">
                    <path
                        fillRule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clipRule="evenodd"
                    />
                </svg>
            )

        case 'DOTS_BG':
            return (
                <svg width="404" height="784" fill="none" viewBox="0 0 404 784">
                    <defs>
                        <pattern
                            id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                            x="0"
                            y="0"
                            width="20"
                            height="20"
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x="0" y="0" width="4" height="4" fill="#9ca3af" />
                        </pattern>
                    </defs>
                    <rect
                        width="404"
                        height="784"
                        fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
                    />
                </svg>
            )

        case 'DUAL_ROLE':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 24 24">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                </svg>
            )

        case 'TRACK_PROGRESS':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 24 24">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
                    />
                </svg>
            )

        case 'LOW_RISK':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#fff">
                    <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                    <path
                        fillRule="evenodd"
                        d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                        clipRule="evenodd"
                    />
                </svg>
            )

        case 'MATCHES':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 24 24">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                    />
                </svg>
            )

        case 'LOGIN':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#fff">
                    <path
                        fillRule="evenodd"
                        d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z"
                        clipRule="evenodd"
                    />
                </svg>
            )

        case 'CLOCK':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#fff">
                    <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                        clipRule="evenodd"
                    />
                </svg>
            )

        case 'SWAP_ARROWS':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#fff">
                    <path d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z" />
                </svg>
            )

        case 'COMPUTER':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#fff">
                    <path
                        fillRule="evenodd"
                        d="M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z"
                        clipRule="evenodd"
                    />
                </svg>
            )

        case 'ARROW_UP':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="#0088e2"
                >
                    <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13a1 1 0 102 0V9.414l1.293 1.293a1 1 0 001.414-1.414z"
                        clipRule="evenodd"
                    />
                </svg>
            )

        case 'PAINT':
            return (
                <svg
                    enableBackground="new 0 0 512 512"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g>
                        <g fill="#964c22">
                            <path d="m90.89 84.03c-23.167 0-42.015-18.848-42.015-42.015s18.848-42.015 42.015-42.015c23.168 0 42.016 18.848 42.016 42.015s-18.848 42.015-42.016 42.015z"></path>
                            <path d="m15.251 177.15c-6.275 0-11.995-4.056-14.115-9.948-2.119-5.886-.247-12.693 4.605-16.653 4.858-3.964 11.833-4.485 17.216-1.265 5.368 3.211 8.237 9.659 7.004 15.795-1.391 6.922-7.643 12.071-14.71 12.071z"></path>
                        </g>
                        <g>
                            <g>
                                <path
                                    d="m255.728 512c-64.23 0-125.655-23.892-172.96-67.274-47.005-43.107-76.102-101.69-81.931-164.958-.448-4.867 1.502-9.647 5.227-12.81 3.725-3.164 8.757-4.316 13.487-3.083 5.109 1.33 10.397 2.004 15.714 2.004 34.285 0 62.178-27.893 62.178-62.177 0-2.361-.141-4.78-.419-7.188-.933-8.064 4.721-15.407 12.755-16.568 21.663-3.13 41.386-16.396 54.109-36.397 12.297-19.329 16.146-42.158 10.561-62.633-1.183-4.333-.36-8.968 2.239-12.63 2.6-3.663 6.704-5.967 11.185-6.281 27.894-1.951 51.157-22.458 56.573-49.869 1.404-7.105 7.714-12.19 14.926-12.091 67.615.946 131.031 27.989 178.567 76.147 47.584 48.207 73.789 112.064 73.789 179.808 0 68.38-26.629 132.668-74.98 181.019-48.353 48.353-112.64 74.981-181.02 74.981z"
                                    fill="#fde4c3"
                                ></path>
                                <path
                                    d="m437.939 76.192c-44.777-45.363-103.645-71.981-166.839-75.71v511.048c62.598-3.683 120.936-29.8 165.648-74.511 48.351-48.352 74.98-112.639 74.98-181.019 0-67.744-26.205-131.601-73.789-179.808z"
                                    fill="#fcd1a6"
                                ></path>
                            </g>
                            <g>
                                <path
                                    d="m176.922 364.715c-23.167 0-42.015-18.848-42.015-42.015s18.848-42.015 42.015-42.015c23.168 0 42.016 18.848 42.016 42.015-.001 23.167-18.848 42.015-42.016 42.015z"
                                    fill="#ba602b"
                                ></path>
                                <g fill="#6f3716">
                                    <ellipse
                                        cx="328.295"
                                        cy="362.402"
                                        rx="40.328"
                                        ry="40.328"
                                        transform="matrix(.707 -.707 .707 .707 -160.102 338.285)"
                                    ></ellipse>
                                    <path d="m398.68 275.096c-22.236 0-40.327-18.091-40.327-40.328s18.091-40.328 40.327-40.328c22.237 0 40.328 18.091 40.328 40.328s-18.09 40.328-40.328 40.328z"></path>
                                    <path d="m398.68 152.15c-6.273 0-11.998-4.054-14.119-9.948-2.12-5.892-.235-12.686 4.609-16.653 4.871-3.989 11.817-4.45 17.216-1.26 5.387 3.183 8.222 9.673 6.994 15.79-1.389 6.925-7.625 12.071-14.7 12.071z"></path>
                                </g>
                                <path
                                    d="m430.1 342.07c-6.285 0-11.983-4.047-14.119-9.942-2.134-5.891-.224-12.683 4.609-16.647 4.85-3.979 11.828-4.476 17.211-1.27 5.386 3.207 8.223 9.663 6.999 15.8-1.381 6.924-7.637 12.059-14.7 12.059z"
                                    fill="#964c22"
                                ></path>
                                <path
                                    d="m206.251 229.77c-6.237 0-12.04-4.055-14.114-9.951-2.076-5.902-.268-12.673 4.604-16.649 4.848-3.956 11.847-4.501 17.217-1.265 5.354 3.226 8.254 9.645 7.003 15.795-1.409 6.92-7.629 12.07-14.71 12.07z"
                                    fill="#6f3716"
                                ></path>
                                <path
                                    d="m236.251 441.77c-6.24 0-12.036-4.058-14.11-9.951-2.077-5.902-.274-12.67 4.6-16.649 4.848-3.956 11.847-4.501 17.217-1.265 5.354 3.226 8.254 9.645 7.003 15.795-1.408 6.918-7.628 12.07-14.71 12.07z"
                                    fill="#964c22"
                                ></path>
                                <path
                                    d="m302.06 241.77c-6.277 0-11.996-4.057-14.115-9.951-2.116-5.885-.247-12.69 4.605-16.649 4.859-3.964 11.832-4.488 17.216-1.265 5.369 3.214 8.236 9.654 7.004 15.795-1.389 6.922-7.642 12.07-14.71 12.07z"
                                    fill="#964c22"
                                ></path>
                                <path
                                    d="m75.911 342.07c-6.261 0-12.022-4.037-14.12-9.942-2.095-5.898-.261-12.673 4.6-16.647 4.859-3.973 11.828-4.478 17.217-1.27 5.388 3.207 8.222 9.652 7.003 15.79-1.377 6.928-7.633 12.069-14.7 12.069z"
                                    fill="#964c22"
                                ></path>
                                <g>
                                    <circle
                                        cx="302.06"
                                        cy="127.997"
                                        fill="#ba602b"
                                        r="44.917"
                                    ></circle>
                                    <path
                                        d="m302.06 83.08c-11.994 0-22.896 4.734-30.96 12.421v64.992c8.064 7.687 18.966 12.421 30.96 12.421 24.767 0 44.917-20.15 44.917-44.917s-20.149-44.917-44.917-44.917z"
                                        fill="#964c22"
                                    ></path>
                                </g>
                                <g>
                                    <path
                                        d="m271.1 298.42c-6.286 0-11.984-4.052-14.119-9.946-2.129-5.878-.236-12.704 4.609-16.654 4.865-3.966 11.822-4.469 17.211-1.26 5.389 3.21 8.215 9.649 6.999 15.79-1.371 6.926-7.639 12.07-14.7 12.07z"
                                        fill="#964c22"
                                    ></path>
                                    <path
                                        d="m278.802 270.56c-2.368-1.41-5.039-2.102-7.701-2.1v29.96c7.061 0 13.328-5.144 14.7-12.07 1.216-6.141-1.61-12.581-6.999-15.79z"
                                        fill="#6f3716"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            )

        case 'CROSS':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#fff"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
            )

        case 'CLOCK_TIMER':
            return (
                <svg
                    height="30"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                </svg>
            )
        case 'DOWNLOAD_REPORT':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                        fillRule="evenodd"
                        d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                        clipRule="evenodd"
                    />
                </svg>
            )
        case 'REFRESH':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                    />
                </svg>
            )

        case 'TICK':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#fff">
                    <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                    />
                </svg>
            )

        case 'EXCLAMATION':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#fff">
                    <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                        clipRule="evenodd"
                    />
                </svg>
            )
        case 'CANCEL':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                    />
                </svg>
            )
        case 'ADD_TO_CALENDER':
            return (
                <svg
                    viewBox="0 0 477 493"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M0 99C0 85.1929 11.1929 74 25 74H370C383.807 74 395 85.1929 395 99V177H0V99Z" />
                    <path d="M0 376H225V411H25C11.1929 411 0 399.807 0 386V376Z" />
                    <rect y="177" width="34" height="199" />
                    <rect x="361" y="177" width="34" height="69" />
                    <rect x="73" y="207" width="60" height="60" />
                    <rect x="163" y="207" width="60" height="60" />
                    <rect x="253" y="207" width="60" height="60" />
                    <rect x="163" y="287" width="60" height="60" />
                    <rect x="73" y="287" width="60" height="60" />
                    <path d="M477 379C477 441.96 425.96 493 363 493C300.04 493 249 441.96 249 379C249 316.04 300.04 265 363 265C425.96 265 477 316.04 477 379ZM281.878 379C281.878 423.802 318.198 460.122 363 460.122C407.802 460.122 444.122 423.802 444.122 379C444.122 334.198 407.802 297.878 363 297.878C318.198 297.878 281.878 334.198 281.878 379Z" />
                    <rect x="349" y="327" width="29" height="107" rx="14.5" />
                    <rect
                        x="310"
                        y="395"
                        width="29"
                        height="107"
                        rx="14.5"
                        transform="rotate(-90 310 395)"
                    />
                    <rect x="87" width="32" height="74" fill="white" />
                    <rect x="281" width="32" height="74" fill="white" />
                </svg>
            )

        case 'WHITEBOARD':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                </svg>
            )

        case 'Coding Interview_grey':
            return (
                <svg
                    width="54"
                    height="44"
                    viewBox="0 0 54 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M44 1H10C4.89137 1 0.75 5.14137 0.75 10.25V34.25C0.75 39.3586 4.89137 43.5 10 43.5H44C49.1086 43.5 53.25 39.3586 53.25 34.25V10.25C53.25 5.14137 49.1086 1 44 1Z"
                        stroke="black"
                    />
                    <path d="M0 12.5H54" stroke="black" />
                    <path
                        d="M7.5 9C8.74264 9 9.75 7.99264 9.75 6.75C9.75 5.50736 8.74264 4.5 7.5 4.5C6.25736 4.5 5.25 5.50736 5.25 6.75C5.25 7.99264 6.25736 9 7.5 9Z"
                        stroke="black"
                    />
                    <path
                        d="M15.5 9C16.7426 9 17.75 7.99264 17.75 6.75C17.75 5.50736 16.7426 4.5 15.5 4.5C14.2574 4.5 13.25 5.50736 13.25 6.75C13.25 7.99264 14.2574 9 15.5 9Z"
                        stroke="black"
                    />
                    <path
                        d="M23.5 9C24.7426 9 25.75 7.99264 25.75 6.75C25.75 5.50736 24.7426 4.5 23.5 4.5C22.2574 4.5 21.25 5.50736 21.25 6.75C21.25 7.99264 22.2574 9 23.5 9Z"
                        stroke="black"
                    />
                    <path d="M22.2988 37.9824L30.2988 16.9824" stroke="black" />
                    <path d="M14.5762 25.3991L20.2331 19.7422" stroke="black" />
                    <path d="M38.6424 28.0352L32.9111 33.6167" stroke="black" />
                    <path d="M14.5762 24.9277L20.233 30.5845" stroke="black" />
                    <path d="M38.6372 28.5066L33.0557 22.7754" stroke="black" />
                </svg>
            )

        case 'Coding Interview':
            return (
                <svg
                    width="54"
                    height="44"
                    viewBox="0 0 54 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M44 1H10C4.89137 1 0.75 5.14137 0.75 10.25V34.25C0.75 39.3586 4.89137 43.5 10 43.5H44C49.1086 43.5 53.25 39.3586 53.25 34.25V10.25C53.25 5.14137 49.1086 1 44 1Z"
                        stroke="#004084"
                    />
                    <path d="M0 12.5H54" stroke="#004084" />
                    <path
                        d="M7.5 9C8.74264 9 9.75 7.99264 9.75 6.75C9.75 5.50736 8.74264 4.5 7.5 4.5C6.25736 4.5 5.25 5.50736 5.25 6.75C5.25 7.99264 6.25736 9 7.5 9Z"
                        stroke="#004084"
                    />
                    <path
                        d="M15.5 9C16.7426 9 17.75 7.99264 17.75 6.75C17.75 5.50736 16.7426 4.5 15.5 4.5C14.2574 4.5 13.25 5.50736 13.25 6.75C13.25 7.99264 14.2574 9 15.5 9Z"
                        stroke="#004084"
                    />
                    <path
                        d="M23.5 9C24.7426 9 25.75 7.99264 25.75 6.75C25.75 5.50736 24.7426 4.5 23.5 4.5C22.2574 4.5 21.25 5.50736 21.25 6.75C21.25 7.99264 22.2574 9 23.5 9Z"
                        stroke="#004084"
                    />
                    <path d="M22.2988 37.9824L30.2988 16.9824" stroke="#004084" />
                    <path d="M14.5762 25.3991L20.2331 19.7422" stroke="#004084" />
                    <path d="M38.6424 28.0352L32.9111 33.6167" stroke="#004084" />
                    <path d="M14.5762 24.9277L20.233 30.5845" stroke="#004084" />
                    <path d="M38.6372 28.5066L33.0557 22.7754" stroke="#004084" />
                </svg>
            )

        case 'System Design Interview_grey':
            return (
                <svg
                    width="63"
                    height="58"
                    viewBox="0 0 63 58"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M18.3506 18.3496H41.6506C44.0254 18.3496 45.9506 20.2748 45.9506 22.6496V40.9496H22.6506C20.2758 40.9496 18.3506 39.0244 18.3506 36.6496V18.3496Z"
                        stroke="black"
                    />
                    <path d="M47.25 29.0508H60.05" stroke="black" />
                    <path d="M32.5225 17.5478L32.5839 4.74805" stroke="black" />
                    <path d="M32.5225 54.5479L32.5839 41.748" stroke="black" />
                    <path d="M5.25 29.0508H18.05" stroke="black" />
                    <path d="M13.25 25.0508H18.05" stroke="black" />
                    <path d="M46.25 25.0508H51.05" stroke="black" />
                    <path d="M28.5176 17.5284L28.5406 12.7285" stroke="black" />
                    <path d="M28.5176 46.5284L28.5406 41.7285" stroke="black" />
                    <path d="M13.25 33.0508H18.05" stroke="black" />
                    <path d="M46.25 33.0508H51.05" stroke="black" />
                    <path d="M36.5176 17.5675L36.5406 12.7676" stroke="black" />
                    <path d="M36.5176 46.5676L36.5406 41.7676" stroke="black" />
                    <path d="M7.65039 21.0508H18.6504" stroke="black" />
                    <path d="M45.6504 21.0508H51.6504" stroke="black" />
                    <path d="M24.5169 18.1628L24.0527 12.7012" stroke="black" />
                    <path d="M24.5146 52.1093L24.5674 41.1094" stroke="black" />
                    <path d="M12.6504 37.0508H18.6504" stroke="black" />
                    <path d="M46.6504 37.0508H57.6504" stroke="black" />
                    <path d="M40.5195 18.1854L40.5722 7.18555" stroke="black" />
                    <path d="M41.0533 46.7057L40.5508 41.2441" stroke="black" />
                    <path d="M51.2266 21.2266L59.2269 13.2266" stroke="black" />
                    <path d="M13.0742 37.0742L5.07422 45.0742" stroke="black" />
                    <path d="M41.0742 46.2266L49.0742 54.2266" stroke="black" />
                    <path d="M24.2266 13.0742L16.2266 5.07422" stroke="black" />
                    <path
                        d="M60.15 14.3C61.3374 14.3 62.3 13.3374 62.3 12.15C62.3 10.9626 61.3374 10 60.15 10C58.9626 10 58 10.9626 58 12.15C58 13.3374 58.9626 14.3 60.15 14.3Z"
                        stroke="black"
                    />
                    <path
                        d="M60.15 39.3C61.3374 39.3 62.3 38.3374 62.3 37.15C62.3 35.9626 61.3374 35 60.15 35C58.9626 35 58 35.9626 58 37.15C58 38.3374 58.9626 39.3 60.15 39.3Z"
                        stroke="black"
                    />
                    <path
                        d="M50.15 57.3C51.3374 57.3 52.3 56.3374 52.3 55.15C52.3 53.9626 51.3374 53 50.15 53C48.9626 53 48 53.9626 48 55.15C48 56.3374 48.9626 57.3 50.15 57.3Z"
                        stroke="black"
                    />
                    <path
                        d="M24.15 56.3C25.3374 56.3 26.3 55.3374 26.3 54.15C26.3 52.9626 25.3374 52 24.15 52C22.9626 52 22 52.9626 22 54.15C22 55.3374 22.9626 56.3 24.15 56.3Z"
                        stroke="black"
                    />
                    <path
                        d="M3.15 49.3C4.33741 49.3 5.3 48.3374 5.3 47.15C5.3 45.9626 4.33741 45 3.15 45C1.96259 45 1 45.9626 1 47.15C1 48.3374 1.96259 49.3 3.15 49.3Z"
                        stroke="black"
                    />
                    <path
                        d="M5.15 23.3C6.33741 23.3 7.3 22.3374 7.3 21.15C7.3 19.9626 6.33741 19 5.15 19C3.96259 19 3 19.9626 3 21.15C3 22.3374 3.96259 23.3 5.15 23.3Z"
                        stroke="black"
                    />
                    <path
                        d="M14.15 5.3C15.3374 5.3 16.3 4.33741 16.3 3.15C16.3 1.96259 15.3374 1 14.15 1C12.9626 1 12 1.96259 12 3.15C12 4.33741 12.9626 5.3 14.15 5.3Z"
                        stroke="black"
                    />
                    <path
                        d="M40.15 7.3C41.3374 7.3 42.3 6.33741 42.3 5.15C42.3 3.96259 41.3374 3 40.15 3C38.9626 3 38 3.96259 38 5.15C38 6.33741 38.9626 7.3 40.15 7.3Z"
                        stroke="black"
                    />
                    <path
                        d="M32.8334 22.9683C32.4289 22.203 31.7621 21.6091 30.9553 21.2955C30.1485 20.9818 29.2557 20.9693 28.4404 21.2604C27.6252 21.5514 26.9421 22.1265 26.5163 22.8802C26.0906 23.6339 25.9507 24.5157 26.1222 25.3642"
                        stroke="black"
                    />
                    <path
                        d="M28.3825 25.1259C27.6304 24.9698 26.8481 25.0583 26.15 25.3786C25.4518 25.6988 24.8744 26.2339 24.502 26.9057C24.1297 27.5776 23.982 28.3509 24.0805 29.1126C24.1791 29.8744 24.5187 30.5847 25.0497 31.1396"
                        stroke="black"
                    />
                    <path
                        d="M26.5301 28.7422C25.8436 29.2427 25.3562 29.9698 25.1541 30.795C24.952 31.6202 25.0483 32.4903 25.4259 33.2514C25.8035 34.0124 26.4381 34.6154 27.2175 34.9537C27.9968 35.2919 28.8707 35.3436 29.6845 35.0997"
                        stroke="black"
                    />
                    <path
                        d="M28.0508 35.6743C28.0555 36.173 28.2034 36.6598 28.4771 37.0768C28.7507 37.4937 29.1385 37.8231 29.5942 38.0258C30.0499 38.2284 30.5543 38.2957 31.0472 38.2197C31.5401 38.1436 32.0007 37.9274 32.3741 37.5969C32.7475 37.2663 33.018 36.8353 33.1532 36.3553C33.2885 35.8753 33.2829 35.3665 33.137 34.8896C32.9911 34.4127 32.7111 33.9878 32.3305 33.6656C31.9498 33.3434 31.4845 33.1374 30.9901 33.0723"
                        stroke="black"
                    />
                    <path
                        d="M33.7236 34.7472C34.0291 35.1738 34.4242 35.5285 34.8811 35.7864C35.3381 36.0443 35.846 36.1992 36.3691 36.2402C36.8922 36.2812 37.418 36.2074 37.9096 36.0238C38.4012 35.8403 38.8467 35.5516 39.215 35.1778C39.5832 34.804 39.8653 34.3542 40.0415 33.8599C40.2177 33.3657 40.2837 32.8388 40.2349 32.3164C40.1861 31.7939 40.0237 31.2885 39.759 30.8354C39.4943 30.3823 39.1338 29.9925 38.7027 29.6934"
                        stroke="black"
                    />
                    <path
                        d="M40.0144 31.3646C40.5266 30.9185 40.9021 30.3367 41.0976 29.6862C41.2931 29.0358 41.3007 28.3433 41.1193 27.6887C40.938 27.0342 40.5752 26.4443 40.0728 25.9872C39.5705 25.5301 38.949 25.2244 38.2803 25.1055"
                        stroke="black"
                    />
                    <path
                        d="M38.1273 25.5831C38.3295 24.8288 38.281 24.0292 37.989 23.3049C37.6971 22.5806 37.1775 21.9709 36.5087 21.5677C35.8399 21.1645 35.0581 20.9897 34.2813 21.0697C33.5045 21.1497 32.7747 21.4802 32.2021 22.0112"
                        stroke="black"
                    />
                    <path d="M33.0508 36.05V28.25" stroke="black" />
                    <path
                        d="M33.4141 29.3986C33.8201 29.2278 34.2729 29.2034 34.6949 29.3296C35.1169 29.4557 35.482 29.7245 35.7277 30.0901C35.9734 30.4557 36.0845 30.8952 36.0419 31.3336C35.9993 31.7721 35.8058 32.182 35.4943 32.4935"
                        stroke="black"
                    />
                </svg>
            )

        case 'System Design Interview':
            return (
                <svg
                    width="63"
                    height="58"
                    viewBox="0 0 63 58"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M18.3506 18.3496H41.6506C44.0254 18.3496 45.9506 20.2748 45.9506 22.6496V40.9496H22.6506C20.2758 40.9496 18.3506 39.0244 18.3506 36.6496V18.3496Z"
                        stroke="#004084"
                    />
                    <path d="M47.25 29.0508H60.05" stroke="#004084" />
                    <path d="M32.5225 17.5478L32.5839 4.74805" stroke="#004084" />
                    <path d="M32.5225 54.5479L32.5839 41.748" stroke="#004084" />
                    <path d="M5.25 29.0508H18.05" stroke="#004084" />
                    <path d="M13.25 25.0508H18.05" stroke="#004084" />
                    <path d="M46.25 25.0508H51.05" stroke="#004084" />
                    <path d="M28.5176 17.5284L28.5406 12.7285" stroke="#004084" />
                    <path d="M28.5176 46.5284L28.5406 41.7285" stroke="#004084" />
                    <path d="M13.25 33.0508H18.05" stroke="#004084" />
                    <path d="M46.25 33.0508H51.05" stroke="#004084" />
                    <path d="M36.5176 17.5675L36.5406 12.7676" stroke="#004084" />
                    <path d="M36.5176 46.5676L36.5406 41.7676" stroke="#004084" />
                    <path d="M7.65039 21.0508H18.6504" stroke="#004084" />
                    <path d="M45.6504 21.0508H51.6504" stroke="#004084" />
                    <path d="M24.5169 18.1628L24.0527 12.7012" stroke="#004084" />
                    <path d="M24.5146 52.1093L24.5674 41.1094" stroke="#004084" />
                    <path d="M12.6504 37.0508H18.6504" stroke="#004084" />
                    <path d="M46.6504 37.0508H57.6504" stroke="#004084" />
                    <path d="M40.5195 18.1854L40.5722 7.18555" stroke="#004084" />
                    <path d="M41.0533 46.7057L40.5508 41.2441" stroke="#004084" />
                    <path d="M51.2266 21.2266L59.2269 13.2266" stroke="#004084" />
                    <path d="M13.0742 37.0742L5.07422 45.0742" stroke="#004084" />
                    <path d="M41.0742 46.2266L49.0742 54.2266" stroke="#004084" />
                    <path d="M24.2266 13.0742L16.2266 5.07422" stroke="#004084" />
                    <path
                        d="M60.15 14.3C61.3374 14.3 62.3 13.3374 62.3 12.15C62.3 10.9626 61.3374 10 60.15 10C58.9626 10 58 10.9626 58 12.15C58 13.3374 58.9626 14.3 60.15 14.3Z"
                        stroke="#004084"
                    />
                    <path
                        d="M60.15 39.3C61.3374 39.3 62.3 38.3374 62.3 37.15C62.3 35.9626 61.3374 35 60.15 35C58.9626 35 58 35.9626 58 37.15C58 38.3374 58.9626 39.3 60.15 39.3Z"
                        stroke="#004084"
                    />
                    <path
                        d="M50.15 57.3C51.3374 57.3 52.3 56.3374 52.3 55.15C52.3 53.9626 51.3374 53 50.15 53C48.9626 53 48 53.9626 48 55.15C48 56.3374 48.9626 57.3 50.15 57.3Z"
                        stroke="#004084"
                    />
                    <path
                        d="M24.15 56.3C25.3374 56.3 26.3 55.3374 26.3 54.15C26.3 52.9626 25.3374 52 24.15 52C22.9626 52 22 52.9626 22 54.15C22 55.3374 22.9626 56.3 24.15 56.3Z"
                        stroke="#004084"
                    />
                    <path
                        d="M3.15 49.3C4.33741 49.3 5.3 48.3374 5.3 47.15C5.3 45.9626 4.33741 45 3.15 45C1.96259 45 1 45.9626 1 47.15C1 48.3374 1.96259 49.3 3.15 49.3Z"
                        stroke="#004084"
                    />
                    <path
                        d="M5.15 23.3C6.33741 23.3 7.3 22.3374 7.3 21.15C7.3 19.9626 6.33741 19 5.15 19C3.96259 19 3 19.9626 3 21.15C3 22.3374 3.96259 23.3 5.15 23.3Z"
                        stroke="#004084"
                    />
                    <path
                        d="M14.15 5.3C15.3374 5.3 16.3 4.33741 16.3 3.15C16.3 1.96259 15.3374 1 14.15 1C12.9626 1 12 1.96259 12 3.15C12 4.33741 12.9626 5.3 14.15 5.3Z"
                        stroke="#004084"
                    />
                    <path
                        d="M40.15 7.3C41.3374 7.3 42.3 6.33741 42.3 5.15C42.3 3.96259 41.3374 3 40.15 3C38.9626 3 38 3.96259 38 5.15C38 6.33741 38.9626 7.3 40.15 7.3Z"
                        stroke="#004084"
                    />
                    <path
                        d="M32.8334 22.9683C32.4289 22.203 31.7621 21.6091 30.9553 21.2955C30.1485 20.9818 29.2557 20.9693 28.4404 21.2604C27.6252 21.5514 26.9421 22.1265 26.5163 22.8802C26.0906 23.6339 25.9507 24.5157 26.1222 25.3642"
                        stroke="#004084"
                    />
                    <path
                        d="M28.3825 25.1259C27.6304 24.9698 26.8481 25.0583 26.15 25.3786C25.4518 25.6988 24.8744 26.2339 24.502 26.9057C24.1297 27.5776 23.982 28.3509 24.0805 29.1126C24.1791 29.8744 24.5187 30.5847 25.0497 31.1396"
                        stroke="#004084"
                    />
                    <path
                        d="M26.5301 28.7422C25.8436 29.2427 25.3562 29.9698 25.1541 30.795C24.952 31.6202 25.0483 32.4903 25.4259 33.2514C25.8035 34.0124 26.4381 34.6154 27.2175 34.9537C27.9968 35.2919 28.8707 35.3436 29.6845 35.0997"
                        stroke="#004084"
                    />
                    <path
                        d="M28.0508 35.6743C28.0555 36.173 28.2034 36.6598 28.4771 37.0768C28.7507 37.4937 29.1385 37.8231 29.5942 38.0258C30.0499 38.2284 30.5543 38.2957 31.0472 38.2197C31.5401 38.1436 32.0007 37.9274 32.3741 37.5969C32.7475 37.2663 33.018 36.8353 33.1532 36.3553C33.2885 35.8753 33.2829 35.3665 33.137 34.8896C32.9911 34.4127 32.7111 33.9878 32.3305 33.6656C31.9498 33.3434 31.4845 33.1374 30.9901 33.0723"
                        stroke="#004084"
                    />
                    <path
                        d="M33.7236 34.7472C34.0291 35.1738 34.4242 35.5285 34.8811 35.7864C35.3381 36.0443 35.846 36.1992 36.3691 36.2402C36.8922 36.2812 37.418 36.2074 37.9096 36.0238C38.4012 35.8403 38.8467 35.5516 39.215 35.1778C39.5832 34.804 39.8653 34.3542 40.0415 33.8599C40.2177 33.3657 40.2837 32.8388 40.2349 32.3164C40.1861 31.7939 40.0237 31.2885 39.759 30.8354C39.4943 30.3823 39.1338 29.9925 38.7027 29.6934"
                        stroke="#004084"
                    />
                    <path
                        d="M40.0144 31.3646C40.5266 30.9185 40.9021 30.3367 41.0976 29.6862C41.2931 29.0358 41.3007 28.3433 41.1193 27.6887C40.938 27.0342 40.5752 26.4443 40.0728 25.9872C39.5705 25.5301 38.949 25.2244 38.2803 25.1055"
                        stroke="#004084"
                    />
                    <path
                        d="M38.1273 25.5831C38.3295 24.8288 38.281 24.0292 37.989 23.3049C37.6971 22.5806 37.1775 21.9709 36.5087 21.5677C35.8399 21.1645 35.0581 20.9897 34.2813 21.0697C33.5045 21.1497 32.7747 21.4802 32.2021 22.0112"
                        stroke="#004084"
                    />
                    <path d="M33.0508 36.05V28.25" stroke="#004084" />
                    <path
                        d="M33.4141 29.3986C33.8201 29.2278 34.2729 29.2034 34.6949 29.3296C35.1169 29.4557 35.482 29.7245 35.7277 30.0901C35.9734 30.4557 36.0845 30.8952 36.0419 31.3336C35.9993 31.7721 35.8058 32.182 35.4943 32.4935"
                        stroke="#004084"
                    />
                </svg>
            )

        case 'System Design Interview (Low-Level)_grey':
            return (
                <svg
                    width="63"
                    height="57"
                    viewBox="0 0 63 57"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M18.3506 18.3496H41.6506C44.0254 18.3496 45.9506 20.2748 45.9506 22.6496V40.9496H22.6506C20.2758 40.9496 18.3506 39.0244 18.3506 36.6496V18.3496Z"
                        stroke="black"
                    />
                    <path d="M32.5225 17.5478L32.5839 4.74805" stroke="black" />
                    <path d="M5.25 29.0508H18.05" stroke="black" />
                    <path d="M13.25 25.0508H18.05" stroke="black" />
                    <path d="M46.25 25.0508H51.05" stroke="black" />
                    <path d="M28.5176 17.5284L28.5406 12.7285" stroke="black" />
                    <path d="M28.5176 46.5284L28.5406 41.7285" stroke="black" />
                    <path d="M13.25 33.0508H18.05" stroke="black" />
                    <path d="M36.5176 17.5675L36.5406 12.7676" stroke="black" />
                    <path d="M36.5176 46.5676L36.5406 41.7676" stroke="black" />
                    <path d="M7.65039 21.0508H18.6504" stroke="black" />
                    <path d="M45.6504 21.0508H51.6504" stroke="black" />
                    <path d="M24.5169 18.1628L24.0527 12.7012" stroke="black" />
                    <path d="M24.5146 52.1093L24.5674 41.1094" stroke="black" />
                    <path d="M12.6504 37.0508H18.6504" stroke="black" />
                    <path d="M40.5195 18.1854L40.5722 7.18555" stroke="black" />
                    <path d="M41.0533 46.7057L40.5508 41.2441" stroke="black" />
                    <path d="M51.2266 21.2266L59.2269 13.2266" stroke="black" />
                    <path d="M13.0742 37.0742L5.07422 45.0742" stroke="black" />
                    <path d="M24.2266 13.0742L16.2266 5.07422" stroke="black" />
                    <path
                        d="M60.15 14.3C61.3374 14.3 62.3 13.3374 62.3 12.15C62.3 10.9626 61.3374 10 60.15 10C58.9626 10 58 10.9626 58 12.15C58 13.3374 58.9626 14.3 60.15 14.3Z"
                        stroke="black"
                    />
                    <path
                        d="M24.15 56.3C25.3374 56.3 26.3 55.3374 26.3 54.15C26.3 52.9626 25.3374 52 24.15 52C22.9626 52 22 52.9626 22 54.15C22 55.3374 22.9626 56.3 24.15 56.3Z"
                        stroke="black"
                    />
                    <path
                        d="M3.15 49.3C4.33741 49.3 5.3 48.3374 5.3 47.15C5.3 45.9626 4.33741 45 3.15 45C1.96259 45 1 45.9626 1 47.15C1 48.3374 1.96259 49.3 3.15 49.3Z"
                        stroke="black"
                    />
                    <path
                        d="M5.15 23.3C6.33741 23.3 7.3 22.3374 7.3 21.15C7.3 19.9626 6.33741 19 5.15 19C3.96259 19 3 19.9626 3 21.15C3 22.3374 3.96259 23.3 5.15 23.3Z"
                        stroke="black"
                    />
                    <path
                        d="M14.15 5.3C15.3374 5.3 16.3 4.33741 16.3 3.15C16.3 1.96259 15.3374 1 14.15 1C12.9626 1 12 1.96259 12 3.15C12 4.33741 12.9626 5.3 14.15 5.3Z"
                        stroke="black"
                    />
                    <path
                        d="M40.15 7.3C41.3374 7.3 42.3 6.33741 42.3 5.15C42.3 3.96259 41.3374 3 40.15 3C38.9626 3 38 3.96259 38 5.15C38 6.33741 38.9626 7.3 40.15 7.3Z"
                        stroke="black"
                    />
                    <path
                        d="M32.8334 22.9683C32.4289 22.203 31.7621 21.6091 30.9553 21.2955C30.1485 20.9818 29.2557 20.9693 28.4404 21.2604C27.6252 21.5514 26.9421 22.1265 26.5163 22.8802C26.0906 23.6339 25.9507 24.5157 26.1222 25.3642"
                        stroke="black"
                    />
                    <path
                        d="M28.3825 25.1259C27.6304 24.9698 26.8481 25.0583 26.15 25.3786C25.4518 25.6988 24.8744 26.2339 24.502 26.9057C24.1297 27.5776 23.982 28.3509 24.0805 29.1126C24.1791 29.8744 24.5187 30.5847 25.0497 31.1396"
                        stroke="black"
                    />
                    <path
                        d="M26.5301 28.7422C25.8436 29.2427 25.3562 29.9698 25.1541 30.795C24.952 31.6202 25.0483 32.4903 25.4259 33.2514C25.8035 34.0124 26.4381 34.6154 27.2175 34.9537C27.9968 35.2919 28.8707 35.3436 29.6845 35.0997"
                        stroke="black"
                    />
                    <path
                        d="M28.0508 35.6743C28.0555 36.173 28.2034 36.6598 28.4771 37.0768C28.7507 37.4937 29.1385 37.8231 29.5942 38.0258C30.0499 38.2284 30.5543 38.2957 31.0472 38.2197C31.5401 38.1436 32.0007 37.9274 32.3741 37.5969C32.7475 37.2663 33.018 36.8353 33.1532 36.3553C33.2885 35.8753 33.2829 35.3665 33.137 34.8896C32.9911 34.4127 32.7111 33.9878 32.3305 33.6656C31.9498 33.3434 31.4845 33.1374 30.9901 33.0723"
                        stroke="black"
                    />
                    <path
                        d="M33.7236 34.7472C34.0291 35.1738 34.4242 35.5285 34.8811 35.7864C35.3381 36.0443 35.846 36.1992 36.3691 36.2402C36.8922 36.2812 37.418 36.2074 37.9096 36.0238C38.4012 35.8403 38.8467 35.5516 39.215 35.1778C39.5832 34.804 39.8653 34.3542 40.0415 33.8599C40.2177 33.3657 40.2837 32.8388 40.2349 32.3164C40.1861 31.7939 40.0237 31.2885 39.759 30.8354C39.4943 30.3823 39.1338 29.9925 38.7027 29.6934"
                        stroke="black"
                    />
                    <path
                        d="M40.0144 31.3646C40.5266 30.9185 40.9021 30.3367 41.0976 29.6862C41.2931 29.0358 41.3007 28.3433 41.1193 27.6887C40.938 27.0342 40.5752 26.4443 40.0728 25.9872C39.5705 25.5301 38.949 25.2244 38.2803 25.1055"
                        stroke="black"
                    />
                    <path
                        d="M38.1273 25.5831C38.3295 24.8288 38.281 24.0292 37.989 23.3049C37.6971 22.5806 37.1775 21.9709 36.5087 21.5677C35.8399 21.1645 35.0581 20.9897 34.2813 21.0697C33.5045 21.1497 32.7747 21.4802 32.2021 22.0112"
                        stroke="black"
                    />
                    <path d="M33.0508 36.05V28.25" stroke="black" />
                    <path
                        d="M33.4141 29.3986C33.8201 29.2278 34.2729 29.2034 34.6949 29.3296C35.1169 29.4557 35.482 29.7245 35.7277 30.0901C35.9734 30.4557 36.0845 30.8952 36.0419 31.3336C35.9993 31.7721 35.8058 32.182 35.4943 32.4935"
                        stroke="black"
                    />
                    <path
                        d="M30.5898 34C30.5898 30.6863 33.2761 28 36.5898 28H47.6107C50.9244 28 53.6107 30.6863 53.6107 34V40.8889C53.6107 44.2026 50.9244 46.8889 47.6107 46.8889H36.5898C33.2761 46.8889 30.5898 44.2026 30.5898 40.8889V34Z"
                        fill="white"
                    />
                    <path
                        d="M49.5562 28H34.4451C32.1746 28 30.334 29.8406 30.334 32.1111V42.7778C30.334 45.0483 32.1746 46.8889 34.4451 46.8889H49.5562C51.8267 46.8889 53.6673 45.0483 53.6673 42.7778V32.1111C53.6673 29.8406 51.8267 28 49.5562 28Z"
                        stroke="black"
                    />
                    <path d="M30 33.1094H54" stroke="black" />
                    <path
                        d="M33.334 31.5566C33.8863 31.5566 34.334 31.1089 34.334 30.5566C34.334 30.0044 33.8863 29.5566 33.334 29.5566C32.7817 29.5566 32.334 30.0044 32.334 30.5566C32.334 31.1089 32.7817 31.5566 33.334 31.5566Z"
                        stroke="black"
                    />
                    <path
                        d="M36.8887 31.5566C37.441 31.5566 37.8887 31.1089 37.8887 30.5566C37.8887 30.0044 37.441 29.5566 36.8887 29.5566C36.3364 29.5566 35.8887 30.0044 35.8887 30.5566C35.8887 31.1089 36.3364 31.5566 36.8887 31.5566Z"
                        stroke="black"
                    />
                    <path
                        d="M40.4443 31.5566C40.9966 31.5566 41.4443 31.1089 41.4443 30.5566C41.4443 30.0044 40.9966 29.5566 40.4443 29.5566C39.8921 29.5566 39.4443 30.0044 39.4443 30.5566C39.4443 31.1089 39.8921 31.5566 40.4443 31.5566Z"
                        stroke="black"
                    />
                    <path d="M39.9111 44.4349L43.4667 35.1016" stroke="black" />
                    <path d="M36.4785 38.8423L38.9927 36.3281" stroke="black" />
                    <path d="M47.1742 40.0156L44.627 42.4963" stroke="black" />
                    <path d="M36.4785 38.6348L38.9927 41.1489" stroke="black" />
                    <path d="M47.1711 40.2249L44.6904 37.6777" stroke="black" />
                </svg>
            )

        case 'System Design Interview (Low-Level)':
            return (
                <svg
                    width="63"
                    height="57"
                    viewBox="0 0 63 57"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M18.3506 18.3496H41.6506C44.0254 18.3496 45.9506 20.2748 45.9506 22.6496V40.9496H22.6506C20.2758 40.9496 18.3506 39.0244 18.3506 36.6496V18.3496Z"
                        stroke="#004084"
                    />
                    <path d="M32.5225 17.5478L32.5839 4.74805" stroke="#004084" />
                    <path d="M5.25 29.0508H18.05" stroke="#004084" />
                    <path d="M13.25 25.0508H18.05" stroke="#004084" />
                    <path d="M46.25 25.0508H51.05" stroke="#004084" />
                    <path d="M28.5176 17.5284L28.5406 12.7285" stroke="#004084" />
                    <path d="M28.5176 46.5284L28.5406 41.7285" stroke="#004084" />
                    <path d="M13.25 33.0508H18.05" stroke="#004084" />
                    <path d="M36.5176 17.5675L36.5406 12.7676" stroke="#004084" />
                    <path d="M36.5176 46.5676L36.5406 41.7676" stroke="#004084" />
                    <path d="M7.65039 21.0508H18.6504" stroke="#004084" />
                    <path d="M45.6504 21.0508H51.6504" stroke="#004084" />
                    <path d="M24.5169 18.1628L24.0527 12.7012" stroke="#004084" />
                    <path d="M24.5146 52.1093L24.5674 41.1094" stroke="#004084" />
                    <path d="M12.6504 37.0508H18.6504" stroke="#004084" />
                    <path d="M40.5195 18.1854L40.5722 7.18555" stroke="#004084" />
                    <path d="M41.0533 46.7057L40.5508 41.2441" stroke="#004084" />
                    <path d="M51.2266 21.2266L59.2269 13.2266" stroke="#004084" />
                    <path d="M13.0742 37.0742L5.07422 45.0742" stroke="#004084" />
                    <path d="M24.2266 13.0742L16.2266 5.07422" stroke="#004084" />
                    <path
                        d="M60.15 14.3C61.3374 14.3 62.3 13.3374 62.3 12.15C62.3 10.9626 61.3374 10 60.15 10C58.9626 10 58 10.9626 58 12.15C58 13.3374 58.9626 14.3 60.15 14.3Z"
                        stroke="#004084"
                    />
                    <path
                        d="M24.15 56.3C25.3374 56.3 26.3 55.3374 26.3 54.15C26.3 52.9626 25.3374 52 24.15 52C22.9626 52 22 52.9626 22 54.15C22 55.3374 22.9626 56.3 24.15 56.3Z"
                        stroke="#004084"
                    />
                    <path
                        d="M3.15 49.3C4.33741 49.3 5.3 48.3374 5.3 47.15C5.3 45.9626 4.33741 45 3.15 45C1.96259 45 1 45.9626 1 47.15C1 48.3374 1.96259 49.3 3.15 49.3Z"
                        stroke="#004084"
                    />
                    <path
                        d="M5.15 23.3C6.33741 23.3 7.3 22.3374 7.3 21.15C7.3 19.9626 6.33741 19 5.15 19C3.96259 19 3 19.9626 3 21.15C3 22.3374 3.96259 23.3 5.15 23.3Z"
                        stroke="#004084"
                    />
                    <path
                        d="M14.15 5.3C15.3374 5.3 16.3 4.33741 16.3 3.15C16.3 1.96259 15.3374 1 14.15 1C12.9626 1 12 1.96259 12 3.15C12 4.33741 12.9626 5.3 14.15 5.3Z"
                        stroke="#004084"
                    />
                    <path
                        d="M40.15 7.3C41.3374 7.3 42.3 6.33741 42.3 5.15C42.3 3.96259 41.3374 3 40.15 3C38.9626 3 38 3.96259 38 5.15C38 6.33741 38.9626 7.3 40.15 7.3Z"
                        stroke="#004084"
                    />
                    <path
                        d="M32.8334 22.9683C32.4289 22.203 31.7621 21.6091 30.9553 21.2955C30.1485 20.9818 29.2557 20.9693 28.4404 21.2604C27.6252 21.5514 26.9421 22.1265 26.5163 22.8802C26.0906 23.6339 25.9507 24.5157 26.1222 25.3642"
                        stroke="#004084"
                    />
                    <path
                        d="M28.3825 25.1259C27.6304 24.9698 26.8481 25.0583 26.15 25.3786C25.4518 25.6988 24.8744 26.2339 24.502 26.9057C24.1297 27.5776 23.982 28.3509 24.0805 29.1126C24.1791 29.8744 24.5187 30.5847 25.0497 31.1396"
                        stroke="#004084"
                    />
                    <path
                        d="M26.5301 28.7422C25.8436 29.2427 25.3562 29.9698 25.1541 30.795C24.952 31.6202 25.0483 32.4903 25.4259 33.2514C25.8035 34.0124 26.4381 34.6154 27.2175 34.9537C27.9968 35.2919 28.8707 35.3436 29.6845 35.0997"
                        stroke="#004084"
                    />
                    <path
                        d="M28.0508 35.6743C28.0555 36.173 28.2034 36.6598 28.4771 37.0768C28.7507 37.4937 29.1385 37.8231 29.5942 38.0258C30.0499 38.2284 30.5543 38.2957 31.0472 38.2197C31.5401 38.1436 32.0007 37.9274 32.3741 37.5969C32.7475 37.2663 33.018 36.8353 33.1532 36.3553C33.2885 35.8753 33.2829 35.3665 33.137 34.8896C32.9911 34.4127 32.7111 33.9878 32.3305 33.6656C31.9498 33.3434 31.4845 33.1374 30.9901 33.0723"
                        stroke="#004084"
                    />
                    <path
                        d="M33.7236 34.7472C34.0291 35.1738 34.4242 35.5285 34.8811 35.7864C35.3381 36.0443 35.846 36.1992 36.3691 36.2402C36.8922 36.2812 37.418 36.2074 37.9096 36.0238C38.4012 35.8403 38.8467 35.5516 39.215 35.1778C39.5832 34.804 39.8653 34.3542 40.0415 33.8599C40.2177 33.3657 40.2837 32.8388 40.2349 32.3164C40.1861 31.7939 40.0237 31.2885 39.759 30.8354C39.4943 30.3823 39.1338 29.9925 38.7027 29.6934"
                        stroke="#004084"
                    />
                    <path
                        d="M40.0144 31.3646C40.5266 30.9185 40.9021 30.3367 41.0976 29.6862C41.2931 29.0358 41.3007 28.3433 41.1193 27.6887C40.938 27.0342 40.5752 26.4443 40.0728 25.9872C39.5705 25.5301 38.949 25.2244 38.2803 25.1055"
                        stroke="#004084"
                    />
                    <path
                        d="M38.1273 25.5831C38.3295 24.8288 38.281 24.0292 37.989 23.3049C37.6971 22.5806 37.1775 21.9709 36.5087 21.5677C35.8399 21.1645 35.0581 20.9897 34.2813 21.0697C33.5045 21.1497 32.7747 21.4802 32.2021 22.0112"
                        stroke="#004084"
                    />
                    <path d="M33.0508 36.05V28.25" stroke="#004084" />
                    <path
                        d="M33.4141 29.3986C33.8201 29.2278 34.2729 29.2034 34.6949 29.3296C35.1169 29.4557 35.482 29.7245 35.7277 30.0901C35.9734 30.4557 36.0845 30.8952 36.0419 31.3336C35.9993 31.7721 35.8058 32.182 35.4943 32.4935"
                        stroke="#004084"
                    />
                    <path
                        d="M30.5898 34C30.5898 30.6863 33.2761 28 36.5898 28H47.6107C50.9244 28 53.6107 30.6863 53.6107 34V40.8889C53.6107 44.2026 50.9244 46.8889 47.6107 46.8889H36.5898C33.2761 46.8889 30.5898 44.2026 30.5898 40.8889V34Z"
                        fill="white"
                    />
                    <path
                        d="M49.5562 28H34.4451C32.1746 28 30.334 29.8406 30.334 32.1111V42.7778C30.334 45.0483 32.1746 46.8889 34.4451 46.8889H49.5562C51.8267 46.8889 53.6673 45.0483 53.6673 42.7778V32.1111C53.6673 29.8406 51.8267 28 49.5562 28Z"
                        stroke="#004084"
                    />
                    <path d="M30 33.1094H54" stroke="#004084" />
                    <path
                        d="M33.334 31.5566C33.8863 31.5566 34.334 31.1089 34.334 30.5566C34.334 30.0044 33.8863 29.5566 33.334 29.5566C32.7817 29.5566 32.334 30.0044 32.334 30.5566C32.334 31.1089 32.7817 31.5566 33.334 31.5566Z"
                        stroke="#004084"
                    />
                    <path
                        d="M36.8887 31.5566C37.441 31.5566 37.8887 31.1089 37.8887 30.5566C37.8887 30.0044 37.441 29.5566 36.8887 29.5566C36.3364 29.5566 35.8887 30.0044 35.8887 30.5566C35.8887 31.1089 36.3364 31.5566 36.8887 31.5566Z"
                        stroke="#004084"
                    />
                    <path
                        d="M40.4443 31.5566C40.9966 31.5566 41.4443 31.1089 41.4443 30.5566C41.4443 30.0044 40.9966 29.5566 40.4443 29.5566C39.8921 29.5566 39.4443 30.0044 39.4443 30.5566C39.4443 31.1089 39.8921 31.5566 40.4443 31.5566Z"
                        stroke="#004084"
                    />
                    <path d="M39.9111 44.4349L43.4667 35.1016" stroke="#004084" />
                    <path d="M36.4785 38.8423L38.9927 36.3281" stroke="#004084" />
                    <path d="M47.1742 40.0156L44.627 42.4963" stroke="#004084" />
                    <path d="M36.4785 38.6348L38.9927 41.1489" stroke="#004084" />
                    <path d="M47.1711 40.2249L44.6904 37.6777" stroke="#004084" />
                </svg>
            )

        case 'Front-end Interview_grey':
            return (
                <svg
                    width="54"
                    height="44"
                    viewBox="0 0 54 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M49 1H5C2.65279 1 0.75 2.90279 0.75 5.25V39.25C0.75 41.5972 2.65279 43.5 5 43.5H49C51.3472 43.5 53.25 41.5972 53.25 39.25V5.25C53.25 2.90279 51.3472 1 49 1Z"
                        stroke="black"
                    />
                    <path d="M0 12.5H54" stroke="black" />
                    <path
                        d="M7.5 9C8.74264 9 9.75 7.99264 9.75 6.75C9.75 5.50736 8.74264 4.5 7.5 4.5C6.25736 4.5 5.25 5.50736 5.25 6.75C5.25 7.99264 6.25736 9 7.5 9Z"
                        stroke="black"
                    />
                    <path
                        d="M15.5 9C16.7426 9 17.75 7.99264 17.75 6.75C17.75 5.50736 16.7426 4.5 15.5 4.5C14.2574 4.5 13.25 5.50736 13.25 6.75C13.25 7.99264 14.2574 9 15.5 9Z"
                        stroke="black"
                    />
                    <path
                        d="M23.5 9C24.7426 9 25.75 7.99264 25.75 6.75C25.75 5.50736 24.7426 4.5 23.5 4.5C22.2574 4.5 21.25 5.50736 21.25 6.75C21.25 7.99264 22.2574 9 23.5 9Z"
                        stroke="black"
                    />
                    <path
                        d="M25 18H8C7.30964 18 6.75 18.5596 6.75 19.25V36.25C6.75 36.9404 7.30964 37.5 8 37.5H25C25.6904 37.5 26.25 36.9404 26.25 36.25V19.25C26.25 18.5596 25.6904 18 25 18Z"
                        stroke="black"
                    />
                    <path
                        d="M21.125 28.4669L13.625 32.797C13.4583 32.8933 13.25 32.773 13.25 32.5805V23.9203C13.25 23.7278 13.4583 23.6075 13.625 23.7038L21.125 28.0339C21.2917 28.1301 21.2917 28.3707 21.125 28.4669Z"
                        stroke="black"
                    />
                    <path
                        d="M39 18H32C31.3096 18 30.75 18.5596 30.75 19.25V25.25C30.75 25.9404 31.3096 26.5 32 26.5H39C39.6904 26.5 40.25 25.9404 40.25 25.25V19.25C40.25 18.5596 39.6904 18 39 18Z"
                        stroke="black"
                    />
                    <path
                        d="M46 29H32C31.3096 29 30.75 29.5596 30.75 30.25V35.25C30.75 35.9404 31.3096 36.5 32 36.5H46C46.6904 36.5 47.25 35.9404 47.25 35.25V30.25C47.25 29.5596 46.6904 29 46 29Z"
                        stroke="black"
                    />
                    <path d="M43.75 18.5H48.25" stroke="black" />
                    <path d="M43.75 21.5H49.25" stroke="black" />
                    <path d="M43.75 24.5H46.25" stroke="black" />
                </svg>
            )

        case 'Front-end Interview':
            return (
                <svg
                    width="54"
                    height="44"
                    viewBox="0 0 54 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M49 1H5C2.65279 1 0.75 2.90279 0.75 5.25V39.25C0.75 41.5972 2.65279 43.5 5 43.5H49C51.3472 43.5 53.25 41.5972 53.25 39.25V5.25C53.25 2.90279 51.3472 1 49 1Z"
                        stroke="#004084"
                    />
                    <path d="M0 12.5H54" stroke="#004084" />
                    <path
                        d="M7.5 9C8.74264 9 9.75 7.99264 9.75 6.75C9.75 5.50736 8.74264 4.5 7.5 4.5C6.25736 4.5 5.25 5.50736 5.25 6.75C5.25 7.99264 6.25736 9 7.5 9Z"
                        stroke="#004084"
                    />
                    <path
                        d="M15.5 9C16.7426 9 17.75 7.99264 17.75 6.75C17.75 5.50736 16.7426 4.5 15.5 4.5C14.2574 4.5 13.25 5.50736 13.25 6.75C13.25 7.99264 14.2574 9 15.5 9Z"
                        stroke="#004084"
                    />
                    <path
                        d="M23.5 9C24.7426 9 25.75 7.99264 25.75 6.75C25.75 5.50736 24.7426 4.5 23.5 4.5C22.2574 4.5 21.25 5.50736 21.25 6.75C21.25 7.99264 22.2574 9 23.5 9Z"
                        stroke="#004084"
                    />
                    <path
                        d="M25 18H8C7.30964 18 6.75 18.5596 6.75 19.25V36.25C6.75 36.9404 7.30964 37.5 8 37.5H25C25.6904 37.5 26.25 36.9404 26.25 36.25V19.25C26.25 18.5596 25.6904 18 25 18Z"
                        stroke="#004084"
                    />
                    <path
                        d="M21.125 28.4669L13.625 32.797C13.4583 32.8933 13.25 32.773 13.25 32.5805V23.9203C13.25 23.7278 13.4583 23.6075 13.625 23.7038L21.125 28.0339C21.2917 28.1301 21.2917 28.3707 21.125 28.4669Z"
                        stroke="#004084"
                    />
                    <path
                        d="M39 18H32C31.3096 18 30.75 18.5596 30.75 19.25V25.25C30.75 25.9404 31.3096 26.5 32 26.5H39C39.6904 26.5 40.25 25.9404 40.25 25.25V19.25C40.25 18.5596 39.6904 18 39 18Z"
                        stroke="#004084"
                    />
                    <path
                        d="M46 29H32C31.3096 29 30.75 29.5596 30.75 30.25V35.25C30.75 35.9404 31.3096 36.5 32 36.5H46C46.6904 36.5 47.25 35.9404 47.25 35.25V30.25C47.25 29.5596 46.6904 29 46 29Z"
                        stroke="#004084"
                    />
                    <path d="M43.75 18.5H48.25" stroke="#004084" />
                    <path d="M43.75 21.5H49.25" stroke="#004084" />
                    <path d="M43.75 24.5H46.25" stroke="#004084" />
                </svg>
            )

        case 'Behavioral Interview_grey':
            return (
                <svg
                    width="53"
                    height="53"
                    viewBox="0 0 53 53"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M39.75 26.5C46.7916 26.5 52.5 20.7916 52.5 13.75C52.5 6.70837 46.7916 1 39.75 1C32.7084 1 27 6.70837 27 13.75C27 20.7916 32.7084 26.5 39.75 26.5Z"
                        stroke="black"
                    />
                    <path
                        d="M52.5 40.25C52.5 46.9891 46.8186 52.5 39.75 52.5C32.6814 52.5 27 46.9891 27 40.25C27 33.5109 32.6814 28 39.75 28C46.8186 28 52.5 33.5109 52.5 40.25Z"
                        stroke="black"
                    />
                    <path
                        d="M25.5 13.75C25.5 20.8186 19.9891 26.5 13.25 26.5C6.5109 26.5 1 20.8186 1 13.75C1 6.6814 6.5109 1 13.25 1C19.9891 1 25.5 6.6814 25.5 13.75Z"
                        stroke="black"
                    />
                    <path
                        d="M13.25 52.5C20.0155 52.5 25.5 47.0155 25.5 40.25C25.5 33.4845 20.0155 28 13.25 28C6.48451 28 1 33.4845 1 40.25C1 47.0155 6.48451 52.5 13.25 52.5Z"
                        stroke="black"
                    />
                    <path d="M8 9V11.5" stroke="black" />
                    <path d="M8 36V37.5" stroke="black" />
                    <path d="M19 36V37.5" stroke="black" />
                    <path d="M35 36V37.5" stroke="black" />
                    <path d="M46 36V37.5" stroke="black" />
                    <path d="M19 9V11.5" stroke="black" />
                    <path d="M35 9V11.5" stroke="black" />
                    <path d="M46 9V11.5" stroke="black" />
                    <mask
                        id="mask0_261_501"
                        maskUnits="userSpaceOnUse"
                        x="7"
                        y="15"
                        width="13"
                        height="7"
                    >
                        <path
                            d="M19.25 15.75C19.25 16.4723 19.0948 17.1875 18.7933 17.8548C18.4917 18.522 18.0498 19.1284 17.4926 19.6391C16.9355 20.1498 16.2741 20.5549 15.5461 20.8313C14.8181 21.1077 14.0379 21.25 13.25 21.25C12.4621 21.25 11.6819 21.1077 10.9539 20.8313C10.2259 20.5549 9.5645 20.1498 9.0074 19.6391C8.4502 19.1284 8.0083 18.522 7.7067 17.8548C7.4052 17.1875 7.25 16.4723 7.25 15.75H13.25H19.25Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask0_261_501)">
                        <path
                            d="M19.25 15.75C19.25 16.4723 19.0948 17.1875 18.7933 17.8548C18.4917 18.522 18.0498 19.1284 17.4926 19.6391C16.9355 20.1498 16.2741 20.5549 15.5461 20.8313C14.8181 21.1077 14.0379 21.25 13.25 21.25C12.4621 21.25 11.6819 21.1077 10.9539 20.8313C10.2259 20.5549 9.5645 20.1498 9.0074 19.6391C8.4502 19.1284 8.0083 18.522 7.7067 17.8548C7.4052 17.1875 7.25 16.4723 7.25 15.75H13.25H19.25Z"
                            stroke="black"
                        />
                    </g>
                    <mask
                        id="mask1_261_501"
                        maskUnits="userSpaceOnUse"
                        x="34"
                        y="15"
                        width="13"
                        height="7"
                    >
                        <path
                            d="M46.25 21.25C46.25 19.6587 45.6179 18.1326 44.4926 17.0074C43.3674 15.8821 41.8413 15.25 40.25 15.25C38.6587 15.25 37.1326 15.8821 36.0074 17.0074C34.8821 18.1326 34.25 19.6587 34.25 21.25H40.25H46.25Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask1_261_501)">
                        <path
                            d="M46.25 21.25C46.25 19.6587 45.6179 18.1326 44.4926 17.0074C43.3674 15.8821 41.8413 15.25 40.25 15.25C38.6587 15.25 37.1326 15.8821 36.0074 17.0074C34.8821 18.1326 34.25 19.6587 34.25 21.25H40.25H46.25Z"
                            stroke="black"
                        />
                    </g>
                    <mask
                        id="mask2_261_501"
                        maskUnits="userSpaceOnUse"
                        x="36"
                        y="41"
                        width="8"
                        height="3"
                    >
                        <path
                            d="M43.6095 42.2789C42.6355 41.6206 41.4895 41.2629 40.3139 41.2503C39.1383 41.2378 37.985 41.5708 36.9971 42.2081L37.8887 43.5902C38.6058 43.1276 39.443 42.8858 40.2963 42.8949C41.1496 42.9041 41.9815 43.1637 42.6885 43.6416L43.6095 42.2789Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask2_261_501)">
                        <path
                            d="M43.6095 42.2789C42.6355 41.6206 41.4895 41.2629 40.3139 41.2503C39.1383 41.2378 37.985 41.5708 36.9971 42.2081L37.8887 43.5902C38.6058 43.1276 39.443 42.8858 40.2963 42.8949C41.1496 42.9041 41.9815 43.1637 42.6885 43.6416L43.6095 42.2789Z"
                            stroke="black"
                        />
                    </g>
                    <mask
                        id="mask3_261_501"
                        maskUnits="userSpaceOnUse"
                        x="10"
                        y="18"
                        width="7"
                        height="3"
                    >
                        <path
                            d="M16.7773 19.7439C16.4641 19.2815 16.0124 18.8992 15.4689 18.6366C14.9255 18.374 14.31 18.2407 13.6863 18.2505C13.0625 18.2603 12.4532 18.4128 11.9213 18.6922C11.3894 18.9717 10.9543 19.3679 10.6611 19.8399L11.792 20.3561C11.9779 20.0569 12.2537 19.8057 12.5908 19.6286C12.928 19.4514 13.3143 19.3548 13.7097 19.3486C14.1051 19.3424 14.4953 19.4269 14.8398 19.5933C15.1843 19.7598 15.4706 20.0021 15.6692 20.2953L16.7773 19.7439Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask3_261_501)">
                        <path
                            d="M16.7773 19.7439C16.4641 19.2815 16.0124 18.8992 15.4689 18.6366C14.9255 18.374 14.31 18.2407 13.6863 18.2505C13.0625 18.2603 12.4532 18.4128 11.9213 18.6922C11.3894 18.9717 10.9543 19.3679 10.6611 19.8399L11.792 20.3561C11.9779 20.0569 12.2537 19.8057 12.5908 19.6286C12.928 19.4514 13.3143 19.3548 13.7097 19.3486C14.1051 19.3424 14.4953 19.4269 14.8398 19.5933C15.1843 19.7598 15.4706 20.0021 15.6692 20.2953L16.7773 19.7439Z"
                            stroke="black"
                        />
                    </g>
                    <mask
                        id="mask4_261_501"
                        maskUnits="userSpaceOnUse"
                        x="37"
                        y="16"
                        width="7"
                        height="3"
                    >
                        <path
                            d="M37.7227 16.7565C38.0282 17.2076 38.4656 17.5826 38.9918 17.8446C39.518 18.1065 40.1148 18.2464 40.7233 18.2503C41.3318 18.2543 41.9309 18.1221 42.4617 17.867C42.9924 17.6119 43.4364 17.2426 43.7498 16.7955L42.6515 16.2299C42.4529 16.5133 42.1714 16.7474 41.835 16.9091C41.4985 17.0709 41.1187 17.1546 40.7329 17.1521C40.3472 17.1496 39.9689 17.061 39.6353 16.8949C39.3018 16.7288 39.0245 16.4911 38.8308 16.2051L37.7227 16.7565Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask4_261_501)">
                        <path
                            d="M37.7227 16.7565C38.0282 17.2076 38.4656 17.5826 38.9918 17.8446C39.518 18.1065 40.1148 18.2464 40.7233 18.2503C41.3318 18.2543 41.9309 18.1221 42.4617 17.867C42.9924 17.6119 43.4364 17.2426 43.7498 16.7955L42.6515 16.2299C42.4529 16.5133 42.1714 16.7474 41.835 16.9091C41.4985 17.0709 41.1187 17.1546 40.7329 17.1521C40.3472 17.1496 39.9689 17.061 39.6353 16.8949C39.3018 16.7288 39.0245 16.4911 38.8308 16.2051L37.7227 16.7565Z"
                            stroke="black"
                        />
                    </g>
                </svg>
            )

        case 'Behavioral Interview':
            return (
                <svg
                    width="53"
                    height="53"
                    viewBox="0 0 53 53"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M39.75 26.5C46.7916 26.5 52.5 20.7916 52.5 13.75C52.5 6.70837 46.7916 1 39.75 1C32.7084 1 27 6.70837 27 13.75C27 20.7916 32.7084 26.5 39.75 26.5Z"
                        stroke="#004084"
                    />
                    <path
                        d="M52.5 40.25C52.5 46.9891 46.8186 52.5 39.75 52.5C32.6814 52.5 27 46.9891 27 40.25C27 33.5109 32.6814 28 39.75 28C46.8186 28 52.5 33.5109 52.5 40.25Z"
                        stroke="#004084"
                    />
                    <path
                        d="M25.5 13.75C25.5 20.8186 19.9891 26.5 13.25 26.5C6.5109 26.5 1 20.8186 1 13.75C1 6.6814 6.5109 1 13.25 1C19.9891 1 25.5 6.6814 25.5 13.75Z"
                        stroke="#004084"
                    />
                    <path
                        d="M13.25 52.5C20.0155 52.5 25.5 47.0155 25.5 40.25C25.5 33.4845 20.0155 28 13.25 28C6.48451 28 1 33.4845 1 40.25C1 47.0155 6.48451 52.5 13.25 52.5Z"
                        stroke="#004084"
                    />
                    <path d="M8 9V11.5" stroke="#004084" />
                    <path d="M8 36V37.5" stroke="#004084" />
                    <path d="M19 36V37.5" stroke="#004084" />
                    <path d="M35 36V37.5" stroke="#004084" />
                    <path d="M46 36V37.5" stroke="#004084" />
                    <path d="M19 9V11.5" stroke="#004084" />
                    <path d="M35 9V11.5" stroke="#004084" />
                    <path d="M46 9V11.5" stroke="#004084" />
                    <mask
                        id="mask0_261_500"
                        maskUnits="userSpaceOnUse"
                        x="7"
                        y="15"
                        width="13"
                        height="7"
                    >
                        <path
                            d="M19.25 15.75C19.25 16.4723 19.0948 17.1875 18.7933 17.8548C18.4917 18.522 18.0498 19.1284 17.4926 19.6391C16.9355 20.1498 16.2741 20.5549 15.5461 20.8313C14.8181 21.1077 14.0379 21.25 13.25 21.25C12.4621 21.25 11.6819 21.1077 10.9539 20.8313C10.2259 20.5549 9.5645 20.1498 9.0074 19.6391C8.4502 19.1284 8.0083 18.522 7.7067 17.8548C7.4052 17.1875 7.25 16.4723 7.25 15.75H13.25H19.25Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask0_261_500)">
                        <path
                            d="M19.25 15.75C19.25 16.4723 19.0948 17.1875 18.7933 17.8548C18.4917 18.522 18.0498 19.1284 17.4926 19.6391C16.9355 20.1498 16.2741 20.5549 15.5461 20.8313C14.8181 21.1077 14.0379 21.25 13.25 21.25C12.4621 21.25 11.6819 21.1077 10.9539 20.8313C10.2259 20.5549 9.5645 20.1498 9.0074 19.6391C8.4502 19.1284 8.0083 18.522 7.7067 17.8548C7.4052 17.1875 7.25 16.4723 7.25 15.75H13.25H19.25Z"
                            stroke="#004084"
                        />
                    </g>
                    <mask
                        id="mask1_261_500"
                        maskUnits="userSpaceOnUse"
                        x="34"
                        y="15"
                        width="13"
                        height="7"
                    >
                        <path
                            d="M46.25 21.25C46.25 19.6587 45.6179 18.1326 44.4926 17.0074C43.3674 15.8821 41.8413 15.25 40.25 15.25C38.6587 15.25 37.1326 15.8821 36.0074 17.0074C34.8821 18.1326 34.25 19.6587 34.25 21.25H40.25H46.25Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask1_261_500)">
                        <path
                            d="M46.25 21.25C46.25 19.6587 45.6179 18.1326 44.4926 17.0074C43.3674 15.8821 41.8413 15.25 40.25 15.25C38.6587 15.25 37.1326 15.8821 36.0074 17.0074C34.8821 18.1326 34.25 19.6587 34.25 21.25H40.25H46.25Z"
                            stroke="#004084"
                        />
                    </g>
                    <mask
                        id="mask2_261_500"
                        maskUnits="userSpaceOnUse"
                        x="36"
                        y="41"
                        width="8"
                        height="3"
                    >
                        <path
                            d="M43.6095 42.2789C42.6355 41.6206 41.4895 41.2629 40.3139 41.2503C39.1383 41.2378 37.985 41.5708 36.9971 42.2081L37.8887 43.5902C38.6058 43.1276 39.443 42.8858 40.2963 42.8949C41.1496 42.9041 41.9815 43.1637 42.6885 43.6416L43.6095 42.2789Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask2_261_500)">
                        <path
                            d="M43.6095 42.2789C42.6355 41.6206 41.4895 41.2629 40.3139 41.2503C39.1383 41.2378 37.985 41.5708 36.9971 42.2081L37.8887 43.5902C38.6058 43.1276 39.443 42.8858 40.2963 42.8949C41.1496 42.9041 41.9815 43.1637 42.6885 43.6416L43.6095 42.2789Z"
                            stroke="#004084"
                        />
                    </g>
                    <mask
                        id="mask3_261_500"
                        maskUnits="userSpaceOnUse"
                        x="10"
                        y="18"
                        width="7"
                        height="3"
                    >
                        <path
                            d="M16.7773 19.7439C16.4641 19.2815 16.0124 18.8992 15.4689 18.6366C14.9255 18.374 14.31 18.2407 13.6863 18.2505C13.0625 18.2603 12.4532 18.4128 11.9213 18.6922C11.3894 18.9717 10.9543 19.3679 10.6611 19.8399L11.792 20.3561C11.9779 20.0569 12.2537 19.8057 12.5908 19.6286C12.928 19.4514 13.3143 19.3548 13.7097 19.3486C14.1051 19.3424 14.4953 19.4269 14.8398 19.5933C15.1843 19.7598 15.4706 20.0021 15.6692 20.2953L16.7773 19.7439Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask3_261_500)">
                        <path
                            d="M16.7773 19.7439C16.4641 19.2815 16.0124 18.8992 15.4689 18.6366C14.9255 18.374 14.31 18.2407 13.6863 18.2505C13.0625 18.2603 12.4532 18.4128 11.9213 18.6922C11.3894 18.9717 10.9543 19.3679 10.6611 19.8399L11.792 20.3561C11.9779 20.0569 12.2537 19.8057 12.5908 19.6286C12.928 19.4514 13.3143 19.3548 13.7097 19.3486C14.1051 19.3424 14.4953 19.4269 14.8398 19.5933C15.1843 19.7598 15.4706 20.0021 15.6692 20.2953L16.7773 19.7439Z"
                            stroke="#004084"
                        />
                    </g>
                    <mask
                        id="mask4_261_500"
                        maskUnits="userSpaceOnUse"
                        x="37"
                        y="16"
                        width="7"
                        height="3"
                    >
                        <path
                            d="M37.7227 16.7565C38.0282 17.2076 38.4656 17.5826 38.9918 17.8446C39.518 18.1065 40.1148 18.2464 40.7233 18.2503C41.3318 18.2543 41.9309 18.1221 42.4617 17.867C42.9924 17.6119 43.4364 17.2426 43.7498 16.7955L42.6515 16.2299C42.4529 16.5133 42.1714 16.7474 41.835 16.9091C41.4985 17.0709 41.1187 17.1546 40.7329 17.1521C40.3472 17.1496 39.9689 17.061 39.6353 16.8949C39.3018 16.7288 39.0245 16.4911 38.8308 16.2051L37.7227 16.7565Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask4_261_500)">
                        <path
                            d="M37.7227 16.7565C38.0282 17.2076 38.4656 17.5826 38.9918 17.8446C39.518 18.1065 40.1148 18.2464 40.7233 18.2503C41.3318 18.2543 41.9309 18.1221 42.4617 17.867C42.9924 17.6119 43.4364 17.2426 43.7498 16.7955L42.6515 16.2299C42.4529 16.5133 42.1714 16.7474 41.835 16.9091C41.4985 17.0709 41.1187 17.1546 40.7329 17.1521C40.3472 17.1496 39.9689 17.061 39.6353 16.8949C39.3018 16.7288 39.0245 16.4911 38.8308 16.2051L37.7227 16.7565Z"
                            stroke="#004084"
                        />
                    </g>
                </svg>
            )

        case 'INTERVIEWREADY_WHITE':
            return (
                <svg viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.98403 20.9161H3.67679C1.92879 20.9161 0.505981 19.4941 0.505981 17.7453V10.1584C0.505981 8.77853 1.62471 7.6598 3.00457 7.6598C4.38442 7.6598 5.50315 8.77853 5.50315 10.1584V15.9193H7.98403C9.36389 15.9193 10.4826 17.0381 10.4826 18.4179C10.4826 19.7974 9.36389 20.9161 7.98403 20.9161Z"
                        fill="white"
                    />
                    <path
                        d="M22.8067 20.9161H18.4994C17.1196 20.9161 16.0009 19.7974 16.0009 18.4179C16.0009 17.0381 17.1196 15.9193 18.4994 15.9193H20.9807V10.1584C20.9807 8.77853 22.0994 7.6598 23.4789 7.6598C24.8588 7.6598 25.9775 8.77853 25.9775 10.1584V17.7453C25.9775 19.4941 24.5551 20.9161 22.8067 20.9161Z"
                        fill="white"
                    />
                    <path
                        d="M20.958 3.34063C20.958 4.73292 22.0865 5.86145 23.4788 5.86145C24.8711 5.86145 26 4.73292 26 3.34063C26 1.94834 24.8711 0.819815 23.4788 0.819815C22.0865 0.819815 20.958 1.94834 20.958 3.34063Z"
                        fill="white"
                    />
                    <path
                        d="M0.506104 3.34063C0.506104 4.73292 1.63501 5.86145 3.02692 5.86145C4.41959 5.86145 5.54811 4.73292 5.54811 3.34063C5.54811 1.94834 4.41959 0.819815 3.02692 0.819815C1.63501 0.819815 0.506104 1.94834 0.506104 3.34063Z"
                        fill="white"
                    />
                    <path
                        d="M6.46941 4.95566L9.9556 11.1631C10.0261 11.2897 10.199 11.3108 10.2989 11.2057L19.774 1.19101C19.9522 1.00223 19.705 0.71737 19.4929 0.868091L10.739 7.0944C10.667 7.14564 10.5709 7.14753 10.4967 7.0993L6.77463 4.67042C6.58622 4.54758 6.35939 4.75972 6.46941 4.95566Z"
                        fill="white"
                    />
                    <path
                        d="M19.4737 12.5501V14.4115H18.4978C16.2901 14.4115 14.4924 16.2089 14.4924 18.417C14.4924 19.359 14.8202 20.2294 15.3703 20.9152H11.1128C11.6629 20.2294 11.9908 19.359 11.9908 18.417C11.9908 16.2089 10.193 14.4115 7.98533 14.4115H7.00903V12.5501H19.4737Z"
                        fill="white"
                    />
                </svg>
            )

        case 'GRAMOPHONE':
            return (
                <svg
                    width="26"
                    height="21"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#fff"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                    />
                </svg>
            )

        default:
            console.log('Invalid icon name!')
    }
}

export default getIcon

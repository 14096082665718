import { useState, useEffect, useCallback, useContext } from 'react'
import { Typography } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'

import Navbar from '../Landing/Navbar/Navbar'
import ButtonComp from '../../components/utils/ButtonComp/ButtonComp'
import toastNotification from '../../components/utils/Notification/Notification'
import FeedbackFormItem from './FeedbackFormItem'
import ReportForm from '../Meeting/ReportForm/ReportForm'
import {
    getFeedbackParams,
    getFeedbackCommentInitial,
    getFeedbackRatingInitial,
} from '../../utils/getFeedbackParams'
import * as actions from '../../store/actions/feedback'
import { setFeedbackSubmitted } from '../../store/actions/platformRating'
import { MeetContext } from '../Meeting/MeetContext'
import {
    FEEDBACK_COMMENT_MIN_LENGTH,
    FEEDBACK_COMMENT_MAX_LENGTH,
} from '../../constants/vars'
import ConfirmModal from '../utils/ConfirmModal/ConfirmModal'
import './FeedbackForm.css'

const FeedbackForm = ({ history }) => {
    const dispatch = useDispatch()
    const { meetInfo } = useContext(MeetContext)

    const {
        feedbackComments,
        success: successFeedbackSave,
        error: errorFeedbackSave,
    } = useSelector((state) => state.feedback)

    const [textInputVals, setTextInputVals] = useState(feedbackComments)
    const [ratingVals, setRatingVals] = useState(getFeedbackRatingInitial(meetInfo.type))
    const [showErrors, setShowErrors] = useState(false)
    const [isResetConfirmOpen, setIsResetConfirmOpen] = useState(false)

    const handleOnTextChange = (e) => {
        setTextInputVals((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }))
    }

    const handleOnRatingChange = (e, newVal) => {
        setRatingVals((prev) => ({ ...prev, [e.target.name]: newVal }))
    }

    const handleFormSubmit = () => {
        let isError = false

        Object.keys(textInputVals).forEach((inputKey) => {
            if (
                textInputVals[inputKey].trim().length < FEEDBACK_COMMENT_MIN_LENGTH ||
                textInputVals[inputKey].trim().length > FEEDBACK_COMMENT_MAX_LENGTH
            ) {
                isError = true
            }
        })

        Object.keys(ratingVals).forEach((inputKey) => {
            if (ratingVals[inputKey] < 1) {
                isError = true
            }
        })

        if (isError) {
            setShowErrors(true)
        } else {
            setShowErrors(false)

            const feedbackData = {
                comments: textInputVals,
                ratings: ratingVals,
            }
            dispatch(actions.feedbackSave({ feedbackData, meetInfo, autosave: false }))
        }
    }

    const handleFormReset = useCallback(() => {
        setShowErrors(false)
        setRatingVals(getFeedbackRatingInitial(meetInfo.type))
        setTextInputVals(getFeedbackCommentInitial(meetInfo.type))
        dispatch(actions.feedbackResetForm())
    }, [dispatch, meetInfo.type])

    useEffect(() => {
        if (successFeedbackSave) {
            handleFormReset()
            toastNotification('Feedback Saved!')
            history.push('/profile')
            dispatch(setFeedbackSubmitted(true))
        }

        if (errorFeedbackSave) {
            toastNotification("Error: Can't save feedback! Try again.")
        }
    }, [successFeedbackSave, errorFeedbackSave, handleFormReset, history, dispatch])

    if (!meetInfo.roomID) {
        history.push('/404')
    }

    return (
        <>
            <Navbar />
            <div className="feedbackForm">
                <Typography
                    variant="h1"
                    color="indigo.600"
                    align="center"
                    typography={{ textTransform: 'uppercase' }}
                    fontWeight="600"
                    letterSpacing="0.05em"
                    fontSize="1rem"
                    lineHeight="1.5rem"
                >
                    Interview Feedback
                </Typography>
                <Typography
                    variant="h2"
                    color="grey.dark"
                    align="center"
                    fontSize="2.25rem"
                    lineHeight="2.5rem"
                    letterSpacing="-0.025em"
                    fontWeight="800"
                    mt="0.5rem"
                    className="feedbackForm__heading"
                >
                    Write feedback about the candidate.
                </Typography>

                <div className="feedbackForm__wrapper">
                    {getFeedbackParams(meetInfo.type).map((item) => (
                        <FeedbackFormItem
                            key={item.type}
                            {...item}
                            inputVal={textInputVals[item.type]}
                            handleOnTextChange={handleOnTextChange}
                            ratingVal={ratingVals[item.type]}
                            handleOnRatingChange={handleOnRatingChange}
                            showErrors={showErrors}
                        />
                    ))}
                </div>

                <div className="feedbackForm__buttons">
                    <ReportForm isButton />
                    <div>
                        <ButtonComp
                            variant="secondary"
                            handleOnClick={() => setIsResetConfirmOpen(true)}
                        >
                            Reset Form
                        </ButtonComp>
                        <ButtonComp handleOnClick={handleFormSubmit}>
                            Submit Feedback
                        </ButtonComp>
                    </div>
                </div>
            </div>

            <ConfirmModal
                isOpen={isResetConfirmOpen}
                setIsOpen={setIsResetConfirmOpen}
                handleOnConfirm={handleFormReset}
            />
        </>
    )
}

export default FeedbackForm

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './store/store'
import { ThemeProvider } from '@mui/material'

import './config/vars.css'
import App from './App'
import theme from './config/theme'

// for removing logs in production
if (process.env.REACT_APP_ENV === 'PROD') {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
}

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </React.StrictMode>
    </Provider>,
    document.getElementById('root')
)

import Chart from 'react-apexcharts'

const RatingGraph = ({ data }) => {
    const xAxis = () => {
        function join(t, a, s) {
            function format(m) {
                let f = new Intl.DateTimeFormat('en', m)
                return f.format(t)
            }
            return a.map(format).join(s)
        }
        let a = [{ day: 'numeric' }, { month: 'short' }, { year: 'numeric' }]
        const dates = []
        if (data?.length > 0)
            data.forEach((stat) => {
                dates.push(join(new Date(stat.date), a, ' '))
            })
        return dates
    }

    const getRatings = () => {
        let ratings = []
        if (data?.length > 0)
            data.forEach((ele) => {
                ratings.push(ele.rating)
            })
        return ratings
    }
    const options = {
        chart: {
            class: 'chart',
            type: 'line',
            dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2,
            },
            toolbar: {
                show: false,
            },
        },
        colors: ['#00609f'],
        dataLabels: {
            enabled: true,
        },
        stroke: {
            curve: 'smooth',
        },
        title: {
            // text: "Mock Interview Performance",
            align: 'left',
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#F6FCFF', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5,
            },
        },
        markers: {
            size: 1,
        },
        xaxis: {
            categories: xAxis(),
            title: {
                text: 'Dates',
            },
        },
        yaxis: {
            title: {
                text: 'Ratings',
            },
            min: Math.min(...getRatings()) - 1,
            max: Math.max(...getRatings()) + 10,
        },
        legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -20,
            offsetX: -5,
        },
        tooltip: {
            enabled: false,
        },
    }

    const series = [
        {
            data: getRatings(),
        },
    ]

    return (
        <Chart
            options={options}
            series={series}
            height="300"
            type="line"
            className="chart"
        />
    )
}

export default RatingGraph

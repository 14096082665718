import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'

import InstantInstructions from './InstantInstructions.jsx'
import LottieComp from '../utils/LottieComp/LottieComp'
import SEARCHING from '../../assets/lotties/matching.json'
import ERROR from '../../assets/lotties/red-error.json'
import INTERVIEW from '../../assets/lotties/interview.json'
import THUMB_UP from '../../assets/lotties/thumb-up.json'
import './Instant.css'

const InstantStatusCard = () => {
    const { status: instantInterviewStatus } = useSelector(
        (state) => state.instantInterview
    )

    const [isInstructionsOpen, setIsInstructionsOpen] = useState(false)

    const instantInterviewMessage = () => {
        switch (instantInterviewStatus) {
            case 'REQUESTED':
                return {
                    message: 'We are finding a match. Hold tight!',
                    color: 'grey.dark',
                    lottie: SEARCHING,
                }
            case 'MATCHED':
                return {
                    message: 'Found a match! Redirecting you to the interview.',
                    color: 'indigo.600',
                    lottie: THUMB_UP,
                }
            case 'NOT_MATCHED':
                return {
                    message: 'We could not find a match! Try one more time.',
                    color: 'red.100',
                    lottie: ERROR,
                }
            case 'FAILED':
                return {
                    message: 'Something went wrong! Please try again.',
                    color: 'red.400',
                    lottie: ERROR,
                }
            default:
                return {
                    message: 'Please read the instructions before setting an interview.',
                    color: 'grey.medium',
                    lottie: INTERVIEW,
                }
        }
    }

    const handleInstructionsOpen = () => {
        setIsInstructionsOpen(true)
    }

    return (
        <>
            <div className="instantStatusCard">
                <LottieComp animationJSON={instantInterviewMessage().lottie} />
                <Typography
                    variant="body1"
                    color={instantInterviewMessage().color}
                    className="instantStatusCard__message"
                >
                    {instantInterviewMessage().message}
                </Typography>
                {instantInterviewStatus === '' && (
                    <p
                        className="instantStatusCard__instructionButton"
                        onClick={handleInstructionsOpen}
                    >
                        View Instructions
                    </p>
                )}
            </div>

            <InstantInstructions
                isOpen={isInstructionsOpen}
                setIsOpen={setIsInstructionsOpen}
            />
        </>
    )
}

export default InstantStatusCard

import * as actionTypes from '../actionTypes'

const initialState = {
    loading: false,
    success: false,
    error: null,
    submissionID :0,
}

const schedulerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SCHEDULER_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                error: null,
            }

        case actionTypes.SCHEDULER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: null,
            }

        case actionTypes.SCHEDULER_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            }
            
        default:
                return state
        }
}

export default schedulerReducer

import * as actionTypes from '../actionTypes'

const initialState = {
    user: {},
}

const userInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_USER:
            return {
                user: action.payload,
            }

        case actionTypes.SIGN_OUT:
            return {
                user: {},
            }

        default:
            return state
    }
}

export default userInfoReducer

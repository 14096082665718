import axiosInstance from '../service/axios'
import store from '../store/store'
import { signOutUser } from '../store/actions/userInfo'
import history from '../history/history'

// logic for session exist or not
const Auth = {
    getAuth() {
        return Boolean(store.getState().userInfo.user.email)
    },
    async signOut() {
        try {
            await axiosInstance.post('/api/v1/users/logout')
        } catch (err) {
            console.log(err.message)
        } finally {
            store.dispatch(signOutUser())
            history.push('/')
        }
    },
}
export default Auth

import { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Navbar from '../Landing/Navbar/Navbar'
import ProfileStat from './ProfileStat/ProfileStat'
import UserProfile from './UserProfile/UserProfile'
import CheckList from './CheckList/CheckList'
import UpcomingInterviews from './UpcomingInterviews/UpcomingInterviews'
import PastInterviews from './PastInterviews/PastInterviews'
import Sidebar from './Sidebar/Sidebar'
import CardComp from '../utils/CardComp/CardComp'
import Integrations from './Integrations/Integrations'
import { ProfileContext } from './ProfileContext'
import { toggleSchedulerModal } from '../../store/actions/ui'
import { useLocation } from 'react-router-dom'
import './Profile.css'

const Profile = () => {
    const { profileRef, slotsRef, ratingRef, historyRef } = useContext(ProfileContext)
    const { user } = useSelector((state) => state.userInfo)
    const isDev = process.env.REACT_APP_ENV === 'DEV'
    const location = useLocation()
    const dispatch = useDispatch()

    useEffect(() => {
        if (location.state?.schedulerToggle)
            dispatch(toggleSchedulerModal())
    }, [location])
    return (
        <div className="profile custom-bg">
            <div ref={profileRef} />
            <Navbar isProfilePage={true} />

            <Sidebar>
                <div className="profile__wrapper">
                    <CardComp>
                        <div className="profile__firstCard">
                            <UserProfile />
                            <CheckList />
                        </div>
                    </CardComp>
                    {
                        isDev ? (
                            <CardComp>
                            <Integrations/>
                            </CardComp>
                        ) :<></>
                    }
                    <div ref={slotsRef} />
                    {!user.mockInterviewBan && (
                        <CardComp>
                            <UpcomingInterviews />
                        </CardComp>
                    )}

                    <div ref={ratingRef} />
                    <CardComp>
                        <ProfileStat />
                    </CardComp>

                    <div ref={historyRef} />
                    <CardComp>
                        <PastInterviews />
                    </CardComp>
                </div>
            </Sidebar>
        </div>
    )
}

export default Profile

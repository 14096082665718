import * as actionTypes from '../actionTypes'

const initialState = {
    code: '',
    loading: false,
    error: null,
    resultID: null,
    result: null,
    compileStatus: null,
    compileCodeLoading: false,
    codeTheme: 'monokai',
    codeLanguage: 'c_cpp',
    clearCode: false,
}

const codeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INTERVIEW_CODE_REQUEST:
            return { ...state, loading: true, error: null, result: null }

        case actionTypes.GET_INTERVIEW_CODE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                code: action.payload,
            }

        case actionTypes.GET_INTERVIEW_CODE_FAIL:
            return { ...state, loading: false, error: action.payload, code: '' }

        case actionTypes.RUN_INTERVIEW_CODE_REQUEST:
            return {
                ...state,
                compileCodeLoading: true,
                error: null,
                result: null,
                resultID: null,
                compileStatus: null,
            }

        case actionTypes.RUN_INTERVIEW_CODE_SUCCESS:
            return {
                ...state,
                error: null,
                resultID: action.payload,
                result: null,
            }

        case actionTypes.RUN_INTERVIEW_CODE_FAIL:
            return {
                ...state,
                compileCodeLoading: false,
                error: action.payload,
                result: null,
            }

        case actionTypes.GET_CODE_COMPILE_RESULT_REQUEST:
            return {
                ...state,
                compileCodeLoading: true,
                error: null,
                compileStatus: null,
            }

        case actionTypes.GET_CODE_COMPILE_RESULT_SUCCESS:
            return {
                ...state,
                compileCodeLoading: action.payload.compileCodeLoading,
                error: null,
                compileStatus: action.payload.status,
                result: action.payload.result,
            }

        case actionTypes.GET_CODE_COMPILE_RESULT_FAIL:
            return { ...state, compileCodeLoading: false, error: action.payload }

        case actionTypes.SET_SYNCED_CODE_STORE_DATA:
            return { ...state, ...action.payload }

        case actionTypes.SET_CODE_COMPILE_REQUEST_TIMEOUT:
            return { ...state, compileStatus: 'timeout', compileCodeLoading: false }

        case actionTypes.TOGGLE_CODE_THEME:
            return {
                ...state,
                codeTheme: state.codeTheme === 'monokai' ? 'github' : 'monokai',
            }

        case actionTypes.CODE_LANGUAGE_CHANGE:
            return { ...state, codeLanguage: action.payload || 'c_cpp' }

        case actionTypes.TOGGLE_CLEAR_CODE: {
            const isClearCode = !state.clearCode

            // about to switch roles
            if (isClearCode) {
                return { ...initialState, clearCode: true }
            } else {
                return { ...state, clearCode: false }
            }
        }

        default:
            return state
    }
}

export default codeReducer

import * as actionTypes from '../actionTypes'
import axiosInstance from '../../service/axios'
import parseChats from '../../utils/parseChats'
import toastNotification from '../../components/utils/Notification/Notification'

export const getMeetingChat =
    (meetingID = '') =>
    async (dispatch) => {
        try {
            dispatch({ type: actionTypes.GET_MEETING_CHAT_REQUEST })

            const { data } = await axiosInstance.get(`api/v1/chats/${meetingID}`)

            dispatch({
                type: actionTypes.GET_MEETING_CHAT_SUCCESS,
                payload: parseChats({ chatsData: data }),
            })
        } catch (err) {
            dispatch({
                type: actionTypes.GET_MEETING_CHAT_FAIL,
                payload: err.message,
            })

            toastNotification(
                'Error in fetching chats for this interview! Try again later.'
            )
        }
    }

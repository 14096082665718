import { useSelector } from 'react-redux'

import Chat from '../Chat/Chat'
import ReportForm from '../ReportForm/ReportForm'
import LogoText from '../../utils/Logo/LogoText'
import LogoIcon from '../../utils/Logo/LogoIcon'
import TesterNotes from '../TesterNotes/TesterNotes'
import './MeetNavbar.css'

const MeetNavbar = () => {
    const { questionsSidebar } = useSelector((state) => state.ui)

    return (
        <nav className="meetNavbar">
            <div
                className={`meetNavbar__logoWrapper meetNavbar__logoWrapper--mobile-hide ${
                    questionsSidebar && 'shiftLogo'
                }`}
            >
                <LogoText />
            </div>
            <div className="meetNavbar__logoWrapper meetNavbar__logoWrapper--mobile-show">
                <LogoIcon />
            </div>

            <div className="meetNavbar__rightWrapper">
                <TesterNotes />
                <div className="meetNavbar__actions">
                    <Chat />
                    <ReportForm />
                </div>
            </div>
        </nav>
    )
}

export default MeetNavbar

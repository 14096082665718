import React from 'react'
import { Typography } from '@mui/material'

import parseTime, { isSameMinute } from '../../../utils/parseTime'
import './Chat.css'

const ChatMessage = ({ message, lastMessage }) => {
    return (
        <div className="chatMessage">
            {lastMessage && lastMessage.name === message.name ? null : (
                <Typography
                    variant="h1"
                    color="grey.dark"
                    letterSpacing="-0.025em"
                    fontSize="1rem"
                    lineHeight="1.75rem"
                    fontWeight="500"
                    mt="1rem"
                >
                    {message.name}
                </Typography>
            )}
            <div className="chatMessage__bodyWrapper">
                <Typography
                    variant="body1"
                    color="grey.dark"
                    fontSize="0.9rem"
                    lineHeight="1.5rem"
                    className="chatMessage__message"
                >
                    {message.message}
                </Typography>

                {lastMessage &&
                isSameMinute(message.timestamp, lastMessage.timestamp) &&
                lastMessage.name === message.name ? (
                    <div style={{ minWidth: '100px' }} />
                ) : (
                    <Typography
                        variant="body1"
                        color="grey.main"
                        fontSize="0.9rem"
                        lineHeight="1.5rem"
                        minWidth="100px"
                        align="right"
                        className="chatMessage__timestamp"
                    >
                        {parseTime(message.timestamp)}
                    </Typography>
                )}
            </div>
        </div>
    )
}

export default ChatMessage

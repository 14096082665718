import * as actionTypes from '../actionTypes'
import axiosInstance from '../../service/axios'
import toastNotification from '../../components/utils/Notification/Notification'

export const setFeedbackSubmitted =
    (status = false) =>
    (dispatch) => {
        dispatch({ type: actionTypes.SET_FEEDBACK_SUBMITTED, payload: status })
    }

export const platformRatingSave =
    ({ rating, meeting_id }) =>
    async (dispatch) => {
        try {
            dispatch({
                type: actionTypes.SAVE_PLATFORM_RATING_REQUEST,
                payload: { rating, meeting_id },
            })

            if (rating !== 0) {
                await axiosInstance.post('api/v1/platform-rating', { rating, meeting_id })
            }

            dispatch({ type: actionTypes.SAVE_PLATFORM_RATING_SUCCESS })
            window.meetingId = null

            if (rating !== 0) {
                toastNotification('Thank you for rating our platform!')
            }
        } catch (err) {
            const errorMessage = err?.response?.data?.message
            dispatch({
                type: actionTypes.SAVE_PLATFORM_RATING_FAIL,
                payload: errorMessage,
            })
            toastNotification(errorMessage)
        }
    }

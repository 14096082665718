import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, Paper, Typography, Skeleton } from '@mui/material'
import { styled } from '@mui/material/styles'

import UpcomingInterviewCard from './UpcomingInterviewCard'
import { getUpcomingInterviews } from '../../../store/actions/interview'
import { toggleSchedulerModal } from '../../../store/actions/ui'
import './UpcomingInterviews.css'

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
}))

const UpcomingInterviews = () => {
    const dispatch = useDispatch()

    const { upcomingInterviews, fetchInterviewsLoading } = useSelector(
        (state) => state.interview
    )
    const { user } = useSelector((state) => state.userInfo)

    useEffect(() => {
        if (user && user.email) {
            dispatch(getUpcomingInterviews())
        }
    }, [dispatch, user])

    const getSlots = () => {
        let content = []
        let i
        upcomingInterviews.forEach((interviewDetail) => {
            content.push(
                <UpcomingInterviewCard
                    key={interviewDetail.id}
                    interviewDetail={interviewDetail}
                />
            )
        })

        for (i = 0; i < 5 - upcomingInterviews.length; i++) {
            content.push(
                <Grid
                    item
                    lg={2.4}
                    md={4}
                    xs={12}
                    key={i}
                    className="upcomingInterviews__gridItem"
                    onClick={() => dispatch(toggleSchedulerModal())}
                >
                    <Item className="upcomingInterviews__slotCard upcomingInterviews__slotCard--available">
                        <div>
                            <Typography
                                variant="h6"
                                fontSize="1.1rem"
                                data-cy="available"
                            >
                                Available
                            </Typography>
                        </div>
                    </Item>
                </Grid>
            )
        }
        return content
    }

    return (
        <>
            <div className="upcomingInterviews">
                <Typography
                    variant="h1"
                    fontSize="2rem"
                    fontWeight="500"
                    mb="0.3rem"
                    align="center"
                    color="primary"
                    className="mobile-heading"
                >
                    Interview Booking Slots
                </Typography>
                <Typography
                    variant="h3"
                    fontSize="1.1rem"
                    fontWeight="400"
                    align="center"
                    sx={{ color: 'var(--grey-medium)' }}
                    mb="3rem"
                    className="mobile-subheading"
                >
                    Schedule up to five interviews simultaneously
                </Typography>
                <Box sx={{ flexGrow: 1, marginBottom: 0, marginTop: '3rem' }}>
                    <Grid container spacing={2} align="center" justifyContent="center">
                        {fetchInterviewsLoading
                            ? [1, 2, 3, 4, 5].map((_, key) => (
                                  <Grid m={1} key={key}>
                                      <Skeleton
                                          animation="wave"
                                          variant="rectangular"
                                          width={160}
                                          height={180}
                                      />
                                  </Grid>
                              ))
                            : getSlots()}
                    </Grid>
                </Box>
            </div>
        </>
    )
}

export default UpcomingInterviews

import { Chip } from '@mui/material'
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

import NoVideoCard from '../NoVideoCard/NoVideoCard'
import CanvasElement from '../../MeetingGateway/CanvasElement/CanvasElement'

const UserVideo = ({
    videoRef,
    video,
    audio,
    isTester,
    name,
    image,
    isMute = false,
    needBlurCanvas = false,
}) => {
    return (
        <div className="meeting__videoWrapper">
            <video
                ref={videoRef}
                className="meeting__video"
                autoPlay
                muted={isMute}
                playsInline
            />
            {needBlurCanvas && <CanvasElement elementID="meetingCanvasID" />}
            {!video && <NoVideoCard name={name} image={image} />}
            {audio ? (
                <MoreHorizIcon color="secondary" className="meeting__micStatusIcon" />
            ) : (
                <MicOffOutlinedIcon
                    color="secondary"
                    className="meeting__micStatusIcon"
                />
            )}
            <Chip
                label={isTester ? 'Interviewer' : 'Candidate'}
                color="primary"
                className="meeting__roleTag"
            />
        </div>
    )
}

export default UserVideo

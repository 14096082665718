import { useState } from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Rating,
    Box,
} from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'

import ButtonComp from '../utils/ButtonComp/ButtonComp'
import { platformRatingSave } from '../../store/actions/platformRating'

const labels = {
    1: 'Poor',
    2: 'Ok',
    3: 'Good',
    4: 'Excellent',
    5: 'Fully Satisfied',
}

const PlatformRating = () => {
    const dispatch = useDispatch()

    const { isFeedbackSubmitted: isOpen } = useSelector((state) => state.platformRating)

    const [rating, setRating] = useState(0)
    const [hover, setHover] = useState(-1)

    const handleSubmit = () => {
        const meeting_id = parseInt(window.meetingId)
        dispatch(platformRatingSave({ rating, meeting_id }))
    }

    return (
        <Dialog open={isOpen}>
            <DialogTitle>Thank You!</DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: '5px',
                    }}
                >
                    <Rating
                        size="large"
                        value={rating}
                        onChange={(e) => setRating(Number(e.target.value))}
                        onChangeActive={(_, newHover) => {
                            setHover(newHover)
                        }}
                    />
                    {rating !== null && rating !== 0 && (
                        <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : rating]}</Box>
                    )}
                </Box>
                <DialogContentText>
                    Please rate your interview experience.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ButtonComp handleOnClick={handleSubmit}>Submit</ButtonComp>
            </DialogActions>
        </Dialog>
    )
}

export default PlatformRating

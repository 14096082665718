import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Typography } from '@mui/material'

import ERROR_404 from '../../assets/lotties/404.json'
import LottieComp from '../utils/LottieComp/LottieComp'
import './Error404.css'

const Error404 = () => {
    const location = useLocation()
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        if (location.state?.message?.message)
            setErrorMessage(location.state?.message?.message)
    }, [location])
    return (
        <div className="error404 custom-bg">
            <div className="error404__lottieWrapper">
                <LottieComp animationJSON={ERROR_404} />
            </div>
            {errorMessage.length > 0 && (
                <Typography
                    variant="h1"
                    color="grey.dark"
                    align="center"
                    letterSpacing="-0.025em"
                    fontSize="2rem"
                    lineHeight="1.75rem"
                    fontWeight="500"
                    mb="2rem"
                    className="meetingGateway__interviewType"
                >
                    {errorMessage}
                </Typography>
            )}
            <Link to="/" className="error404__link">
                Go Back To Home
            </Link>
        </div>
    )
}

export default Error404

import { useEffect, useState, useContext, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import VideocamIcon from '@mui/icons-material/Videocam'
import VideocamOffIcon from '@mui/icons-material/VideocamOff'
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined'
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined'
import BlurOnIcon from '@mui/icons-material/BlurOn'
import BlurOffIcon from '@mui/icons-material/BlurOff'
import { IconButton, Typography, Tooltip, Badge } from '@mui/material'
import Countdown, { zeroPad } from 'react-countdown'

import getIcon from '../../utils/getIcon'
import Loader from '../utils/Loader/Loader'
import ConfigureDevices from './ConfigureDevices/ConfigureDevices'
import ButtonComp from '../utils/ButtonComp/ButtonComp'
import toastNotification from '../utils/Notification/Notification'
import Navbar from '../Landing/Navbar/Navbar'
import NoVideoCard from '../Meeting/NoVideoCard/NoVideoCard'
import * as actions from '../../store/actions/meeting'
import { connectSocket, sendUserId, kickOut } from '../Meeting/socket'
import { MeetContext } from '../Meeting/MeetContext'
import { ONE_ROLE_TIME_SLOT_IN_MILLISECONDS } from '../../constants/vars'
import CanvasElement from './CanvasElement/CanvasElement'
import { segment } from '../../utils/selfieSegmentation'
import './MeetingGateway.css'

let currentTime = Date.now()

// for countdown display
const timerRenderer = ({ days, hours, minutes, seconds, completed, api }) => {
    if (completed) {
        return null
    } else {
        if (api.isStopped()) {
            api.start()
        }

        if (Number(days) === 0 && Number(hours) === 0 && Number(minutes) <= 9) {
            return (
                <Typography variant="body1" className="meetingGateway__timer">
                    Time Left :{' '}
                    <span className="timer">
                        {zeroPad(minutes)}:{zeroPad(seconds)}
                    </span>
                </Typography>
            )
        } else {
            return null
        }
    }
}

const MeetingGateway = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { roomID } = useParams()

    const {
        userVideoRef,
        isUserAudioAvailable,
        isUserVideoAvailable,
        setIsUserScreenAvailable,
        videoDevice,
        audioDevice,
        meetInfo,
    } = useContext(MeetContext)

    const { user: userInfo } = useSelector((state) => state.userInfo)
    const meeting = useSelector((state) => state.meeting)
    const { interviewStartTime, isBackgroundBlur } = meeting

    const [isSettingsOpen, setIsSettingsOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [devices, setDevices] = useState([])
    const [timeRemaining, setTimeRemaining] = useState(0)
    const [isJoinButtonDisabled, setIsJoinButtonDisabled] = useState(true)

    useEffect(() => {
        if (meeting.token === null) {
            history.push(`/interview-verify/${roomID}`)
        }
    }, [history, meeting.token, roomID])

    const startLocalStream = useCallback(async () => {
        await navigator.mediaDevices
            .getUserMedia({
                video: {
                    deviceId: videoDevice,
                    width: { ideal: 640 },
                    height: { ideal: 360 },
                },
                audio: { deviceId: audioDevice },
            })
            .then((stream) => {
                userVideoRef.current.srcObject = stream
            })
            .catch((err) => console.log(err))
    }, [audioDevice, userVideoRef, videoDevice])

    const getPermissions = useCallback(async () => {
        await navigator.mediaDevices
            .getUserMedia({ video: true })
            .then(() => (isUserVideoAvailable.current = true))
            .catch(() => (isUserVideoAvailable.current = false))

        await navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(() => (isUserAudioAvailable.current = true))
            .catch(() => (isUserAudioAvailable.current = false))

        if (navigator.mediaDevices.getDisplayMedia) {
            setIsUserScreenAvailable(true)
        } else {
            setIsUserScreenAvailable(false)
            toastNotification('Error: Your screen sharing is not available!')
        }

        await navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => setDevices(devices))
            .catch((err) => console.log(err))

        if (isUserVideoAvailable.current || isUserAudioAvailable.current) {
            startLocalStream()
        } else {
            toastNotification('Error: Video and audio are necessary for interview.')
        }
    }, [
        isUserAudioAvailable,
        isUserVideoAvailable,
        setIsUserScreenAvailable,
        startLocalStream,
    ])

    useEffect(() => {
        if (!navigator.onLine) return history.push('/404')

        navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => setDevices(devices))
            .catch((err) => console.log(err))

        if (loading) {
            try {
                if (!meetInfo || meetInfo.exp < Date.now() / 1000) {
                    throw Error('404')
                }
                dispatch(actions.changeTesterId(meetInfo.tester))
                setLoading(false)
            } catch (e) {
                return history.push('/404')
            }
        }
    }, [dispatch, history, loading, meetInfo])

    useEffect(() => {
        getPermissions()
    }, [getPermissions])

    useEffect(() => {
        if (!loading) {
            connectSocket()
            sendUserId(meeting.events.sendUserId, meeting.token)
            kickOut(meeting.events.kickOut, () => {
                window.location.href = '/'
            })
        }
    }, [loading, meeting.token, meeting.events.sendUserId, meeting.events.kickOut])

    // setting the remaining time to the interview
    useEffect(() => {
        currentTime = Date.now()
        const timeLeft = interviewStartTime - currentTime

        if (timeLeft > 0) {
            setTimeRemaining(timeLeft)
        } else {
            setIsJoinButtonDisabled(false)
        }
    }, [interviewStartTime])

    // to flip the role if roles are still not swapped after 45 minutes
    useEffect(() => {
        if (Date.now() > interviewStartTime + ONE_ROLE_TIME_SLOT_IN_MILLISECONDS) {
            if (meetInfo && !meetInfo.isFlipped && !meetInfo.flipTime) {
                dispatch(actions.swapRole(meetInfo))
            }
        }
    }, [dispatch, interviewStartTime, meetInfo])

    // when countdown is ended
    const handleCountdownEnd = () => {
        setIsJoinButtonDisabled(false)
    }

    const handleMeetJoin = () => {
        dispatch(actions.saveUserAction(userInfo.id, meetInfo.roomID, 'entry'))
        if (userVideoRef.current && userVideoRef.current.srcObject) {
            userVideoRef.current.srcObject.getTracks().forEach((track) => {
                track.stop()
            })
            userVideoRef.current.srcObject = null
        }

        if (meetInfo.isFlipped) {
            dispatch(actions.toggleRolesFlip(meetInfo.flipTime * 1000))
        }

        history.push(`/interview/${meetInfo.roomID}`)
    }

    const handleToggleVideo = () => {
        try {
            if (meeting.video) {
                userVideoRef.current.srcObject.getVideoTracks().forEach((track) => {
                    track.stop()
                })
            } else {
                navigator.mediaDevices
                    .getUserMedia({
                        video: { deviceId: videoDevice },
                        audio: meeting.audio ? { deviceId: audioDevice } : false,
                    })
                    .then((stream) => {
                        userVideoRef.current.srcObject = stream
                    })
                    .catch((err) => {
                        console.log('[handleToggleVideo() failed!]', err)
                    })
            }
            dispatch(actions.toggleVideo())
        } catch (err) {
            console.log('[handleToggleVideo() failed!]', err)
        }
    }

    const handleToggleAudio = () => {
        try {
            if (meeting.audio) {
                userVideoRef.current.srcObject.getAudioTracks().forEach((track) => {
                    track.stop()
                })
            } else {
                navigator.mediaDevices
                    .getUserMedia({
                        video: meeting.video ? { deviceId: videoDevice } : false,
                        audio: { deviceId: audioDevice },
                    })
                    .then((stream) => {
                        userVideoRef.current.srcObject = stream
                    })
                    .catch((err) => {
                        console.log('[handleToggleAudio() failed!]', err)
                    })
            }
            dispatch(actions.toggleAudio())
        } catch (err) {
            console.log('[handleToggleAudio() failed!]', err)
        }
    }

    const handleToggleBackgroundBlur = () => {
        dispatch(actions.toggleBackgroundBlur())
    }

    useEffect(() => {
        if (!isBackgroundBlur) return

        toastNotification(
            'Enabling video background blur will affect the performance! NOTE: You can only enable/disable this feature before entering the interview.'
        )
        startLocalStream()

        const videoElement = userVideoRef.current
        if (videoElement) {
            videoElement.onplaying = async () => {
                let lastTime = new Date()

                async function getFrames() {
                    const now = videoElement.currentTime
                    if (now > lastTime) {
                        await segment({
                            videoElement,
                            canvasElementID: 'meetingGatewayCanvasID',
                        })
                    }
                    lastTime = now
                    requestAnimationFrame(getFrames)
                }

                await getFrames()
            }
        }
    }, [userVideoRef, isBackgroundBlur, startLocalStream])

    return (
        <div className="meetingGateway custom-bg">
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Navbar />
                    <ConfigureDevices
                        open={isSettingsOpen}
                        devices={devices}
                        closeModal={() => {
                            setIsSettingsOpen(false)
                        }}
                    />

                    <div className="meetingGateway__container">
                        <div className="meetingGateway__bodyWrapper">
                            <div className="meetingGateway__videoWrapper">
                                <CanvasElement elementID="meetingGatewayCanvasID" />
                                <video playsInline muted ref={userVideoRef} autoPlay />
                                {!meeting.video && (
                                    <NoVideoCard
                                        name={meetInfo.localUser.name}
                                        image={meetInfo.localUser.image}
                                    />
                                )}

                                <div className="meetingGateway__controls">
                                    <Tooltip title="Mic on/off" placement="top" arrow>
                                        <IconButton
                                            size="large"
                                            className={`meetingGateway__circleButton ${
                                                !meeting.audio &&
                                                'meetingGateway__circleButton--off'
                                            }`}
                                            onClick={handleToggleAudio}
                                        >
                                            {meeting.audio ? (
                                                <MicNoneOutlinedIcon color="secondary" />
                                            ) : (
                                                <MicOffOutlinedIcon color="secondary" />
                                            )}
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Camera on/off" placement="top" arrow>
                                        <IconButton
                                            size="large"
                                            className={`meetingGateway__circleButton ${
                                                !meeting.video &&
                                                'meetingGateway__circleButton--off'
                                            }`}
                                            onClick={handleToggleVideo}
                                        >
                                            {meeting.video ? (
                                                <VideocamIcon color="secondary" />
                                            ) : (
                                                <VideocamOffIcon color="secondary" />
                                            )}
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Settings" placement="top" arrow>
                                        <IconButton
                                            className="videoWrapper__configureButton"
                                            onClick={() => setIsSettingsOpen(true)}
                                        >
                                            {getIcon('SETTINGS')}
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip
                                        title="Video background blur"
                                        placement="top"
                                        arrow
                                    >
                                        <Badge
                                            color="primary"
                                            badgeContent="beta"
                                            overlap="circular"
                                        >
                                            <IconButton
                                                size="large"
                                                className={`meetingGateway__circleButton ${
                                                    isBackgroundBlur &&
                                                    'meetingGateway__circleButton--blurOn'
                                                }`}
                                                onClick={handleToggleBackgroundBlur}
                                            >
                                                {isBackgroundBlur ? (
                                                    <BlurOnIcon color="secondary" />
                                                ) : (
                                                    <BlurOffIcon color="secondary" />
                                                )}
                                            </IconButton>
                                        </Badge>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="meetingGateway__joinWrapper">
                                <Typography
                                    variant="h1"
                                    color="grey.dark"
                                    align="center"
                                    letterSpacing="-0.025em"
                                    fontSize="2rem"
                                    lineHeight="1.75rem"
                                    fontWeight="500"
                                    mb="2rem"
                                    className="meetingGateway__interviewType"
                                >
                                    {meetInfo.type}
                                </Typography>

                                <Typography
                                    variant="body1"
                                    color="indigo.600"
                                    align="center"
                                    typography={{ textTransform: 'uppercase' }}
                                    fontWeight="600"
                                    letterSpacing="0.05em"
                                    fontSize="1.2rem"
                                    lineHeight="1.5rem"
                                >
                                    All set to go!
                                </Typography>
                                <Typography
                                    variant="h1"
                                    color="grey.main"
                                    align="center"
                                    fontSize="1.1rem"
                                    lineHeight="1.5rem"
                                    mb="1rem"
                                    mt="0.25rem"
                                >
                                    Welcome!{' '}
                                    <span className="meetingGateway__username">
                                        {userInfo.firstName} {userInfo?.lastName}
                                    </span>
                                </Typography>

                                <ButtonComp
                                    size="lg"
                                    handleOnClick={handleMeetJoin}
                                    disabled={
                                        (!isUserVideoAvailable.current &&
                                            !isUserAudioAvailable.current) ||
                                        isJoinButtonDisabled
                                    }
                                >
                                    {isJoinButtonDisabled
                                        ? 'You are early'
                                        : 'Join Interview'}
                                </ButtonComp>
                                {Number(timeRemaining) > 0 && (
                                    <Countdown
                                        date={currentTime + timeRemaining}
                                        renderer={timerRenderer}
                                        onComplete={handleCountdownEnd}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default MeetingGateway

import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle'
import { IconButton, Tooltip, Typography, CircularProgress } from '@mui/material'

import { toggleCodeResultPanel } from '../../../store/actions/ui'
import { getCodeCompileResult } from '../../../store/actions/code'
import { CODE_COMPILE_STATUS } from '../../../constants/vars'

const CodeResult = () => {
    const dispatch = useDispatch()

    const { codeResultPanel } = useSelector((state) => state.ui)
    const { compileCodeLoading, result, codeTheme, resultID, compileStatus, clearCode } =
        useSelector((state) => state.code)

    const handleCodeResultPanelToggle = () => {
        dispatch(toggleCodeResultPanel(!codeResultPanel))
    }

    useEffect(() => {
        if (!resultID) return
        dispatch(getCodeCompileResult())
    }, [resultID, dispatch])

    // to close the code results panel on role swap
    useEffect(() => {
        if (clearCode && codeResultPanel) {
            dispatch(toggleCodeResultPanel(false))
        }
    }, [clearCode, dispatch, codeResultPanel])

    const isCompileStatusVisible =
        compileCodeLoading || CODE_COMPILE_STATUS[compileStatus]

    return (
        <div className={`codeResult ${codeResultPanel && 'open'}`}>
            <Tooltip
                title={`${codeResultPanel ? 'Minimize' : 'Expand'} output`}
                placement="top"
                arrow
            >
                <IconButton
                    className={`codeResult__toggleButton ${codeResultPanel && 'open'}`}
                    onClick={handleCodeResultPanelToggle}
                >
                    <ArrowDropDownCircleIcon
                        color={`${codeTheme === 'monokai' ? 'secondary' : 'primary'}`}
                        fontSize="large"
                    />
                </IconButton>
            </Tooltip>

            <div className="codeResult__headerWrapper">
                <Typography
                    variant="h1"
                    color="white"
                    letterSpacing="-0.025em"
                    fontSize="1.125rem"
                    lineHeight="1.75rem"
                    fontWeight="500"
                    ml="0.4rem"
                >
                    Output :
                </Typography>
                {isCompileStatusVisible && (
                    <Typography
                        variant="h1"
                        letterSpacing="-0.025em"
                        fontSize="0.9rem"
                        fontWeight="400"
                        ml="1rem"
                        className="codeResult__statusWrapper"
                    >
                        {compileCodeLoading && <CircularProgress size={25} />}
                        &nbsp;
                        {CODE_COMPILE_STATUS[compileStatus]}
                    </Typography>
                )}
            </div>
            {result && (
                <div className="codeResult__resultWrapper">
                    {result[0] && <p dangerouslySetInnerHTML={{ __html: result[0] }}></p>}
                    <br />
                    {result[1] && (
                        <>
                            <p>Error :</p>
                            <p dangerouslySetInnerHTML={{ __html: result[1] }}></p>
                        </>
                    )}
                </div>
            )}
        </div>
    )
}

export default CodeResult

import Axios from '../../service/axios'
import * as actionTypes from '../actionTypes'
import toastNotification from '../../components/utils/Notification/Notification'
import { OTP_LOCAL_STORAGE_FLAG } from '../../constants/vars'
import { fetchUser } from '../actions/userInfo'

export const sendOTP = (phoneNumber) => async (dispatch, getState) => {
    const { count, userID } = getState().otp
    const { user } = getState().userInfo

    if (userID === user.id) {
        if (count >= 3) {
            dispatch({
                type: actionTypes.OTP_SEND_FAIL,
                payload: 'Error: Max OTP requests exceeded! Try again in an hour.',
            })
            toastNotification('Error: Max OTP requests exceeded! Try again in an hour.')
            return
        }
    } else {
        localStorage.removeItem(OTP_LOCAL_STORAGE_FLAG)
        dispatch({ type: actionTypes.OTP_STORE_RESET })
    }

    const number = phoneNumber.includes('+') ? phoneNumber : '+' + phoneNumber

    try {
        dispatch({
            type: actionTypes.OTP_SEND_REQUEST,
            payload: { phoneNumber: number, userID: user.id },
        })

        await Axios.post('api/v1/meeting/sendotp', {
            phoneNumber: number, // phone number must have the county code along with '+' sign
        })

        localStorage.setItem(
            OTP_LOCAL_STORAGE_FLAG,
            JSON.stringify({
                timestamp: Date.now(),
                count: userID === user.id ? count + 1 : 1,
                userID: user.id,
            })
        )

        dispatch({ type: actionTypes.OTP_SEND_SUCCESS })
        toastNotification('OTP sent successfully to your number.')
        dispatch({ type: actionTypes.OTP_SEND_COUNT_INCREMENT })
    } catch (err) {
        let errorMessage = err?.response?.data?.message
            ? err?.response?.data?.message
            : 'Something went wrong! Try again.'

        dispatch({
            type: actionTypes.OTP_SEND_FAIL,
            payload: errorMessage,
        })

        toastNotification(errorMessage)
    }
}

export const editPhone = () => (dispatch) => {
    dispatch({ type: actionTypes.OTP_EDIT_PHONE })
}

export const verifyOTP = (otp) => async (dispatch) => {
    try {
        dispatch({ type: actionTypes.OTP_VERIFY_REQUEST })
        await Axios.post('api/v1/meeting/verifyotp', {
            Key: otp,
        })

        dispatch({ type: actionTypes.OTP_VERIFY_SUCCESS })
        dispatch(fetchUser())
        toastNotification('OTP verified! Phone number updated.')
    } catch (err) {
        let errorMessage = err.message ? err.message : 'Something went wrong! Try again.'

        dispatch({
            type: actionTypes.OTP_VERIFY_FAIL,
            payload: errorMessage,
        })

        toastNotification(errorMessage)
    }
}

export const setOTPStore = () => async (dispatch, getState) => {
    const { user } = getState().userInfo
    const { userID } = getState().otp

    if (user) {
        if (user.id !== userID) {
            localStorage.removeItem(OTP_LOCAL_STORAGE_FLAG)
            dispatch({ type: actionTypes.OTP_STORE_RESET })
        }
    }
}

import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Auth from '../middleware'

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                Auth.getAuth() ? <Component {...props} /> : <Redirect to="/" />
            }
        />
    )
}

export default PrivateRoute

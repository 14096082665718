import React from 'react'
import { Typography } from '@mui/material'

import getIcon from '../../../utils/getIcon'
import './WhyUs.css'

const WhyUsItem = ({ icon, title, content }) => {
    return (
        <div className="whyUsItem" data-aos="zoom-in">
            <div className="whyUsItem__icon">{getIcon(icon)}</div>
            <div className="whyUsItem__textWrapper">
                <Typography
                    variant="h1"
                    color="grey.dark"
                    letterSpacing="-0.025em"
                    fontSize="1.125rem"
                    lineHeight="1.75rem"
                    fontWeight="500"
                >
                    {title}
                </Typography>
                <Typography
                    variant="body1"
                    color="grey.main"
                    mt="0.5rem"
                    fontSize="1rem"
                    lineHeight="1.5rem"
                >
                    {content}
                </Typography>
            </div>
        </div>
    )
}

export default WhyUsItem

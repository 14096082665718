import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Auth from '../middleware'

const ContextRoute = ({ contextComponent: Provider, component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                Auth.getAuth() ? (
                    <Provider>
                        <Component {...props} />
                    </Provider>
                ) : (
                    <Redirect to="/" />
                )
            }
        />
    )
}

export default ContextRoute

import React, { createContext, useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import jwtDecode from 'jwt-decode'

const MeetContext = createContext()

const MeetContextProvider = ({ children }) => {
    const { token } = useSelector((state) => state.meeting)

    const userVideoRef = useRef()
    const remoteVideoRef = useRef()
    const userScreenRef = useRef()
    const remoteScreenRef = useRef()
    const isUserVideoAvailable = useRef(false)
    const isUserAudioAvailable = useRef(false)

    const [isUserScreenAvailable, setIsUserScreenAvailable] = useState(false)
    const [videoDevice, setVideoDevice] = useState(
        localStorage.getItem('videoDevice') !== undefined
            ? localStorage.getItem('videoDevice')
            : ''
    )
    const [audioDevice, setAudioDevice] = useState(
        localStorage.getItem('audioDevice') !== undefined
            ? localStorage.getItem('audioDevice')
            : 'default'
    )
    const [meetInfo, setMeetInfo] = useState({})

    useEffect(() => {
        if (token) {
            const decodedToken = jwtDecode(token)
            const localUser = decodedToken.people.find(
                (person) => person.id === decodedToken.userID
            )
            const remoteUser = decodedToken.people.find(
                (person) => person.id !== decodedToken.userID
            )
            setMeetInfo({ ...decodedToken, localUser, remoteUser })
        }
    }, [token])

    return (
        <MeetContext.Provider
            value={{
                userVideoRef,
                remoteVideoRef,
                userScreenRef,
                remoteScreenRef,
                isUserVideoAvailable,
                isUserAudioAvailable,
                isUserScreenAvailable,
                setIsUserScreenAvailable,
                meetInfo,
                videoDevice,
                setVideoDevice,
                audioDevice,
                setAudioDevice,
            }}
        >
            {children}
        </MeetContext.Provider>
    )
}

export { MeetContext, MeetContextProvider }

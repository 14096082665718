import * as actionTypes from '../actionTypes'
import axiosInstance from '../../service/axios'
import toastNotification from '../../components/utils/Notification/Notification'
import { INSTANT_INTERVIEW_MATCH_TIMER } from '../../constants/vars'

export const postInstantInterviewRequest = (reqBody) => async (dispatch) => {
    try {
        dispatch({
            type: actionTypes.SET_INSTANT_INTERVIEW_REQUEST_STATUS,
            payload: 'REQUESTED',
        })
        const reqResponse = await axiosInstance.post(
            'api/v1/instant-meeting/create',
            reqBody
        )
        let reqCount = 1
        const interval = setInterval(async () => {
            let statusResponse = await axiosInstance.post(
                `api/v1/instant-meeting/status/${reqResponse.data.id}`,
                reqBody
            )
            if (statusResponse.status === 200) {
                clearInterval(interval)
                dispatch({
                    type: actionTypes.SET_INSTANT_INTERVIEW_REQUEST_STATUS,
                    payload: 'MATCHED',
                    meetingID: statusResponse.data.id,
                })
            }
            if (reqCount >= 6) {
                clearInterval(interval)
                dispatch({
                    type: actionTypes.SET_INSTANT_INTERVIEW_REQUEST_STATUS,
                    payload: 'NOT_MATCHED',
                })
            }
            reqCount++
        }, (INSTANT_INTERVIEW_MATCH_TIMER * 1000) / 6)
    } catch (err) {
        toastNotification(err.message)
        dispatch({
            type: actionTypes.SET_INSTANT_INTERVIEW_REQUEST_STATUS,
            payload: 'FAILED',
        })
    }
}

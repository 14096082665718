import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Countdown, { zeroPad } from 'react-countdown'
import { Typography } from '@mui/material'

import ButtonComp from '../../utils/ButtonComp/ButtonComp'
import { INSTANT_INTERVIEW_REQUEST_TIMER } from '../../../constants/vars'
import './InstantInterviewTimer.css'

const timerRenderer = ({ minutes, seconds, completed, api }) => {
    if (completed) {
        // when countdown is ended
        return null
    } else {
        //when countdown is going on
        if (api.isStopped()) {
            api.start()
        }

        return (
            <Typography variant="body1" className="instantInterviewTimer__timer">
                Request an Instant Interview in{' '}
                <span>
                    {zeroPad(minutes)}:{zeroPad(seconds)}
                </span>
            </Typography>
        )
    }
}

const InstantInterviewTimer = () => {
    const history = useHistory()
    const { roomUsersCount } = useSelector((state) => state.meeting)

    const [isButtonDisable, setIsButtonDisable] = useState(true)
    const [timeRemaining, setTimeRemaining] = useState(
        Date.now() + INSTANT_INTERVIEW_REQUEST_TIMER
    )

    useEffect(() => {
        if (roomUsersCount === 1) {
            setTimeRemaining(Date.now() + INSTANT_INTERVIEW_REQUEST_TIMER)
        }
    }, [roomUsersCount])

    const handleCountdownEnd = () => {
        setIsButtonDisable(false)
    }

    const handleButtonClick = () => {
        history.push('/instant')
    }

    return (
        <div className="instantInterviewTimer">
            <div className="instantInterviewTimer__wrapper">
                <Countdown
                    date={timeRemaining}
                    renderer={timerRenderer}
                    onComplete={handleCountdownEnd}
                />
                <ButtonComp disabled={isButtonDisable} handleOnClick={handleButtonClick}>
                    Request Instant Interview
                </ButtonComp>
            </div>
        </div>
    )
}

export default InstantInterviewTimer

import React from 'react'
import { toast } from 'react-toastify'

import LogoIcon from '../Logo/LogoIcon'

export const Notification = ({ children, text }) => {
    return (
        <div className="notification">
            <LogoIcon />
            {text && <p>{text}</p>}
            {children}
        </div>
    )
}

const toastNotification = (text) => toast(<Notification text={text} />)

export default toastNotification

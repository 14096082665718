import * as actionTypes from '../actionTypes'

const initialState = {
    feedbackComments: {},
    feedbackRatings: {},
    success: false,
    loading: false,
    error: null,
    historyFeedback: [],
    loadingQuestion: false,
    askedQuestion: null,
    errorQuestion: null,
}

const feedbackReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_FEEDBACK_COMMENTS:
            return { ...state, feedbackComments: action.payload }

        case actionTypes.SAVE_FEEDBACK_DATA_REQUEST:
            return { ...state, loading: true, error: null, success: false }

        case actionTypes.SAVE_FEEDBACK_DATA_SUCCESS:
            return { ...state, loading: true, error: null, success: true }

        case actionTypes.SAVE_FEEDBACK_DATA_FAIL:
            return { ...state, error: action.payload, loading: false, success: false }

        case actionTypes.GET_SAVED_FEEDBACK_REQUEST:
        case actionTypes.GET_HISTORY_FEEDBACK_REQUEST:
            return { ...state, loading: true, error: null, historyFeedback: [] }

        case actionTypes.GET_SAVED_FEEDBACK_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                feedbackComments: action.payload,
            }

        case actionTypes.GET_HISTORY_FEEDBACK_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                historyFeedback: action.payload,
            }

        case actionTypes.GET_SAVED_FEEDBACK_FAIL:
        case actionTypes.GET_HISTORY_FEEDBACK_FAIL:
            return { ...state, error: action.payload, loading: false, success: false }

        case actionTypes.FEEDBACK_FORM_RESET:
            return initialState

        case actionTypes.GET_ASKED_QUESTION_REQUEST:
            return {
                ...state,
                loadingQuestion: true,
                errorQuestion: null,
                askedQuestion: null,
            }

        case actionTypes.GET_ASKED_QUESTION_SUCCESS:
            return {
                ...state,
                loadingQuestion: false,
                errorQuestion: null,
                askedQuestion: action.payload,
            }

        case actionTypes.GET_ASKED_QUESTION_FAIL:
            return {
                ...state,
                errorQuestion: action.payload,
                loadingQuestion: false,
                askedQuestion: null,
            }

        default:
            return state
    }
}

export default feedbackReducer

import React from 'react'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'

import { PROFILE_IMAGES_BUCKET_BASE_URL } from '../../../constants/urls'
import './NoVideoCard.css'

const NoVideoCard = ({ name = '', image = '' }) => {
    return (
        <div className="noVideoCard">
            <Avatar
                alt={name}
                sx={{
                    width: 86,
                    height: 86,
                    bgcolor: (theme) => theme.palette.indigo[500],
                }}
                src={image !== '' ? `${PROFILE_IMAGES_BUCKET_BASE_URL}/${image}` : null}
            >
                <Typography
                    variant="h1"
                    color="white"
                    letterSpacing="-0.025em"
                    fontSize="2rem"
                    fontWeight="500"
                >
                    {name.toString()[0].toUpperCase()}
                </Typography>
            </Avatar>
        </div>
    )
}

export default NoVideoCard

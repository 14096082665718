import moment from 'moment';

export const addInterviewToGoogleCalendar = (interviewDetail) => {
    let { type, starttime } = interviewDetail
    type = type.replace(/ /g, '+')
    let text = type + '+-+Interview+Ready'

    let endtime = moment(starttime * 1000).add('90', 'minutes');
    endtime = endtime.toISOString().replace(/-/g, '').replace(/:/g, '')

    starttime = moment(starttime * 1000)
        .toISOString()
        .replace(/-/g, '')
        .replace(/:/g, '')

    let dates = starttime + '/' + endtime

    if (interviewDetail.status) {
        window.open(
            `https://calendar.google.com/calendar/u/0/r/eventedit?text=${text}&dates=${dates}&details=Your+${type}+has+been+scheduled.+Click+on+the+link+below+to+join+the+interview+-+${window.location.origin}/interview-verify/${interviewDetail.id}&sf=true&output=xml`,
            '_blank'
        )
    } else
        window.open(
            `https://calendar.google.com/calendar/u/0/r/eventedit?text=${text}&dates=${dates}&details=Visit+${window.location.origin}/profile&sf=true&output=xml`,
            '_blank'
        )
}
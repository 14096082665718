import * as actionTypes from '../actionTypes'

const initialState = {
    upcomingInterviews: [],
    fetchInterviewsLoading: false,
    fetchInterviewsError: null,
    cancelInterviewLoading: false,
    cancelInterviewError: null,
    cancelInterviewSuccess: false,
}

const interviewReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_UPCOMING_INTERVIEWS_REQUEST:
            return {
                ...state,
                fetchInterviewsLoading: true,
                fetchInterviewsError: null,
            }

        case actionTypes.GET_UPCOMING_INTERVIEWS_SUCCESS:
            return {
                ...state,
                fetchInterviewsLoading: false,
                fetchInterviewsError: null,
                upcomingInterviews: action.payload,
            }

        case actionTypes.GET_UPCOMING_INTERVIEWS_FAIL:
            return {
                ...state,
                fetchInterviewsLoading: false,
                fetchInterviewsError: action.payload,
            }

        case actionTypes.CANCEL_INTERVIEW_REQUEST:
            return {
                ...state,
                cancelInterviewLoading: true,
                cancelInterviewError: null,
                cancelInterviewSuccess: false,
            }

        case actionTypes.CANCEL_INTERVIEW_SUCCESS:
            return {
                ...state,
                cancelInterviewLoading: false,
                cancelInterviewError: null,
                cancelInterviewSuccess: true,
            }

        case actionTypes.REMOVE_CANCELLED_INTERVIEW:
            return {
                ...state,
                cancelInterviewSuccess: false,
                upcomingInterviews: state.upcomingInterviews.filter(
                    (interview) => Number(action.payload) !== Number(interview.id)
                ),
            }

        case actionTypes.CANCEL_INTERVIEW_FAIL:
            return {
                ...state,
                cancelInterviewLoading: false,
                cancelInterviewError: action.payload,
                cancelInterviewSuccess: false,
            }

        default:
            return state
    }
}

export default interviewReducer

import { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles'
import {
    Box,
    Drawer,
    CssBaseline,
    IconButton,
    Typography,
    CircularProgress,
} from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ReactMarkdown from 'react-markdown'

import { toggleQuestionsSidebar } from '../../../store/actions/ui'
import { getQuestion } from '../../../store/actions/meeting'
import './Questions.css'

const drawerWidth = 400

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}))

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    })
)

const Questions = ({ children }) => {
    const dispatch = useDispatch()

    const { questionsSidebar } = useSelector((state) => state.ui)
    const { questions, isFlip, isTester, loading } = useSelector((state) => state.meeting)

    const [width, setWidth] = useState(window.innerWidth)
    const [questionToDisplay, setQuestionToDisplay] = useState({
        name: '',
        description: '',
    })

    useEffect(() => {
        if (isTester && questions.length >= 2) {
            dispatch(getQuestion(isFlip ? questions[1] : questions[0]))
        }
    }, [dispatch, questions, isFlip, isTester])

    useEffect(() => {
        if (!loading && isTester && questions.length >= 2) {
            setQuestionToDisplay(isFlip ? questions[1] : questions[0])
        }
    }, [questions, isTester, isFlip, loading])

    const handleQuestionsSidebarToggle = useCallback(() => {
        dispatch(toggleQuestionsSidebar())
    }, [dispatch])

    // to open questions sidebar for interviewer on interview join
    useEffect(() => {
        if (isTester) handleQuestionsSidebarToggle()
    }, [handleQuestionsSidebarToggle, isTester])

    // to close the questions sidebar for candidate if already open
    useEffect(() => {
        if (!isTester && questionsSidebar) handleQuestionsSidebarToggle()
    }, [handleQuestionsSidebarToggle, isTester, questionsSidebar])

    const changeWindowWidth = () => {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', changeWindowWidth)
        return () => {
            window.removeEventListener('resize', changeWindowWidth)
        }
    }, [])

    // to close the questions sidebar on mobile devices
    useEffect(() => {
        if (width < 1300 && questionsSidebar) {
            handleQuestionsSidebarToggle()
        }
    }, [width, questionsSidebar, handleQuestionsSidebarToggle])

    return (
        <Box className="questions" sx={{ display: 'flex' }}>
            <CssBaseline />
            <Drawer
                variant="persistent"
                anchor="left"
                open={questionsSidebar}
                className="questions__sidebar"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
            >
                <DrawerHeader>
                    <Typography
                        variant="h1"
                        color="grey.dark"
                        letterSpacing="-0.025em"
                        fontSize="1.125rem"
                        lineHeight="1.75rem"
                        fontWeight="500"
                        ml="0.4rem"
                        mr="auto"
                    >
                        Question To Ask
                    </Typography>
                    <IconButton onClick={handleQuestionsSidebarToggle}>
                        <ChevronLeftIcon
                            className="questions__closeIcon"
                            color="primary"
                        />
                    </IconButton>
                </DrawerHeader>

                {loading ? (
                    <Box
                        sx={{
                            display: 'grid',
                            placeItems: 'center',
                            minHeight: '500px',
                            width: '100%',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <div className="questions__contentWrapper">
                        <Typography
                            variant="h2"
                            fontSize="1.1rem"
                            fontWeight="400"
                            letterSpacing="-0.025em"
                            mb="0.8rem"
                            color="grey.dark"
                        >
                            {questionToDisplay.name}
                        </Typography>
                        <div className="questions__markdownWrapper">
                            <ReactMarkdown>{questionToDisplay.description}</ReactMarkdown>
                        </div>
                    </div>
                )}
            </Drawer>
            <Main open={questionsSidebar}>{children}</Main>
        </Box>
    )
}

export default Questions

const parseTime = (timestamp) =>
    new Date(timestamp).toLocaleTimeString(navigator.language, {
        hour: '2-digit',
        minute: '2-digit',
    })

export const isSameMinute = (timestamp1 = '', timestamp2 = '') => {
    const time1 = parseTime(timestamp1)
    const time2 = parseTime(timestamp2)

    const hour1 = time1.split(':')[0]
    const hour2 = time2.split(':')[0]

    if (hour1 === hour2) {
        const minute1 = time1.split(':')[1]
        const minute2 = time2.split(':')[1]

        if (minute1 === minute2) {
            return minute1[1] === minute2[1]
        } else {
            return false
        }
    } else {
        return false
    }
}

export default parseTime

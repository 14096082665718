import React from 'react'
import { Grid, Typography } from '@mui/material'

import WhyUsItem from './WhyUsItem'
import WHY_US_DATA from '../../../constants/whyUsData'
import getIcon from '../../../utils/getIcon'
import { CDN_BASE_URL } from '../../../constants/urls'
import './WhyUs.css'

const WhyUs = () => {
    return (
        <section className="whyUs">
            {getIcon('DOTS_BG')}
            <Grid container className="whyUs__colsWrapper" alignItems="center">
                <Grid item xs={12} lg={6}>
                    <Typography
                        variant="h2"
                        color="grey.dark"
                        align="center"
                        fontSize="2.25rem"
                        lineHeight="2.5rem"
                        letterSpacing="-0.025em"
                        fontWeight="800"
                        mt="0.5rem"
                        mb="2.5rem"
                        className="whyUs__heading"
                    >
                        Why should you take a mock interview?
                    </Typography>

                    {WHY_US_DATA.map((data) => (
                        <WhyUsItem
                            key={data.id}
                            icon={data.icon}
                            title={data.title}
                            content={data.content}
                        />
                    ))}
                </Grid>
                <Grid item xs={12} lg={6} className="whyUs__videosWrapper">
                    <video data-aos="zoom-in" width="600" autoPlay loop muted playsInline>
                        <source
                            src={`${CDN_BASE_URL}/System Design ratings 360 fixed.mp4`}
                            type="video/mp4"
                        />
                    </video>
                </Grid>
            </Grid>
        </section>
    )
}

export default WhyUs

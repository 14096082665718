import { useSelector, useDispatch } from 'react-redux'
import { IconButton, Tooltip } from '@mui/material'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import NightlightRoundIcon from '@mui/icons-material/NightlightRound'
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite'

import CodeLanguage from './CodeLanguage'
import * as codeActions from '../../../store/actions/code'
import toastNotification from '../../utils/Notification/Notification'
import { emitCodeCompile } from '../socket'

const CodeEditorNavbar = ({ editorText }) => {
    const dispatch = useDispatch()

    const {
        loading: loadingCode,
        codeTheme,
        compileCodeLoading,
    } = useSelector((state) => state.code)
    const { isTester, isBehavioralInterview, events, token } = useSelector(
        (state) => state.meeting
    )

    const codeThemeType = codeTheme === 'monokai' ? 'light' : 'dark'

    const handleCodeExecution = () => {
        if (loadingCode) return
        if (!editorText.trim().length) {
            toastNotification('Nothing to compile!')
            return
        }

        emitCodeCompile(events.compileCode, token)
        dispatch(codeActions.runInterviewCode({ code: editorText, input: '' }))
    }

    const handleCodeEditorThemeToggle = () => {
        dispatch(codeActions.toggleCodeTheme())
    }

    return (
        <div className="codeEditorNavbar">
            <Tooltip title={`Switch to ${codeThemeType} theme`} placement="top" arrow>
                <IconButton
                    className="codeEditorNavbar__button"
                    onClick={handleCodeEditorThemeToggle}
                >
                    {codeTheme === 'monokai' ? (
                        <LightbulbIcon color="secondary" />
                    ) : (
                        <NightlightRoundIcon className="codeEditorNavbar__darkThemeIcon" />
                    )}
                </IconButton>
            </Tooltip>
            {!isTester && !isBehavioralInterview && (
                <Tooltip title="Run code" placement="top" arrow>
                    <IconButton
                        className={`codeEditorNavbar__button ${
                            compileCodeLoading && 'codeEditorNavbar__button--disabled'
                        }`}
                        onClick={handleCodeExecution}
                        disabled={compileCodeLoading}
                    >
                        <PlayCircleFilledWhiteIcon color="secondary" />
                    </IconButton>
                </Tooltip>
            )}

            {!isBehavioralInterview && <CodeLanguage />}
        </div>
    )
}

export default CodeEditorNavbar

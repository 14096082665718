import moment from 'moment'

import * as actionTypes from '../actionTypes'
import { OTP_LOCAL_STORAGE_FLAG, OTP_DISABLED_TIME_IN_HOURS } from '../../constants/vars'

const otpFlagFromStorage = JSON.parse(localStorage.getItem(OTP_LOCAL_STORAGE_FLAG))
let initialCount = 0,
    userID = null

if (otpFlagFromStorage && otpFlagFromStorage.timestamp) {
    if (
        moment().diff(otpFlagFromStorage.timestamp, 'hours') > OTP_DISABLED_TIME_IN_HOURS
    ) {
        localStorage.removeItem(OTP_LOCAL_STORAGE_FLAG)
        initialCount = 0
    } else {
        initialCount = otpFlagFromStorage.count
        userID = otpFlagFromStorage.userID
    }
}

const initialState = {
    success: false,
    loading: false,
    error: null,
    count: initialCount,
    phoneNumber: '',
    userID,
    verifySuccess: false,
}

const otpReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.OTP_SEND_REQUEST:
        case actionTypes.OTP_VERIFY_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                success: false,
                phoneNumber: action.payload
                    ? action.payload.phoneNumber
                    : state.phoneNumber,
                userID: action.payload ? action.payload.userID : state.userID,
                verifySuccess: false,
            }

        case actionTypes.OTP_SEND_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: null,
            }

        case actionTypes.OTP_SEND_FAIL:
        case actionTypes.OTP_VERIFY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
                verifySuccess: false,
            }

        case actionTypes.OTP_SEND_COUNT_INCREMENT:
            return { ...state, count: state.count + 1 }

        case actionTypes.OTP_EDIT_PHONE:
            return {
                ...state,
                loading: false,
                error: null,
                success: false,
            }

        case actionTypes.OTP_VERIFY_SUCCESS:
            return {
                ...state,
                loading: false,
                success: false,
                error: null,
                verifySuccess: true,
            }

        case actionTypes.OTP_STORE_RESET:
            return { ...initialState, count: 0 }

        default:
            return state
    }
}

export default otpReducer

import React, { useState, useCallback, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton, Drawer, Typography } from '@mui/material'
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded'
import CloseIcon from '@mui/icons-material/Close'
import Chip from '@mui/material/Chip'
import CloudDoneIcon from '@mui/icons-material/CloudDone'
import ErrorIcon from '@mui/icons-material/Error'
import { useAutosave } from 'react-autosave'

import ButtonComp from '../../utils/ButtonComp/ButtonComp'
import FeedbackInput from './FeedbackInput'
import {
    getFeedbackCommentInitial,
    getFeedbackParams,
} from '../../../utils/getFeedbackParams'
import * as actions from '../../../store/actions/feedback'
import { MeetContext } from '../MeetContext'
import {
    FEEDBACK_AUTOSAVE_INTERVAL_IN_MILLISECONDS,
    FEEDBACK_COMMENT_MAX_LENGTH,
} from '../../../constants/vars'
import './TesterNotes.css'

const autosaveMessageInitial = { type: '', message: '' }

const TesterNotes = () => {
    const dispatch = useDispatch()
    const { meetInfo } = useContext(MeetContext)

    const { isTester } = useSelector((state) => state.meeting)
    const { feedbackComments: savedFeedbackComments } = useSelector(
        (state) => state.feedback
    )

    const [isNotesOpen, setIsNotesOpen] = useState(false)
    const [width, setWidth] = useState(window.innerWidth)
    const [formInputs, setFormInputs] = useState(getFeedbackCommentInitial(meetInfo.type))
    const [autosaveMessage, setAutosaveMessage] = useState(autosaveMessageInitial)

    const autosaveFeedback = useCallback(
        (feedbackComments) => {
            let isError = false
            let isAllFieldsEmpty = true

            Object.keys(feedbackComments).forEach((inputKey) => {
                if (
                    feedbackComments[inputKey].trim().length > FEEDBACK_COMMENT_MAX_LENGTH
                ) {
                    isError = true
                }

                if (feedbackComments[inputKey].trim().length !== 0) {
                    isAllFieldsEmpty = false
                }
            })

            if (isError) {
                setAutosaveMessage({ type: 'error', message: 'Max Characters Exceeded' })
            } else {
                if (!isAllFieldsEmpty) {
                    const feedbackData = { comments: feedbackComments }
                    dispatch(actions.feedbackSave({ feedbackData, meetInfo }))
                    setAutosaveMessage({ type: 'success', message: 'Feedback Saved' })
                }
            }

            setTimeout(() => {
                setAutosaveMessage(autosaveMessageInitial)
            }, 3000)
        },
        [dispatch, meetInfo]
    )

    useAutosave({
        data: formInputs,
        onSave: autosaveFeedback,
        interval: FEEDBACK_AUTOSAVE_INTERVAL_IN_MILLISECONDS,
    })

    const handleInputChange = (e) => {
        setFormInputs((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }))
    }

    const handleNotesToggle = () => {
        if (isNotesOpen) {
            dispatch(actions.feedbackSetData(formInputs))
        }
        setIsNotesOpen((prev) => !prev)
    }

    // to fetch the already saved given feedback
    useEffect(() => {
        if (meetInfo) {
            dispatch(actions.feedbackGetSaved(meetInfo))
        }
    }, [dispatch, meetInfo])

    const changeWindowWidth = () => {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', changeWindowWidth)
        return () => {
            window.removeEventListener('resize', changeWindowWidth)
        }
    }, [])

    // to set the form fields with already saved feedback comments
    useEffect(() => {
        let isMounted = true

        if (isMounted && Object.keys(savedFeedbackComments).length > 0) {
            setFormInputs(savedFeedbackComments)
        }

        return () => {
            isMounted = false
        }
    }, [savedFeedbackComments])

    return (
        <>
            {isTester && (
                <ButtonComp
                    handleOnClick={handleNotesToggle}
                    modifyClass="testerNotes__button"
                >
                    {width > 768 ? 'Take' : ''} Notes&nbsp;
                    <NoteAltRoundedIcon color="secondary" />
                </ButtonComp>
            )}

            <Drawer
                anchor="right"
                ModalProps={{ keepMounted: true }}
                open={isNotesOpen}
                onClose={handleNotesToggle}
            >
                <div className="testerNotes">
                    <div className="testerNotes__header">
                        <Typography
                            variant="h1"
                            color="grey.dark"
                            letterSpacing="-0.025em"
                            fontSize="1.125rem"
                            lineHeight="1.75rem"
                            fontWeight="500"
                        >
                            Interview Notes/Feedback
                        </Typography>
                        {autosaveMessage.type && autosaveMessage.message && (
                            <Chip
                                icon={
                                    autosaveMessage.type === 'success' ? (
                                        <CloudDoneIcon />
                                    ) : (
                                        <ErrorIcon />
                                    )
                                }
                                label={autosaveMessage.message}
                                variant="outlined"
                                color={
                                    autosaveMessage.type === 'success'
                                        ? 'primary'
                                        : 'error'
                                }
                            />
                        )}
                        <IconButton onClick={handleNotesToggle}>
                            <CloseIcon />
                        </IconButton>
                    </div>

                    <div className="testerNotes__formWrapper">
                        {getFeedbackParams(meetInfo.type).map((item) => (
                            <FeedbackInput
                                key={item.type}
                                {...item}
                                inputVal={formInputs[item.type]}
                                handleOnChange={handleInputChange}
                            />
                        ))}
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default TesterNotes

import { Backdrop, CircularProgress } from '@mui/material'

const Offline = () => {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <CircularProgress color="inherit" />
            &nbsp;Don't reload browser for best experience...
            <br />
            &nbsp;We are trying to reconnect.
        </Backdrop>
    )
}

export default Offline

import { useState, useEffect } from 'react'
import { Grid, TextField, Rating, Typography } from '@mui/material'

import {
    FEEDBACK_COMMENT_MIN_LENGTH,
    FEEDBACK_COMMENT_MAX_LENGTH,
} from '../../constants/vars'
import './FeedbackForm.css'

const FeedbackFormItem = ({
    type = 'other',
    title = 'Other Comment',
    description = 'Write some comments about the candidate.',
    inputVal = '',
    handleOnTextChange = () => {},
    ratingVal = 0,
    handleOnRatingChange = () => {},
    showErrors = false,
}) => {
    const [nonSpaceCharacterCount, setNonSpaceCharacterCount] = useState(
        inputVal.trim().length
    )

    useEffect(() => {
        setNonSpaceCharacterCount(inputVal.trim().length)
    }, [inputVal])

    return (
        <Grid container justifyContent="space-between" className="feedbackFormItem">
            <Grid item xs={12} sm={6}>
                <Typography
                    variant="h1"
                    color="grey.dark"
                    letterSpacing="-0.025em"
                    fontSize="1.125rem"
                    lineHeight="1.75rem"
                    fontWeight="500"
                >
                    {title}
                </Typography>
                <Typography
                    variant="body1"
                    color="grey.main"
                    mt="0.4rem"
                    mb="0.4rem"
                    fontSize="1rem"
                    lineHeight="1.5rem"
                >
                    {description}
                </Typography>

                {/* if there is an other comments field then we don't want ratings for that. */}
                {type !== 'other' && (
                    <>
                        <Rating
                            name={type}
                            value={ratingVal}
                            onChange={handleOnRatingChange}
                        />
                        {showErrors && ratingVal < 1 && (
                            <Typography variant="subtitle1" color="error">
                                Please rate this parameter.
                            </Typography>
                        )}
                    </>
                )}
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    multiline
                    variant="filled"
                    name={type}
                    label={title}
                    minRows={4}
                    value={inputVal}
                    onChange={handleOnTextChange}
                    helperText={
                        nonSpaceCharacterCount > FEEDBACK_COMMENT_MAX_LENGTH
                            ? 'Exceeded max allowed characters'
                            : `${nonSpaceCharacterCount} /50 characters(min)`
                    }
                    error={
                        showErrors &&
                        (nonSpaceCharacterCount < FEEDBACK_COMMENT_MIN_LENGTH ||
                            nonSpaceCharacterCount > FEEDBACK_COMMENT_MAX_LENGTH)
                    }
                />
            </Grid>
        </Grid>
    )
}

export default FeedbackFormItem

import { useEffect, useState } from 'react'
import { Typography, Grid, Chip, Paper, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import moment from 'moment'

import { addInterviewToGoogleCalendar } from '../../../utils/addToCalenderUtils'
import InterviewActionModal from './InterviewActionModal'
import getIcon from '../../../utils/getIcon'

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
}))

const UpcomingInterviewCard = ({ interviewDetail }) => {
    const [isInterviewActionModalOpen, setIsInterviewActionModalOpen] = useState(false)

    const closeInterviewActionModal = () => {
        setIsInterviewActionModalOpen(false)
    }

    const startInterviewCancel = () => {
        setIsInterviewActionModalOpen(true)
    }
    // to auto cancel an interview directly from URL
    useEffect(() => {
        const paramsString = window.location.search
        const strFragments = paramsString.substring(1).split('=')

        if (
            strFragments.length === 2 &&
            strFragments[0] === 'cancel' &&
            Number(strFragments[1]) === Number(interviewDetail.id)
        ) {
            startInterviewCancel()
        }
    }, [interviewDetail.id])

    return (
        <>
            <Grid
                item
                lg={2.4}
                md={4}
                xs={12}
                key={interviewDetail.id}
                className="upcomingInterviews__gridItem"
                data-cy="view-details"
            >
                <Item
                    onClick={() => setIsInterviewActionModalOpen(true)}
                    className={`upcomingInterviews__slotCard ${
                        interviewDetail.status === 1
                            ? 'upcomingInterviews__slotCard--matched'
                            : 'upcomingInterviews__slotCard--requested'
                    }`}
                >
                    <Chip
                        label={`${interviewDetail.status === 1 ? 'Matched' : 'Waiting'}`}
                        color="primary"
                        className={`upcomingInterviews__chip ${
                            interviewDetail.status === 1 ? 'matched' : 'not-matched'
                        } `}
                    />
                    <div>
                        <Typography
                            variant="h6"
                            fontSize="1.1rem"
                            className="upcomingInterviews__interviewType"
                        >
                            {interviewDetail.type}
                        </Typography>
                        <Typography variant="subtitle1">
                            {moment(interviewDetail.starttime * 1000).format(
                                'DD-MM-YYYY'
                            )}
                        </Typography>
                        <Typography variant="h6" fontSize="1.1rem">
                            {moment(interviewDetail.starttime * 1000).format('hh:mm A')}
                        </Typography>
                    </div>
                </Item>
                <div className="upcomingInterviews__bottomRow" direction="row">
                    <Tooltip title="Add to Calender">
                        <div
                            className="upcomingInterviews__bottomButton"
                            onClick={() => addInterviewToGoogleCalendar(interviewDetail)}
                        >
                            {getIcon('ADD_TO_CALENDER')}
                        </div>
                    </Tooltip>
                    {interviewDetail.status === 1 && (
                        <Tooltip title="Join Interview">
                            <div className="upcomingInterviews__bottomButton">
                                <a
                                    className="upcomingInterviews__joinButton"
                                    href={`${window.location.origin}/interview-verify/${interviewDetail.id}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Join
                                </a>
                            </div>
                        </Tooltip>
                    )}
                    <Tooltip
                        title={`${
                            interviewDetail.status === 0
                                ? 'Cancel this interview request'
                                : 'Cancel this interview'
                        }`}
                    >
                        <div
                            className="upcomingInterviews__bottomButton"
                            onClick={startInterviewCancel}
                        >
                            {getIcon('CANCEL')}
                        </div>
                    </Tooltip>
                </div>
            </Grid>

            <InterviewActionModal
                isInterviewActionModalOpen={isInterviewActionModalOpen}
                selectedInterviewDetail={interviewDetail}
                closeInterviewActionModal={closeInterviewActionModal}
            />
        </>
    )
}

export default UpcomingInterviewCard

import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, TextField, Tab, Tabs, Typography } from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { MobileDateRangePicker, LocalizationProvider } from '@mui/lab'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'

import RatingGraph from './RatingGraph'
import { getUserRatings } from '../../../store/actions/rating'
import { INTERVIEW_TYPES } from '../../../constants/vars'
import './ProfileStat.css'

const TabPanel = (props) => {
    const { children, value, index, ...other } = props

    return (
        <div
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            {...other}
            className="profileStat__tabPanel"
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    )
}

let oneMonthAgoDate = new Date()
oneMonthAgoDate.setMonth(oneMonthAgoDate.getMonth() - 1)

const ProfileStat = () => {
    const dispatch = useDispatch()

    const { ratings } = useSelector((state) => state.rating)

    const [range, setRange] = useState([oneMonthAgoDate, new Date()])
    const [tab, setTab] = useState(0)

    const fetchUserRatings = useCallback(() => {
        const dateRange = {
            start_date: Math.round(range[0].getTime() / 1000),
            end_date: Math.round(range[1].getTime() / 1000),
        }

        dispatch(getUserRatings(dateRange))
    }, [dispatch, range])

    const handleChange = (_, newValue) => {
        setTab(newValue)
    }

    useEffect(() => {
        if (range.length === 2 && range[0] && range[1]) {
            fetchUserRatings()
        }
    }, [range, fetchUserRatings])

    return (
        <div className="profileStat">
            <Typography
                variant="h1"
                fontSize="1.8rem"
                fontWeight="500"
                mb="0.5rem"
                align="center"
                className="mobile-heading"
            >
                Your Ratings
            </Typography>

            <div className="profileStat__rangeSelectorWrapper">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDateRangePicker
                        value={range}
                        maxDate={new Date()}
                        onChange={(newValue) => {
                            setRange(newValue)
                        }}
                        renderInput={(startProps, endProps) => (
                            <>
                                <TextField
                                    {...startProps}
                                    variant="standard"
                                    size="small"
                                    color="primary"
                                    sx={{ width: '90px' }}
                                    label="From"
                                />
                                <Box sx={{ mx: 3, alignSelf: 'flex-end' }}>
                                    <ArrowRightAltIcon color="primary" fontSize="large" />
                                </Box>
                                <TextField
                                    {...endProps}
                                    variant="standard"
                                    size="small"
                                    color="primary"
                                    sx={{ width: '90px' }}
                                    label="To"
                                />
                            </>
                        )}
                    />
                </LocalizationProvider>
            </div>

            <div className="profileStat__sidebarAndGraphWrapper">
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={tab}
                    onChange={handleChange}
                    sx={{
                        borderRight: 1,
                        borderColor: 'divider',
                        flex: 0.2,
                        maxHeight: '350px',
                        backgroundColor: 'var(--grey-light)',
                        py: 3,
                    }}
                >
                    <Tab label={INTERVIEW_TYPES.coding} id={`vertical-tab-${0}`} />
                    <Tab label={INTERVIEW_TYPES.frontend} id={`vertical-tab-${1}`} />
                    <Tab label={INTERVIEW_TYPES.systemDesign} id={`vertical-tab-${2}`} />
                    <Tab
                        label={INTERVIEW_TYPES.systemDesignLLD}
                        id={`vertical-tab-${3}`}
                    />
                    <Tab label={INTERVIEW_TYPES.behavioral} id={`vertical-tab-${4}`} />
                </Tabs>

                <div className="profileStat__graphWrapper">
                    <TabPanel value={tab} index={0}>
                        <RatingGraph data={ratings[INTERVIEW_TYPES.coding]} />
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <RatingGraph data={ratings[INTERVIEW_TYPES.frontend]} />
                    </TabPanel>
                    <TabPanel value={tab} index={2}>
                        <RatingGraph data={ratings[INTERVIEW_TYPES.systemDesign]} />
                    </TabPanel>
                    <TabPanel value={tab} index={3}>
                        <RatingGraph data={ratings[INTERVIEW_TYPES.systemDesignLLD]} />
                    </TabPanel>
                    <TabPanel value={tab} index={4}>
                        <RatingGraph data={ratings[INTERVIEW_TYPES.behavioral]} />
                    </TabPanel>
                </div>
            </div>
        </div>
    )
}

export default ProfileStat

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Typography } from '@mui/material'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import { INTERVIEW_TYPES, INSTANT_INTERVIEW_MATCH_TIMER } from '../../constants/vars'
import Navbar from '../Landing/Navbar/Navbar'
import InterviewType from './InterviewType.jsx'
import InstantStatusCard from './InstantStatusCard.jsx'
import EngineeringFacts from './EngineeringFacts.jsx'
import ButtonComp from '../utils/ButtonComp/ButtonComp'
import getIcon from '../../utils/getIcon'
import toastNotification from '../utils/Notification/Notification'
import { postInstantInterviewRequest } from '../../store/actions/instantInterview'
import './Instant.css'

const Instant = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { user } = useSelector((state) => state.userInfo)
    const {
        loading: instantInterviewLoading,
        status: instantInterviewStatus,
        meetingID,
    } = useSelector((state) => state.instantInterview)

    const [interviewType, setInterviewType] = useState(INTERVIEW_TYPES.systemDesign)
    const [isEligible, setIsEligible] = useState(false)

    useEffect(() => {
        if (user.yoe && user.verified && user.phoneVerified && !user.mockInterviewBan) {
            setIsEligible(true)
            return
        }

        if (user.mockInterviewBan) {
            toastNotification(
                'You have been banned from taking mock interviews at InterviewReady! Please connect with us at contact@interviewready.io if you think this is a mistake.'
            )
            history.push('/profile')
            return
        }

        if (!user.yoe) {
            toastNotification(
                'Please add your years of experience to schedule an interview!'
            )
            history.push('/profile')
            return
        }

        if (!user.verified) {
            toastNotification('Please verify your email to schedule an interview!')
            history.push('/profile')
            return
        }

        if (!user.phoneVerified) {
            toastNotification(
                'Please verify your mobile number to schedule an interview!'
            )
            history.push('/mobile-number')
            return
        }
    }, [user, history])

    const scheduleInstantInterview = () => {
        const data = {
            Type: interviewType,
            TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
        dispatch(postInstantInterviewRequest(data))
    }

    useEffect(() => {
        if (instantInterviewStatus === 'MATCHED') {
            setTimeout(() => {
                window.location.href = `${window.location.origin}/interview-verify/${meetingID}`
            }, 3000)
        }
    }, [instantInterviewStatus, meetingID])

    return !isEligible ? null : (
        <div className="instant">
            <Navbar isProfilePage={false} />
            <div className="instant__bodyWrapper">
                <div className="instant__type">
                    <Typography
                        variant="body1"
                        color="grey.dark"
                        align="center"
                        fontSize="1.25rem"
                        lineHeight="2.5rem"
                        letterSpacing="-0.025em"
                        fontWeight="600"
                        mt="0.5rem"
                        mb="0.8rem"
                    >
                        Select the type of Interview
                    </Typography>
                    <div className="instant__select">
                        <InterviewType
                            interviewType={interviewType}
                            setInterviewType={setInterviewType}
                        />
                    </div>
                </div>
                <div className="instant__timer">
                    <CountdownCircleTimer
                        key={instantInterviewLoading ? 'abc' : 'xyz'}
                        isPlaying={instantInterviewLoading}
                        duration={INSTANT_INTERVIEW_MATCH_TIMER}
                        colors={['#0071bc']}
                        size={250}
                        strokeWidth={20}
                    >
                        {({ remainingTime }) => (
                            <h1 className="instant__timerNumber">{remainingTime}</h1>
                        )}
                    </CountdownCircleTimer>
                    {instantInterviewStatus !== 'MATCHED' && (
                        <ButtonComp
                            handleOnClick={scheduleInstantInterview}
                            disabled={instantInterviewLoading}
                            size="xl"
                        >
                            Find Match{' '}
                            <span className="instant__buttonIcon">
                                {getIcon('INTERVIEWREADY_WHITE')}
                            </span>
                        </ButtonComp>
                    )}
                </div>
                <div className="instant__status">
                    <InstantStatusCard />
                </div>
            </div>
            {instantInterviewLoading && <EngineeringFacts />}
        </div>
    )
}

export default Instant

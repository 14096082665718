import * as actionTypes from '../actionTypes'

const initialState = {
    success: false,
    loading: false,
    error: null,
    isFeedbackSubmitted: false,
}

const platformRatingReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_FEEDBACK_SUBMITTED:
            return {
                ...state,
                isFeedbackSubmitted: action.payload,
            }

        case actionTypes.SAVE_PLATFORM_RATING_REQUEST:
            return { ...state, loading: true, error: null, success: false }

        case actionTypes.SAVE_PLATFORM_RATING_SUCCESS:
            return {
                ...state,
                loading: true,
                error: null,
                success: true,
                isFeedbackSubmitted: false,
            }

        case actionTypes.SAVE_PLATFORM_RATING_FAIL:
            return { ...state, error: action.payload, loading: false, success: false }

        default:
            return state
    }
}

export default platformRatingReducer

const WHY_US_DATA = [
    {
        id: '1',
        icon: 'DUAL_ROLE',
        title: 'Intelligent Matches',
        content:
            'We value your time and effort investment. Our algorithm is designed to match you with a sufficiently experienced interviewer, who can test and evaluate you accurately.',
    },
    {
        id: '2',
        icon: 'LOW_RISK',
        title: 'Instant Feedback',
        content:
            'Get feedback on design, problem solving and communication skills right after the interview.',
    },
    {
        id: '3',
        icon: 'TRACK_PROGRESS',
        title: 'Unlimited Interviews',
        content:
            "Life doesn't have a retry button, but mock interviews do. Practice till you are interview ready.",
    },
]

export default WHY_US_DATA

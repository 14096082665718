import { Typography, Tooltip } from '@mui/material'

import getIcon from '../../../utils/getIcon'
import './CheckList.css'

const CheckListItem = ({ id, doneText, notDoneText, isDone, handleOnClick }) => {
    const onClickHandler = () => {
        if (isDone) return
        handleOnClick()
    }

    return (
        <div
            className={`checkListItem ${id === 3 && 'lastItem'} ${
                isDone ? 'done' : 'not-done'
            }`}
        >
            <div className="checkListItem__iconAndLineWrapper">
                <Tooltip
                    title={isDone ? 'Done' : `Click to ${notDoneText.toLowerCase()}`}
                    placement="left"
                >
                    <div className="checkListItem__icon" onClick={onClickHandler}>
                        {getIcon(isDone ? 'TICK' : 'EXCLAMATION')}
                    </div>
                </Tooltip>
                <div className="checkListItem__line"></div>
            </div>
            <div className="checkListItem__textWrapper">
                <Typography
                    variant="body1"
                    color="grey.main"
                    mt="0.2rem"
                    fontSize="1rem"
                    lineHeight="1.5rem"
                >
                    {isDone ? doneText : notDoneText}
                </Typography>
            </div>
        </div>
    )
}

export default CheckListItem

import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'

import { INTERVIEW_TYPES } from '../../constants/vars'
import getIcon from '../../utils/getIcon'
import './Instant.css'

const InterviewType = ({ interviewType, setInterviewType }) => {
    const { loading: instantInterviewLoading } = useSelector(
        (state) => state.instantInterview
    )

    const handleInterviewTypeSelect = (selectedInterviewType) => {
        if (instantInterviewLoading) return
        setInterviewType(selectedInterviewType)
    }

    return (
        <div className="interviewType__wrapper">
            {Object.keys(INTERVIEW_TYPES).map((key) => (
                <div
                    key={key}
                    onClick={() => {
                        handleInterviewTypeSelect(INTERVIEW_TYPES[key])
                    }}
                    className={`interviewType__card ${
                        interviewType === INTERVIEW_TYPES[key] ? 'active' : ''
                    }`}
                >
                    {interviewType === INTERVIEW_TYPES[key]
                        ? getIcon(INTERVIEW_TYPES[key])
                        : getIcon(INTERVIEW_TYPES[key] + '_grey')}

                    <Typography
                        variant="h1"
                        color="grey.dark"
                        align="center"
                        letterSpacing="-0.025em"
                        fontSize="1.125rem"
                        lineHeight="1.75rem"
                        fontWeight="400"
                    >
                        {INTERVIEW_TYPES[key]}
                    </Typography>
                </div>
            ))}
        </div>
    )
}

export default InterviewType

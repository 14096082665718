import React from 'react'

import './Logo.css'

const LogoIcon = () => {
    return (
        <svg
            className="logoIcon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 203.62 160.51"
        >
            <path
                className="cls-1"
                d="M73.66,167.69H39.26a25.35,25.35,0,0,1-25.33-25.32V81.77a20,20,0,1,1,39.91,0v46H73.66a20,20,0,0,1,0,39.91Z"
                transform="translate(-13.93 -7.18)"
            />
            <path
                className="cls-1"
                d="M192.05,167.69H157.64a20,20,0,0,1,0-39.91h19.82v-46a20,20,0,1,1,39.91,0v60.6A25.35,25.35,0,0,1,192.05,167.69Z"
                transform="translate(-13.93 -7.18)"
            />
            <circle className="cls-2" cx="183.49" cy="20.13" r="20.13" />
            <circle className="cls-2" cx="20.13" cy="20.13" r="20.13" />
            <path
                className="cls-1"
                d="M61.56,40.21,89.4,89.79a1.72,1.72,0,0,0,2.75.34l75.67-80a1.72,1.72,0,0,0-2.24-2.58L95.66,57.29a1.7,1.7,0,0,1-1.93,0L64,37.93A1.72,1.72,0,0,0,61.56,40.21Z"
                transform="translate(-13.93 -7.18)"
            />
            <path
                className="cls-2"
                d="M165.43,100.87v14.87h-7.8a32,32,0,0,0-25,51.94h-34a32,32,0,0,0-25-51.94H65.87V100.87Z"
                transform="translate(-13.93 -7.18)"
            />
        </svg>
    )
}

export default LogoIcon

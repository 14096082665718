import Lottie from 'react-lottie'

const LottieComp = ({ animationJSON }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationJSON,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    return <Lottie options={defaultOptions} />
}

export default LottieComp

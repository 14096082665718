import React from 'react'
import { Typography } from '@mui/material'

import getIcon from '../../../utils/getIcon'
import './Flow.css'

const FlowItem = ({ id, title, content, icon, isAOS = true }) => {
    return (
        <div className="flowItem" data-aos={isAOS ? 'zoom-in' : ''}>
            <div className="flowItem__iconAndLineWrapper">
                <div className="flowItem__icon">{getIcon(icon)}</div>
                {id !== 4 && <div className="flowItem__line"></div>}
            </div>
            <div className="flowItem__textWrapper">
                <Typography
                    variant="subtitle1"
                    color="grey.medium"
                    letterSpacing="-0.025em"
                    fontSize="0.9rem"
                    lineHeight="1.4rem"
                    fontWeight="500"
                    typography={{ textTransform: 'uppercase' }}
                >
                    Step {id}
                </Typography>
                <Typography
                    variant="h1"
                    color="grey.dark"
                    letterSpacing="-0.025em"
                    fontSize="1.125rem"
                    lineHeight="1.75rem"
                    fontWeight="500"
                >
                    {title}
                </Typography>
                <Typography
                    variant="body1"
                    color="grey.main"
                    mt="0.2rem"
                    fontSize="1rem"
                    lineHeight="1.5rem"
                >
                    {content}
                </Typography>
            </div>
        </div>
    )
}

export default FlowItem

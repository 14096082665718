import * as actionTypes from '../actionTypes'

const initialState = {
    allMeetings: [],
    expiredMeetings: [],
    activeMeetings: [],
    loading: false,
    error: null,
}

const meetingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ALL_MEETINGS_REQUEST:
            return { ...state, loading: true, error: null }

        case actionTypes.GET_ALL_MEETINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                allMeetings: action.payload.allMeetings,
                expiredMeetings: action.payload.expiredMeetings,
                activeMeetings: action.payload.activeMeetings,
            }

        case actionTypes.GET_ALL_MEETINGS_FAIL:
            return { ...state, loading: false, error: action.payload }

        default:
            return state
    }
}

export default meetingsReducer

import { Typography } from '@mui/material'

import { ENGINEERING_FACTS } from '../../../constants/engineeringFacts'
import './loader.css'

const Loader = () => {
    const fact = ENGINEERING_FACTS[Math.floor(Math.random() * ENGINEERING_FACTS.length)]

    return (
        <div className="loader">
            <div className="loader__factWrapper">
                <Typography
                    variant="h1"
                    color="grey.dark"
                    align="center"
                    letterSpacing="-0.025em"
                    fontSize="1.125rem"
                    lineHeight="1.75rem"
                    fontWeight="500"
                    className="loader__fact"
                >
                    {fact}
                </Typography>
            </div>
            <div className="sk-cube-grid">
                <div className="sk-cube sk-cube1"></div>
                <div className="sk-cube sk-cube2"></div>
                <div className="sk-cube sk-cube3"></div>
                <div className="sk-cube sk-cube4"></div>
                <div className="sk-cube sk-cube5"></div>
                <div className="sk-cube sk-cube6"></div>
                <div className="sk-cube sk-cube7"></div>
                <div className="sk-cube sk-cube8"></div>
                <div className="sk-cube sk-cube9"></div>
            </div>
        </div>
    )
}

export default Loader

import React, { useState, useEffect } from 'react'
import { Typography, IconButton } from '@mui/material'

import getIcon from '../../../utils/getIcon'
import ButtonComp from '../ButtonComp/ButtonComp'
import './CookiesBanner.css'

const CookiesBanner = () => {
    const [cookieBanner, setCookieBanner] = useState(true)

    useEffect(() => {
        const cookieBanner = localStorage.getItem('cookies_accepted')
        setCookieBanner(cookieBanner === 'true' ? true : false)
    }, [])

    return (
        !cookieBanner && (
            <div className="cookiesBanner">
                <div className="cookiesBanner__container">
                    <div className="cookiesBanner__icon">{getIcon('PAINT')}</div>
                    <Typography variant="body1" color="white" fontSize="1.1rem" mr="auto">
                        Hey! Just letting you know that we use cookies to ensure you have
                        the best experience possible on InterviewReady.
                    </Typography>
                    <ButtonComp
                        variant="secondary"
                        size="sm"
                        handleOnClick={() => {
                            window.open(
                                'https://get.interviewready.io/privacy-policy/',
                                '_blank'
                            )
                        }}
                    >
                        View Privacy Policy
                    </ButtonComp>
                    <IconButton
                        className="cookiesBanner__crossIcon"
                        onClick={() => {
                            localStorage.setItem('cookies_accepted', 'true')
                            setCookieBanner(true)
                        }}
                    >
                        {getIcon('CROSS')}
                    </IconButton>
                </div>
            </div>
        )
    )
}

export default CookiesBanner

import React from 'react'

import './Logo.css'

const LogoText = () => {
    return (
        <svg
            className="logoText"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 600.97 53.33"
        >
            <path
                className="text"
                d="M106,48.63V15.7h4.51V48.63Z"
                transform="translate(-7.13 -7.36)"
            />
            <path
                className="text"
                d="M125.52,15.37h1.17l21,23.34v-23h4.52V49h-1.37L130,25.72V48.63h-4.47Z"
                transform="translate(-7.13 -7.36)"
            />
            <path
                className="text"
                d="M188.46,20.22h-9.74V48.63h-4.56V20.22h-9.79V15.7h24.09Z"
                transform="translate(-7.13 -7.36)"
            />
            <path
                className="text"
                d="M205.25,20.22v9.69h11.48v4.52H205.25v9.69H221v4.51H200.78V15.7H221v4.52Z"
                transform="translate(-7.13 -7.36)"
            />
            <path
                className="text"
                d="M259.82,48.63h-5.55l-9.65-13.5h-6.11v13.5H234V15.7h13.08a9.8,9.8,0,0,1,9.69,9.69,10.3,10.3,0,0,1-6.92,9.41Zm-14-18c4.42,0,6.49-2.31,6.49-5.23s-2.07-5.17-6.49-5.17h-7.29v10.4Z"
                transform="translate(-7.13 -7.36)"
            />
            <path
                className="text"
                d="M283.43,49c-5-11.15-9.83-22.11-14.82-33.26h4.89L284,39.27,294.49,15.7h4.94L284.75,49Z"
                transform="translate(-7.13 -7.36)"
            />
            <path
                className="text"
                d="M310.76,48.63V15.7h4.52V48.63Z"
                transform="translate(-7.13 -7.36)"
            />
            <path
                className="text"
                d="M335,20.22v9.69h11.48v4.52H335v9.69h15.76v4.51H330.56V15.7h20.23v4.52Z"
                transform="translate(-7.13 -7.36)"
            />
            <path
                className="text"
                d="M405.36,15.61,394.87,49H393L382.68,25.3,372.29,49H370.5L360.06,15.7h4.61l7.24,22.91,10.16-23.24h1.23l10.16,23.24,7.19-23Z"
                transform="translate(-7.13 -7.36)"
            />
            <path
                className="cls-1"
                d="M458.94,48.63h-5.55l-9.65-13.5h-6.11v13.5h-4.47V15.7h13.07a9.81,9.81,0,0,1,9.7,9.69A10.3,10.3,0,0,1,449,34.8Zm-14-18c4.42,0,6.49-2.31,6.49-5.23s-2.07-5.17-6.49-5.17h-7.29v10.4Z"
                transform="translate(-7.13 -7.36)"
            />
            <path
                className="cls-1"
                d="M475.87,20.22v9.69h11.48v4.52H475.87v9.69h15.76v4.51H471.4V15.7h20.23v4.52Z"
                transform="translate(-7.13 -7.36)"
            />
            <path
                className="cls-1"
                d="M517,15.37c5,11.15,9.83,22.11,14.82,33.26h-4.94L524,42.14H508.84c-.89,2-2,4.42-2.91,6.49H501l14.63-33.26ZM522,37.63l-5.6-12.57-5.55,12.57Z"
                transform="translate(-7.13 -7.36)"
            />
            <path
                className="cls-1"
                d="M543,48.63V15.7h11c9.74,0,16,6.78,16,16.52,0,9.22-6.21,16.41-16,16.41Zm4.47-4.51h6.54c6.54,0,11.38-5.23,11.38-11.9s-4.79-11.95-11.38-11.95h-6.54Z"
                transform="translate(-7.13 -7.36)"
            />
            <path
                className="cls-1"
                d="M608.1,15.7l-12,16.7V48.63h-4.52V32.4c-4-5.45-8-11.19-12-16.7h5.6l8.7,12.09,8.7-12.09Z"
                transform="translate(-7.13 -7.36)"
            />
            <path
                className="cls-1"
                d="M27,60.7H15.55a8.43,8.43,0,0,1-8.42-8.42V32.15a6.63,6.63,0,0,1,13.26,0V47.44H27A6.63,6.63,0,0,1,27,60.7Z"
                transform="translate(-7.13 -7.36)"
            />
            <path
                className="cls-1"
                d="M66.31,60.7H54.88a6.63,6.63,0,0,1,0-13.26h6.59V32.15a6.63,6.63,0,0,1,13.26,0V52.28A8.43,8.43,0,0,1,66.31,60.7Z"
                transform="translate(-7.13 -7.36)"
            />
            <circle className="cls-2" cx="60.97" cy="6.69" r="6.69" />
            <circle className="cls-2" cx="6.69" cy="6.69" r="6.69" />
            <path
                className="cls-1"
                d="M23,18.34l9.25,16.48a.57.57,0,0,0,.91.11L58.26,8.35a.57.57,0,0,0-.74-.86L34.29,24a.59.59,0,0,1-.65,0l-9.87-6.45A.57.57,0,0,0,23,18.34Z"
                transform="translate(-7.13 -7.36)"
            />
            <path
                className="cls-2"
                d="M57.47,38.5v4.94H54.88a10.62,10.62,0,0,0-8.3,17.26H35.28A10.62,10.62,0,0,0,27,43.44H24.39V38.5Z"
                transform="translate(-7.13 -7.36)"
            />
        </svg>
    )
}

export default LogoText

import { useEffect, useState } from 'react'
import * as actionTypes from '../../../store/actionTypes'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
    FormControl,
    Select,
    MenuItem,
    DialogTitle,
    InputLabel,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    Typography,
    LinearProgress,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'

import ProfileImage from './ProfileImage/ProfileImage'
import ButtonComp from '../../utils/ButtonComp/ButtonComp'
import toastNotification from '../../utils/Notification/Notification'
import Axios from '../../../service/axios'
import { toggleProfileModal } from '../../../store/actions/ui'
import './ProfileEdit.css'

const ProfileEdit = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { user } = useSelector((state) => state.userInfo)
    const { profileModal } = useSelector((state) => state.ui)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [experience, setExperience] = useState('')
    const [loading, setLoading] = useState(false)
    const [image, setImage] = useState({
        currentFile: '',
        previewImage: '',
    })

    useEffect(() => {
        if (profileModal) {
            setFirstName(user.firstName)
            setLastName(user.lastName)
            setPhone(user.phone ? user.phone : 'XXXX-XXXX-XXXX')
            setEmail(user.email)
            setExperience(user.yoe)
        }
    }, [profileModal, user.firstName, user.lastName, user.phone, user.yoe, user.email])

    const updateProfile = (e) => {
        e.preventDefault()

        if (email.length === 0) {
            toastNotification("Error: Email address can't be empty")
            return
        }

        if (firstName.length === 0 || experience.length === 0) {
            toastNotification("Error: Field's can't be empty")
            return
        }

        setLoading(true)
        const formData = new FormData()
        formData.append('firstName', firstName)
        formData.append('lastName', lastName)
        formData.append('email', email)
        formData.append('experience', experience)
        formData.append('image', image.currentFile)

        Axios.patch('/api/v1/users/update-user', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((res) => {
                dispatch(toggleProfileModal())
                setEmail('')
                setFirstName('')
                setLastName('')
                setImage({
                    currentFile: '',
                    previewImage: '',
                })
                dispatch({
                    type: actionTypes.SAVE_USER,
                    payload: res.data,
                })
                toastNotification('Profile Updated')
                setLoading(false)
            })
            .catch((err) => {
                dispatch(toggleProfileModal())
                setLoading(false)
                err.response.data
                    ? toastNotification(`Error: ${err.response.data}`)
                    : toastNotification(`Error: ${err}`)
            })
    }

    const removeProfileImage = () => {
        setLoading(true)
        Axios.post('/api/v1/users/delete-user-image')
            .then((res) => {
                dispatch(toggleProfileModal())
                dispatch({
                    type: actionTypes.SAVE_USER,
                    payload: res.data,
                })
                toastNotification('Profile Image Removed')
                setLoading(false)
                setImage({
                    currentFile: '',
                    previewImage: '',
                })
            })
            .catch((err) => {
                setLoading(false)
                err.response.data
                    ? toastNotification(`Error: ${err.response.data}`)
                    : toastNotification(`Error: ${err}`)
            })
    }

    const goToVerificationPage = () => {
        history.push('/mobile-number')
    }

    return (
        <>
            <ButtonComp
                variant="secondary"
                handleOnClick={() => dispatch(toggleProfileModal())}
                modifyClass="profileEdit__button"
            >
                Edit Profile
            </ButtonComp>

            <Dialog open={profileModal}>
                {loading && <LinearProgress />}
                <div className="profileEdit">
                    <DialogTitle>Update Profile</DialogTitle>
                    <DialogContent>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignSelf="center"
                        >
                            <ProfileImage
                                image={image}
                                setImage={setImage}
                                removeProfileImage={removeProfileImage}
                            />
                        </Grid>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justifyContent="space-between"
                        >
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    margin="normal"
                                    label="First Name"
                                    type="text"
                                    value={firstName}
                                    onChange={(e) => {
                                        setFirstName(e.target.value)
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    margin="normal"
                                    label="Last Name"
                                    type="text"
                                    value={lastName}
                                    onChange={(e) => {
                                        setLastName(e.target.value)
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            margin="normal"
                            label="Email Address"
                            type="text"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                            fullWidth
                        />

                        <div className="profileEdit__phoneWrapper">
                            <Typography
                                variant="h3"
                                color="grey.dark"
                                fontSize="1rem"
                                fontWeight="400"
                            >
                                Phone Number: {phone}
                            </Typography>
                            <IconButton onClick={goToVerificationPage}>
                                <EditIcon color="primary" />
                            </IconButton>
                        </div>

                        <FormControl fullWidth margin="normal">
                            <InputLabel id="demo-simple-select-label">
                                Experience
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Experience"
                                value={experience}
                                onChange={(e) => setExperience(e.target.value)}
                            >
                                <MenuItem value="0-2 years">0-2 years</MenuItem>
                                <MenuItem value="2-6 years">2-6 years</MenuItem>
                                <MenuItem value="6+ years">6+ years</MenuItem>
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <ButtonComp
                            variant="secondary"
                            handleOnClick={() => dispatch(toggleProfileModal())}
                        >
                            Cancel
                        </ButtonComp>
                        <ButtonComp
                            disabled={loading}
                            handleOnClick={updateProfile}
                            color="primary"
                        >
                            Update
                        </ButtonComp>
                    </DialogActions>
                </div>
            </Dialog>
        </>
    )
}

export default ProfileEdit

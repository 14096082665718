import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './ScheduleAnotherMeetingModal.css'
import ButtonComp from '../../utils/ButtonComp/ButtonComp'
import { Dialog, Typography } from '@mui/material'

const ScheduleAnotherMeetingModal = () => {
    const history = useHistory()
    const { scheduleAnother } = useSelector((state) => state.ui)
    const toggleSchedulerOnProfile = (value) => {
        history.push({
            pathname: '/profile',
            state: { schedulerToggle: value },
        })
    }
    return (
        <Dialog open={scheduleAnother} className="scheduleanother__modal">
            <div className="scheduleanother__schedulerBox">
                <div className="scheduleanother__paddingBox">
                    <Typography
                        variant="h6"
                        color="grey.dark"
                        letterSpacing="-0.025em"
                        fontWeight="400"
                        mb="2rem"
                        className="mobile-heading"
                    >
                        Looks like your meeting has expired. Would you like to schedule
                        another one?
                    </Typography>
                    <div className="scheduleanother__actionButtons">
                        <ButtonComp
                            variant="secondary"
                            handleOnClick={() => toggleSchedulerOnProfile(false)}
                            modifyClass="buttonComp__mobile--hidden"
                        >
                            No, take me back.
                        </ButtonComp>
                        <ButtonComp
                            handleOnClick={() => toggleSchedulerOnProfile(true)}
                            modifyClass="buttonComp__mobile--hidden"
                        >
                            <span data-cy="schedule-interview">Yes, of course!</span>
                        </ButtonComp>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default ScheduleAnotherMeetingModal

import jwtDecode from 'jwt-decode'

import * as actionTypes from '../actionTypes'
import { INTERVIEW_TYPES } from '../../constants/vars'

const initialState = {
    userID: null,
    token: null,
    events: {},
    video: true,
    audio: true,
    loading: false,
    shareScreen: false,
    userShareScreen: false,
    unreadMessageCount: 0,
    chat: [],
    isTester: false,
    freezedScreen: false,
    testerId: 0,
    showRoleModal: false,
    roomUsersCount: 1,
    interviewStartTime: Date.now(),
    isFlip: false,
    drawingBoard: false,
    roleFlipTime: Date.now(),
    isInterviewEnded: false,
    questions: [],
    isBackgroundBlur: false,
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ENTER_INTERVIEW:
            const decodedToken = jwtDecode(action.payload.token)

            return {
                ...state,
                userID: action.payload.userID,
                token: action.payload.token,
                events: action.payload.events,
                isTester: Number(decodedToken.tester) === Number(action.payload.userID),
                interviewStartTime: decodedToken.start_time * 1000,
                isInterviewEnded: false,
                questions: decodedToken.questions,
                isBehavioralInterview: INTERVIEW_TYPES.behavioral === decodedToken.type,
            }

        case actionTypes.TOGGLE_VIDEO:
            return { ...state, video: !state.video }

        case actionTypes.TOGGLE_AUDIO:
            return { ...state, audio: !state.audio }

        case actionTypes.TOGGLE_SHARE_SCREEN:
            return { ...state, shareScreen: !state.shareScreen }

        case actionTypes.TOGGLE_USER_SHARE_SCREEN:
            return { ...state, userShareScreen: !state.userShareScreen }

        case actionTypes.TOGGLE_LOADING:
            return { ...state, loading: !state.loading }

        case actionTypes.UNREAD_MESSAGE_COUNT:
            return {
                ...state,
                unreadMessageCount: action.payload ? state.unreadMessageCount + 1 : 0,
            }

        case actionTypes.CHAT_MESSAGE_PUSH:
            return { ...state, chat: [...state.chat, action.payload] }

        case actionTypes.CHAT_INITIAL_FETCH:
            return {
                ...state,
                chat: [...action.payload],
            }

        case actionTypes.ROOM_USERS_COUNT:
            return { ...state, roomUsersCount: action.payload }

        case actionTypes.TOGGLE_TESTER:
            return { ...state, isTester: !state.isTester }

        case actionTypes.TOGGLE_BACKGROUND_BLUR:
            return { ...state, isBackgroundBlur: !state.isBackgroundBlur }

        case actionTypes.TOGGLE_FLIP_ROLE:
            return {
                ...state,
                isFlip: Boolean(action.payload),
                roleFlipTime: action.payload,
            }

        case actionTypes.TOGGLE_FREEZED_SCREEN:
            return { ...state, freezedScreen: !state.freezedScreen }

        case actionTypes.MEET_START_TIME:
            return { ...state, meetStartTime: action.payload }

        case actionTypes.SHOW_ROLE_MODAL:
            return { ...state, showRoleModal: !state.showRoleModal }

        case actionTypes.TESTER_ID:
            return { ...state, testerId: action.payload }

        case actionTypes.SET_INTERVIEW_ENDED:
            return { ...state, isInterviewEnded: true }
        case actionTypes.TOGGLE_DRAWING_BOARD:
            return { ...state, drawingBoard: action.payload }

        case actionTypes.SET_INTERVIEW_QUESTION: {
            const questionData = action.payload
            const questionToPopulateIndex = state.questions.findIndex(
                (questionID) => questionID === questionData.id
            )
            let populatedQuestions = state.questions
            populatedQuestions[questionToPopulateIndex] = questionData
            return { ...state, questions: populatedQuestions }
        }

        default:
            return state
    }
}

export default userReducer

import { useRef, createContext } from 'react'

const ProfileContext = createContext()

const ProfileContextProvider = ({ children }) => {
    const profileRef = useRef(null)
    const ratingRef = useRef(null)
    const historyRef = useRef(null)
    const slotsRef = useRef(null)

    return (
        <ProfileContext.Provider value={{ profileRef, ratingRef, historyRef, slotsRef }}>
            {children}
        </ProfileContext.Provider>
    )
}

export { ProfileContext, ProfileContextProvider }

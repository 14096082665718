import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'

import ButtonComp from '../utils/ButtonComp/ButtonComp'
import FlowItem from '../Landing/Flow/FlowItem'
import { INSTANT_INTERVIEW_FLOW_DATA } from '../../constants/flowData'

const InstantInstructions = ({ isOpen, setIsOpen }) => {
    const handleClose = () => {
        setIsOpen(false)
    }

    return (
        <Dialog open={isOpen} onClose={handleClose} scroll="paper">
            <DialogTitle>Instant Interviews Instructions</DialogTitle>
            <DialogContent dividers>
                <Grid container className="instantInstructions__stepsWrapper">
                    {INSTANT_INTERVIEW_FLOW_DATA.map((data) => (
                        <Grid item key={data.id}>
                            <FlowItem {...data} isAOS={false} />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonComp handleOnClick={handleClose}>Okay! Got it.</ButtonComp>
            </DialogActions>
        </Dialog>
    )
}

export default InstantInstructions

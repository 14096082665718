import { useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import { Avatar, IconButton, Stack } from '@mui/material'
import CameraIcon from '@mui/icons-material/PhotoCamera'
import CancelIcon from '@mui/icons-material/Cancel'

import toastNotification from '../../../utils/Notification/Notification'
import handleImageCompress from '../../../../utils/imageCompressor'
import { PROFILE_IMAGES_BUCKET_BASE_URL } from '../../../../constants/urls'

const Input = styled('input')({
    display: 'none',
})

const ProfileImage = ({ image, setImage, removeProfileImage }) => {
    const { user } = useSelector((state) => state.userInfo)

    const selectImage = (event) => {
        let acceptedFile = event.target?.files
        if (acceptedFile.length === 1) {
            // validating the file extension
            const allowedExt = ['jpeg', 'jpg', 'png']
            const fileExt = acceptedFile[0].name.split('.').pop().toLowerCase()
            if (!allowedExt.includes(fileExt)) {
                toastNotification(
                    'Error: Invalid Image Chosen! Only JPEG, JPG & PNG allowed.'
                )
                return
            }
            handleImageCompress(acceptedFile[0], setImage)
        }
    }

    return (
        <Stack direction="row" alignItems="center" className="profileImage">
            <Avatar
                alt="User Image"
                sx={{
                    width: 80,
                    height: 80,
                    bgcolor: (theme) => theme.palette.indigo[600],
                }}
                src={
                    image.previewImage ||
                    (user?.image !== ''
                        ? `${PROFILE_IMAGES_BUCKET_BASE_URL}/${user?.image}`
                        : null)
                }
            >
                {user.firstName?.[0]}
                {user.lastName?.[0]}
            </Avatar>
            <label htmlFor="icon-button-file">
                <Input
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    onChange={(e) => {
                        selectImage(e)
                    }}
                />
                <IconButton
                    className="profileImage__EditIcon"
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                >
                    <CameraIcon color="primary" />
                </IconButton>
            </label>
            {user?.image !== '' && (
                <IconButton
                    onClick={removeProfileImage}
                    className="profileImage__EditIcon"
                    color="primary"
                    aria-label="remove picture"
                    component="span"
                >
                    <CancelIcon color="primary" />
                </IconButton>
            )}
        </Stack>
    )
}

export default ProfileImage

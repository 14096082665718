import { useEffect, useState } from 'react'
import { Typography } from '@mui/material'

import { ENGINEERING_FACTS } from '../../constants/engineeringFacts'

const EngineeringFacts = () => {
    const [fact, setFact] = useState(
        ENGINEERING_FACTS[Math.floor(Math.random() * ENGINEERING_FACTS.length)]
    )

    useEffect(() => {
        const intervalID = setInterval(() => {
            setFact(
                ENGINEERING_FACTS[Math.floor(Math.random() * ENGINEERING_FACTS.length)]
            )
        }, 16000)

        return () => {
            clearInterval(intervalID)
        }
    }, [])

    return (
        <div className="engineeringFacts">
            <Typography
                variant="h1"
                color="grey.dark"
                align="center"
                letterSpacing="-0.025em"
                fontSize="1.2rem"
                lineHeight="1.85rem"
                fontWeight="500"
                className="engineeringFacts__text"
            >
                {fact}
            </Typography>
        </div>
    )
}

export default EngineeringFacts

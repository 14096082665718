export const FEEDBACK_AUTOSAVE_INTERVAL_IN_MILLISECONDS = 3000
export const FEEDBACK_COMMENT_MIN_LENGTH = 50
export const FEEDBACK_COMMENT_MAX_LENGTH = 1000
export const CHAT_MESSAGE_LENGTH = 250
export const OTP_LOCAL_STORAGE_FLAG = 'as8f67a9s877zxc1'
export const OTP_RESENDING_TIMER = 1 * 60 * 1000 // 1 minute
export const OTP_DISABLED_TIME_IN_HOURS = 1 // 1 hour
export const ONE_ROLE_TIME_SLOT_IN_MILLISECONDS = 45 * 60 * 1000 // 45 minutes
export const INSTANT_INTERVIEW_REQUEST_TIMER = 5 * 60 * 1000 // 5 minutes
export const INSTANT_INTERVIEW_MATCH_TIMER = 30 // 30 seconds
export const INTERNET_SPEED_CHECK_INTERVAL = 10 * 1000 // 10 seconds
export const INTERNET_SPEED_LATENCY_THRESHOLD = 400 // 400 milliseconds
export const CODE_COMPILE_RESULT_FETCH_INTERVAL = 5000 // 5 seconds

export const INTERVIEW_TYPES = {
    systemDesign: 'System Design Interview',
    systemDesignLLD: 'System Design Interview (Low-Level)',
    frontend: 'Front-end Interview',
    coding: 'Coding Interview',
    behavioral: 'Behavioral Interview',
}

export const CODE_LANGUAGE_MAPPING = {
    c_cpp: 'cpp',
    java: 'java',
    python: 'python3',
    javascript: 'javascript',
}

export const CODE_COMPILE_STATUS = {
    queued: 'Queued',
    processing: 'Processing',
    success: 'Success',
    error: 'Error',
    timeout: 'Time Limit Exceeded',
}

export const CODE_EDITOR_DEFAULT_MESSAGE =
    "NOTE: DON'T TAKE INPUTS IN YOUR CODE! PRE-PROVIDE EVERYTHING IN YOUR CODE ITSELF."

export const CANCELLATION_REASONS = [
    'Got another match',
    'I will not be able to attend',
    'Not prepared yet',
    'Was just exploring',
    'Other'
]
import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import ButtonComp from '../ButtonComp/ButtonComp'

const ConfirmModal = ({
    isOpen = false,
    setIsOpen,
    showCancel = true,
    handleOnConfirm = () => {
        console.log('Confirm Clicked!')
    },
    handleOnCancel = () => {
        console.log('Cancel Clicked!')
    },
    title = 'Are you sure?',
    body = 'This cannot be undone. Please be sure before proceeding.',
    confirmButtonText = 'Confirm',
}) => {
    const handleClose = () => {
        if (setIsOpen) {
            setIsOpen(false)
        }
    }

    return (
        <Dialog
            open={isOpen}
            onClose={() => {
                if (showCancel) handleClose()
            }}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{body}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {showCancel && (
                    <ButtonComp
                        variant="secondary"
                        handleOnClick={() => {
                            handleOnCancel()
                            handleClose()
                        }}
                    >
                        Cancel
                    </ButtonComp>
                )}
                <ButtonComp
                    handleOnClick={() => {
                        handleOnConfirm()
                        handleClose()
                    }}
                >
                    {confirmButtonText}
                </ButtonComp>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmModal

export const ENGINEERING_FACTS = [
    'DevOps is a more sought out skill by recruiters than machine learning for the year 2022.',
    'JavaScript is both the most loved and hated programming language amongst developers.',
    'Python and Django are popular for projects which last less than 3 months.',
    'Some NoSQL databases are written on top of SQL databases.',
    'The Amazon S3 internal architecture is not public, but the Ceph file system is...',
    'Developers value company culture more than its tech stack.',
    "FANG was an acronym coined by Jim Cramer, the television host of CNBC's Mad Money.",
    'Justin Uberti is the creator of the WebRTC standard.',
    'About 40% of computer programmers are self-taught.',
    'The Open source Initiative was founded in February 1998.',
    '"Stop the world" garbage collection is a common reason for thundering herds, leading to application crashes.',
    'Consistent Hashing does not improve the consistency of a system.',
    'In an SQL statement, the FROM clause runs first and the SELECT clause runs last.',
    'ElasticSearch is used for text searching in documents.',
    'ATS resume parsers do not select or reject candidates. Humans do.',
    'The first Gigabyte drive cost $40,000.',
    'About 90% of the World’s currency exists in Database records.',
    '60% of the all processing power is spent on sorting things.',
    'The first computer “bug” was an actual real-life bug.',
]

import * as actionTypes from '../actionTypes'

const initialState = {
    chatData: [],
    loading: false,
    error: null,
}

const chatReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_MEETING_CHAT_REQUEST:
            return { ...state, loading: true, error: null }

        case actionTypes.GET_MEETING_CHAT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                chatData: action.payload,
            }

        case actionTypes.GET_MEETING_CHAT_FAIL:
            return { ...state, loading: false, error: action.payload }

        default:
            return state
    }
}

export default chatReducer

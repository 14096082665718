import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Select, InputLabel, FormControl, MenuItem } from '@mui/material'

import toastNotification from '../../utils/Notification/Notification'
import { onLangChange, sendLang } from '../socket'
import { codeLanguageChange } from '../../../store/actions/code'

const CodeLanguage = () => {
    const dispatch = useDispatch()

    const { token, events } = useSelector((state) => state.meeting)
    const { codeLanguage } = useSelector((state) => state.code)

    useEffect(() => {
        onLangChange(events.onLang, (userID, lang) => {
            toastNotification('Code editor language changed.')
            dispatch(codeLanguageChange(lang))
        })
    }, [events.onLang, dispatch])

    const handleLanguageChange = (e) => {
        const newCodeLanguage = e.target.value
        sendLang(events.onLang, token, newCodeLanguage)
        dispatch(codeLanguageChange(newCodeLanguage))
    }

    return (
        <FormControl fullWidth size="small" color="secondary" className="codeLanguage">
            <InputLabel id="languageSelectID">Language</InputLabel>
            <Select
                labelId="languageSelectID"
                value={codeLanguage}
                label="Language"
                onChange={handleLanguageChange}
            >
                <MenuItem value={'c_cpp'}>CPP</MenuItem>
                <MenuItem value={'javascript'}>Javascript</MenuItem>
                <MenuItem value={'java'}>Java</MenuItem>
                <MenuItem value={'python'}>Python 3</MenuItem>
            </Select>
        </FormControl>
    )
}

export default CodeLanguage

import { Chip } from '@mui/material'
import toastNotification from '../../../utils/Notification/Notification'

const Banned = () => {
    const handleClick = () => {
        toastNotification(
            'You have been banned from taking mock interviews at InterviewReady! Please connect with us at contact@interviewready.io if you think this is a mistake.'
        )
    }

    return <Chip onClick={handleClick} label="Banned" color="error" />
}

export default Banned

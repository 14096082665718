import React from 'react'
import { Typography, Grid } from '@mui/material'

import FeatureItem from './FeatureItem'
import FEATURES_DATA from '../../../constants/featuresData'
import './Features.css'

const Features = () => {
    return (
        <section className="features">
            <Typography
                variant="h1"
                color="indigo.600"
                align="center"
                typography={{ textTransform: 'uppercase' }}
                fontWeight="600"
                letterSpacing="0.05em"
                fontSize="1rem"
                lineHeight="1.5rem"
            >
                Highlights
            </Typography>
            <Typography
                variant="h2"
                color="grey.dark"
                align="center"
                fontSize="2.25rem"
                lineHeight="2.5rem"
                letterSpacing="-0.025em"
                fontWeight="800"
                mt="0.5rem"
                className="features__heading"
            >
                The best practice platform
            </Typography>

            <Grid container spacing={6} rowSpacing={10} mt="1rem" data-aos="zoom-in">
                {FEATURES_DATA.map((data) => (
                    <Grid item lg={4} md={6} xs={12} key={data.id}>
                        <FeatureItem
                            icon={data.icon}
                            title={data.title}
                            content={data.content}
                        />
                    </Grid>
                ))}
            </Grid>
        </section>
    )
}

export default Features

import * as actionTypes from '../actionTypes'

const initialState = {
    schedulerModal: false,
    scheduleAnother : false,
    profileSidebar: false,
    loading: false,
    profileModal: false,
    questionsSidebar: false,
    codeResultPanel: false,
}

const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TOGGLE_SCHEDULER_MODAL:
            return { ...state, schedulerModal: !state.schedulerModal }

        case actionTypes.TOGGLE_PROFILE_SIDEBAR:
            return { ...state, profileSidebar: !state.profileSidebar }

        case actionTypes.TOGGLE_QUESTIONS_SIDEBAR:
            return { ...state, questionsSidebar: !state.questionsSidebar }

        case actionTypes.TOGGLE_CODE_RESULT_PANEL:
            return { ...state, codeResultPanel: action.payload }

        case actionTypes.TOGGLE_PROFILE_MODAL:
            return { ...state, profileModal: !state.profileModal }

        case actionTypes.SHOW_LOADING:
            return { ...state, loading: !state.loading }
        
        case actionTypes.TOGGLE_SCHEDULEANOTHERMEETING_MODAL:
            return {...state, scheduleAnother:!state.scheduleAnother}

        default:
            return state
    }
}

export default uiReducer

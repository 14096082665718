import * as actionTypes from '../actionTypes'
import Axios from '../../service/axios'
import toastNotification from '../../components/utils/Notification/Notification'

export const getAllMeetings = () => async (dispatch) => {
    try {
        dispatch({
            type: actionTypes.GET_ALL_MEETINGS_REQUEST,
        })

        const { data } = await Axios.get('api/v1/meeting')

        const allMeetings = data.meetings
        let expiredMeetings = allMeetings.filter(
            (meeting) => meeting.is_expired && meeting.cancelled_by === 0
        )
        expiredMeetings.sort(compare)
        const activeMeetings = allMeetings.filter((meeting) => !meeting.is_expired)

        dispatch({
            type: actionTypes.GET_ALL_MEETINGS_SUCCESS,
            payload: { allMeetings, expiredMeetings, activeMeetings },
        })
    } catch (err) {
        dispatch({
            type: actionTypes.GET_ALL_MEETINGS_FAIL,
            payload: err.message,
        })

        toastNotification("Can't fetch your meetings data! Try refreshing the page.")
    }
}

function compare(a, b) {
    if (a.start_time > b.start_time) {
        return -1
    }
    if (a.start_time < b.start_time) {
        return 1
    }
    return 0
}

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { Typography } from '@mui/material'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

import * as actions from '../../store/actions/otp'
import LogoText from '../utils/Logo/LogoText'
import LoaderScreen from '../utils/Loader/Loader'
import ButtonComp from '../utils/ButtonComp/ButtonComp'
import toastNotification from '../utils/Notification/Notification'
import './MobileUpdate.css'

const MobileUpdate = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const { user: userData } = useSelector((state) => state.userInfo)
    const {
        loading: loadingOTP,
        success: successOTP,
        phoneNumber,
        count,
    } = useSelector((state) => state.otp)

    const [number, setNumber] = useState('')

    useEffect(() => {
        setNumber(phoneNumber ? phoneNumber : userData.phone)
    }, [userData.phone, phoneNumber])

    useEffect(() => {
        dispatch(actions.setOTPStore())
    }, [dispatch])

    const sendOtp = (e) => {
        e.preventDefault()

        if (number.length === 0) {
            toastNotification("Error: Mobile number can't be empty!")
            return
        }

        dispatch(actions.sendOTP(number))
    }

    useEffect(() => {
        if (successOTP) {
            history.push('/mobile-verify')
        }
    }, [successOTP, history])

    return (
        <div className="custom-bg mobileUpdate">
            {loadingOTP ? (
                <LoaderScreen />
            ) : (
                <div className="mobileUpdate__wrapper">
                    <Link className="mobileUpdate__logoWrapper" to="/">
                        <LogoText />
                    </Link>

                    <Typography
                        variant="h1"
                        color="grey.dark"
                        align="center"
                        letterSpacing="-0.025em"
                        fontSize="1.3rem"
                        lineHeight="1.75rem"
                        fontWeight="500"
                        mb="1.8rem"
                    >
                        {userData.phone ? 'Update' : 'Add'} Your Mobile Number
                    </Typography>

                    <PhoneInput
                        containerClass="mobileUpdate__inputContainer"
                        inputClass="mobileUpdate__input"
                        country={'in'}
                        value={number}
                        placeholder="+91 XXXXX-XXXXX"
                        specialLabel="Mobile Number"
                        onChange={(phone) => {
                            setNumber(phone)
                        }}
                    />

                    <ButtonComp handleOnClick={sendOtp}>Send OTP</ButtonComp>
                    <Typography
                        variant="body1"
                        color="grey.dark"
                        align="right"
                        letterSpacing="-0.025em"
                        fontSize="0.9rem"
                        lineHeight="1.1rem"
                        fontWeight="500"
                        mt="0.3rem"
                        pr="0.5rem"
                    >
                        requests remaining: <b>{3 - count}</b>
                    </Typography>
                </div>
            )}
        </div>
    )
}

export default MobileUpdate

import React from 'react'
import { Typography } from '@mui/material'

import getIcon from '../../../utils/getIcon'
import './Features.css'

const FeatureItem = ({ icon, title, content }) => {
    return (
        <div className="featureItem">
            <div className="featureItem__icon">{getIcon(icon)}</div>
            <Typography
                variant="h1"
                color="grey.dark"
                align="center"
                letterSpacing="-0.025em"
                fontSize="1.125rem"
                lineHeight="1.75rem"
                fontWeight="500"
            >
                {title}
            </Typography>
            <Typography
                variant="body1"
                color="grey.main"
                align="center"
                mt="1.25rem"
                fontSize="1rem"
                lineHeight="1.5rem"
            >
                {content}
            </Typography>
        </div>
    )
}

export default FeatureItem

import { Fragment, useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import CheckListItem from './CheckListItem'
import Axios from '../../../service/axios'
import toastNotification from '../../utils/Notification/Notification'
import { toggleProfileModal } from '../../../store/actions/ui'
import './CheckList.css'

const CheckList = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { user } = useSelector((state) => state.userInfo)

    const [checkListData, setCheckListData] = useState([])

    const verifyEmail = () => {
        Axios.post('/api/v1/users/send-verify-email')
            .then(() => {
                toastNotification('Verification link has been sent to your email.')
            })
            .catch(() => {
                toastNotification('Something went wrong! Please try again.')
            })
    }

    const verifyMobile = useCallback(() => {
        history.push('/mobile-number')
    }, [history])

    const addYearsOfExperience = useCallback(() => {
        dispatch(toggleProfileModal())
    }, [dispatch])

    useEffect(() => {
        setCheckListData([
            {
                id: 1,
                doneText: 'Years of Experience Added',
                notDoneText: 'Add your years of experience',
                isDone: Boolean(user.yoe),
                handleOnClick: addYearsOfExperience,
            },
            {
                id: 2,
                doneText: 'Email Verified',
                notDoneText: 'Verify your email',
                isDone: user.verified,
                handleOnClick: verifyEmail,
            },
            {
                id: 3,
                doneText: 'Phone Verified',
                notDoneText: 'Verify your mobile number',
                isDone: user.phoneVerified,
                handleOnClick: verifyMobile,
            },
        ])
    }, [user, addYearsOfExperience, verifyMobile])

    return (
        <div className="checkList">
            {checkListData.map((data) => (
                <Fragment key={data.id}>
                    <CheckListItem {...data} />
                </Fragment>
            ))}
        </div>
    )
}

export default CheckList

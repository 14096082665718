import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.REACT_APP_SUPERDOM,
    withCredentials: true,
})

if (process.env.REACT_APP_ENV === 'DEV') {
    //paste your token here for development
    // instance.defaults.headers.common['Authorization'] = ''
}

export default instance

import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import LogoText from '../../utils/Logo/LogoText'
import toastNotification from '../../utils/Notification/Notification'
import Banned from './Banned/Banned'
import LogoIcon from '../../utils/Logo/LogoIcon'
import ButtonComp from '../../utils/ButtonComp/ButtonComp'
import AccountMenu from './AccountMenu/AccountMenu'
import Auth from '../../../middleware'
import Scheduler from './Scheduler/Scheduler'
import { toggleSchedulerModal } from '../../../store/actions/ui'
import './Navbar.css'

const Navbar = ({ isProfilePage = false }) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { user } = useSelector((state) => state.userInfo)
    const { profileSidebar } = useSelector((state) => state.ui)

    const onClickInstantInterview = () => {
        if (!user || !user?.email) {
            toastNotification(
                'Please login to get an instant interview! Redirecting to login...'
            )
            setTimeout(() => {
                redirectToLoginPage('instant')
            }, 3000)
        } else {
            history.push('/instant')
        }
    }

    const redirectToLoginPage = (page) => {
        window.location.href = `${process.env.REACT_APP_AUTHDOM}/signin?to=${window.location.href}${page}`
    }

    return (
        <>
            <Scheduler />
            <nav className="navbar">
                <Link
                    className={`navbar__logoWrapper ${isProfilePage && 'shiftLogo'} ${
                        profileSidebar && 'shiftLogoMore'
                    }`}
                    to="/"
                >
                    <LogoText />
                    <LogoIcon />
                </Link>
                <div className="navbar__itemsWrapper">
                    {user?.mockInterviewBan ? (
                        <Banned />
                    ) : (
                        <div>
                            <ButtonComp
                                handleOnClick={onClickInstantInterview}
                                modifyClass="buttonComp__mobile--hidden"
                            >
                                Instant Interview
                            </ButtonComp>
                            <ButtonComp
                                data-cy="schedule-now"
                                handleOnClick={() => {
                                    dispatch(toggleSchedulerModal())
                                }}
                                modifyClass="buttonComp__mobile--hidden"
                            >
                                Schedule Interview
                            </ButtonComp>
                        </div>
                    )}
                    {Auth.getAuth() ? (
                        <AccountMenu />
                    ) : (
                        <ButtonComp
                            data-cy="sign-in"
                            variant="secondary"
                            handleOnClick={() => redirectToLoginPage('profile')}
                        >
                            Sign in
                        </ButtonComp>
                    )}
                </div>
            </nav>
        </>
    )
}

export default Navbar

import { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    InputLabel,
    Select,
    MenuItem,
    Grid,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { MeetContext } from '../../Meeting/MeetContext'
import ButtonComp from '../../utils/ButtonComp/ButtonComp'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

const ConfigureDevices = ({ open = false, closeModal, devices }) => {
    const { videoDevice, setVideoDevice, audioDevice, setAudioDevice } =
        useContext(MeetContext)

    const [videoDeviceInput, setVideoDeviceInput] = useState(videoDevice)
    const [audioDeviceInput, setAudioDeviceInput] = useState(audioDevice)

    useEffect(() => {
        if (videoDeviceInput === undefined || videoDeviceInput === null) {
            for (var i = 0; i < devices.length; i++) {
                if (devices[i].kind === 'videoinput') {
                    setVideoDeviceInput(devices[i].deviceId)
                    setVideoDevice(devices[i].deviceId)
                    break
                }
            }
        }
        if (audioDeviceInput === undefined || audioDeviceInput === null) {
            for (var j = 0; j < devices.length; j++) {
                if (devices[j].kind === 'audioinput') {
                    setAudioDeviceInput(devices[j].deviceId)
                    setAudioDevice(devices[j].deviceId)
                    break
                }
            }
        }
    }, [audioDeviceInput, devices, setAudioDevice, setVideoDevice, videoDeviceInput])

    const handleSave = () => {
        localStorage.setItem('videoDevice', videoDeviceInput)
        localStorage.setItem('audioDevice', audioDeviceInput)
        setVideoDevice(videoDeviceInput)
        setAudioDevice(audioDeviceInput)
        handleClose()
    }

    const handleClose = () => {
        closeModal()
    }

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="configure-devices"
            open={open}
            fullWidth
            maxWidth="sm"
        >
            <BootstrapDialogTitle id="configure-devices" onClose={handleClose}>
                Configure Devices
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <Grid mt={0} mx={2}>
                    <InputLabel>Select Video Device</InputLabel>
                    <Select
                        value={videoDeviceInput}
                        fullWidth={true}
                        onChange={(e) => {
                            setVideoDeviceInput(e.target.value)
                        }}
                        label="."
                    >
                        {devices.map(
                            (item, key) =>
                                item.kind === 'videoinput' && (
                                    <MenuItem value={item.deviceId} key={key}>
                                        {item.label}
                                    </MenuItem>
                                )
                        )}
                    </Select>
                </Grid>
                <Grid mt={3} mx={2} mb={2}>
                    <InputLabel pt={2}>Select Audio Device</InputLabel>
                    <Select
                        value={audioDeviceInput}
                        fullWidth={true}
                        onChange={(e) => {
                            setAudioDeviceInput(e.target.value)
                        }}
                        label="."
                    >
                        {devices.map(
                            (item, key) =>
                                item.kind === 'audioinput' && (
                                    <MenuItem value={item.deviceId} key={key}>
                                        {' '}
                                        {item.label}{' '}
                                    </MenuItem>
                                )
                        )}
                    </Select>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonComp handleOnClick={handleSave}>Save</ButtonComp>
            </DialogActions>
        </BootstrapDialog>
    )
}

export default ConfigureDevices

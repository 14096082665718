import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as actions from '../../../store/actions/meeting'
import { Backdrop, CircularProgress } from '@mui/material'

const Freezed = () => {
    const meeting = useSelector((state) => state.meeting)
    const dispatch = useDispatch()

    const [seconds, setSeconds] = useState(meeting.freezedScreen ? 5 : 0)

    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000)
        } else {
            dispatch(actions.toggleFreezedScreen())
        }
    }, [seconds, dispatch])

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 4 }}
            open={meeting.freezedScreen}
        >
            <CircularProgress color="inherit" /> &nbsp;&nbsp;User roles will be switched
            in {seconds} seconds.
        </Backdrop>
    )
}
export default Freezed

import React from 'react'
import { Grid, Typography } from '@mui/material'

import LogoText from '../../utils/Logo/LogoText'
import {
    MOCK_INTERVIEW_UI,
    MAIN_UI,
    SOCIAL_URLS,
    DISCUSSIONS_FORUM,
} from '../../../constants/urls'
import getIcon from '../../../utils/getIcon'
import './Footer.css'

const Footer = () => {
    return (
        <footer className="footer">
            <Grid
                container
                className="footer__mainWrapper"
                justifyContent="space-between"
            >
                <Grid item lg={4} xs={12}>
                    <a className="footer__logoWrapper" href={MOCK_INTERVIEW_UI}>
                        <LogoText />
                    </a>
                    <Typography variant="body1" color="grey.medium" mt="2rem" mb="2rem">
                        Looking to practice software interviews? Mock interviews are what
                        you need.
                    </Typography>
                    <div className="footer__socialIconsWrapper">
                        <a
                            href={SOCIAL_URLS.YOUTUBE}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="interviewready-youtube"
                            data-testid="youtube"
                        >
                            {getIcon('YOUTUBE')}
                        </a>
                        <a
                            href={SOCIAL_URLS.LINKEDIN}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="interviewready-linkedin"
                            data-testid="linkedin"
                        >
                            {getIcon('LINKEDIN')}
                        </a>
                        <a
                            href={SOCIAL_URLS.DISCORD}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="interviewready-discord"
                            data-testid="discord"
                        >
                            {getIcon('DISCORD')}
                        </a>
                        <a
                            href={SOCIAL_URLS.TWITTER}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="interviewready-twitter"
                            data-testid="twitter"
                        >
                            {getIcon('TWITTER')}
                        </a>
                        <a
                            href={SOCIAL_URLS.INSTAGRAM}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="interviewready-instagram"
                            data-testid="instagram"
                        >
                            {getIcon('INSTAGRAM')}
                        </a>
                    </div>
                </Grid>
                <Grid item lg={2} xs={4}>
                    <Typography
                        variant="body1"
                        color="grey.medium"
                        fontWeight="600"
                        letterSpacing="0.05em"
                        mb="2rem"
                        typography={{ textTransform: 'uppercase' }}
                    >
                        Support
                    </Typography>
                    <ul className="footer__list">
                        <li>
                            <a data-testid="faq" href={`${MAIN_UI}/faq`}>
                                FAQ
                            </a>
                        </li>
                        <li>
                            <a data-testid="contact-us" href={`${MAIN_UI}/contact`}>
                                Contact Us
                            </a>
                        </li>
                    </ul>
                </Grid>
                <Grid item lg={2} xs={4}>
                    <Typography
                        variant="body1"
                        color="grey.medium"
                        fontWeight="600"
                        letterSpacing="0.05em"
                        mb="2rem"
                        typography={{ textTransform: 'uppercase' }}
                    >
                        Company
                    </Typography>
                    <ul className="footer__list">
                        <li>
                            <a data-testid="home" href={`${MOCK_INTERVIEW_UI}`}>
                                Home
                            </a>
                        </li>
                        <li>
                            <a
                                data-testid="blog"
                                href={`${MAIN_UI}/blog`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Blog
                            </a>
                        </li>
                        <li>
                            <a
                                data-testid="our-team"
                                href={`${MAIN_UI}/our-team`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Our Team
                            </a>
                        </li>
                        <li>
                            <a
                                data-testid="course-preview"
                                href={`${MAIN_UI}/course-preview`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Course Preview
                            </a>
                        </li>
                        <li>
                            <a
                                data-testid="mock-interviews"
                                href={`${MAIN_UI}/mock-interviews`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Mock Interviews
                            </a>
                        </li>
                        <li>
                            <a
                                data-testid="discussions-forum"
                                href={DISCUSSIONS_FORUM}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Discussions Forum
                            </a>
                        </li>
                    </ul>
                </Grid>
                <Grid item lg={2} xs={4}>
                    <Typography
                        variant="body1"
                        color="grey.medium"
                        fontWeight="600"
                        letterSpacing="0.05em"
                        mb="2rem"
                        typography={{ textTransform: 'uppercase' }}
                    >
                        Legal
                    </Typography>
                    <ul className="footer__list">
                        <li>
                            <a data-testid="disclaimer" href={`${MAIN_UI}/disclaimer`}>
                                Disclaimer
                            </a>
                        </li>
                        <li>
                            <a
                                data-testid="privacy-policy"
                                href={`${MAIN_UI}/privacy-policy`}
                            >
                                Privacy Policy
                            </a>
                        </li>
                        <li>
                            <a data-testid="tnc" href={`${MAIN_UI}/tnc`}>
                                Terms & Conditions
                            </a>
                        </li>
                        <li>
                            <a data-testid="banned-conditions" href={`${MAIN_UI}/banned`}>
                                Banned Conditions
                            </a>
                        </li>
                    </ul>
                </Grid>
            </Grid>
            <div className="footer__copyrightWrapper">
                <Typography
                    variant="body1"
                    color="grey.medium"
                    mt="2rem"
                    mb="2rem"
                    align="center"
                >
                    &copy; 2021 Interview Ready. All rights reserved.
                </Typography>
            </div>
        </footer>
    )
}

export default Footer

import React, { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import CallEndIcon from '@mui/icons-material/CallEnd'
import VideocamIcon from '@mui/icons-material/Videocam'
import VideocamOffIcon from '@mui/icons-material/VideocamOff'
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined'
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined'
import ScreenShareIcon from '@mui/icons-material/ScreenShare'
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import { IconButton, Grid, Tooltip } from '@mui/material'

import * as actions from '../../../store/actions/meeting'
import { toggleQuestionsSidebar } from '../../../store/actions/ui'
import * as socketFx from '../socket'
import Timer from '../Timer/Timer'
import Chat from '../Chat/Chat'
import getIcon from '../../../utils/getIcon'
import ReportForm from '../ReportForm/ReportForm'
import ConfirmModal from '../../utils/ConfirmModal/ConfirmModal'
import ButtonComp from '../../utils/ButtonComp/ButtonComp'
import toastNotification from '../../utils/Notification/Notification'
import { MeetContext } from '../MeetContext'
import './MeetFooter.css'

const MeetFooter = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const {
        userVideoRef,
        userScreenRef,
        remoteScreenRef,
        remoteVideoRef,
        isUserScreenAvailable,
        meetInfo,
    } = useContext(MeetContext)

    const meeting = useSelector((state) => state.meeting)
    const { token, events, isTester } = meeting

    const [isConfirmOpen, setIsConfirmOpen] = useState(false)
    const [endMeetForce, setEndMeetForce] = useState(false)
    const [disabledScreenShare, setDisabledScreenShare] = useState(true)

    useEffect(() => {
        socketFx.onClientJoinToRoom(events.currentRoomState, (usersCount) => {
            dispatch(actions.updateRoomUsersCount(usersCount))
            if (usersCount > 1) setDisabledScreenShare(false)
        })

        socketFx.onClientDisconnect(events.clientDisconnect, () => {
            setDisabledScreenShare(true)
            dispatch(actions.toggleDrawingBoard(false))

            if (meeting.shareScreen) {
                userScreenRef.current.srcObject
                    .getTracks()
                    .forEach((track) => track.stop())
                toastNotification('Other user disconnected! Stopping screen share.')
                dispatch(actions.toggleShareScreen())
                socketFx.myScreen(meeting.events.screenShare, token)
            }

            if (meeting.userShareScreen) {
                toastNotification('Other user disconnected! Stopping screen share.')
                dispatch(actions.toggleUserShareScreen())
            }
        })

        socketFx.onLeaveRoom(events.leave, (userID) => {
            toastNotification(`${meetInfo.remoteUser.name} left!`)
            setEndMeetForce(true)
        })
    }, [
        events.leave,
        userScreenRef,
        events.currentRoomState,
        token,
        dispatch,
        meeting.userShareScreen,
        meeting.shareScreen,
        events.clientDisconnect,
        meeting.events.screenShare,
        meetInfo,
    ])

    const handleToggleAudio = () => {
        try {
            userVideoRef.current.srcObject
                .getAudioTracks()
                .forEach((track) => (track.enabled = !track.enabled))

            dispatch(actions.toggleAudio())
        } catch (err) {
            console.log('[handleToggleAudio() failed!]', err)
        }
    }

    const handleToggleVideo = () => {
        try {
            userVideoRef.current.srcObject
                .getVideoTracks()
                .forEach((track) => (track.enabled = !track.enabled))

            dispatch(actions.toggleVideo())
        } catch (err) {
            console.log('[handleToggleVideo() failed!]', err)
        }
    }

    const handleToggleScreenShare = () => {
        if (meeting.userShareScreen || disabledScreenShare) return

        if (meeting.shareScreen) {
            userScreenRef.current.srcObject.getTracks().forEach((track) => track.stop())

            toastNotification('Stopping screen share.')
        } else {
            toastNotification('Starting screen share.')
        }

        dispatch(actions.toggleShareScreen())
        socketFx.myScreen(meeting.events.screenShare, token)
    }

    const handleMeetEnd = () => {
        try {
            dispatch(actions.expireMeet(meetInfo.roomID))

            if (userVideoRef.current && userVideoRef.current.srcObject) {
                userVideoRef.current.srcObject.getTracks().forEach((track) => {
                    track.stop()
                })
                userVideoRef.current.srcObject = null
            }

            if (remoteVideoRef.current && remoteVideoRef.current.srcObject) {
                remoteVideoRef.current.srcObject.getTracks().forEach((track) => {
                    track.stop()
                })
                remoteVideoRef.current.srcObject = null
            }

            if (userScreenRef.current && userScreenRef.current.srcObject) {
                userScreenRef.current.srcObject.getTracks().forEach((track) => {
                    track.stop()
                })
                userScreenRef.current.srcObject = null
            }

            if (remoteScreenRef.current && remoteScreenRef.current.srcObject) {
                remoteScreenRef.current.srcObject.getTracks().forEach((track) => {
                    track.stop()
                })
                remoteScreenRef.current.srcObject = null
            }

            history.push('/feedback')
            socketFx.leaveRoom(meeting.events.leave, token)
        } catch (err) {
            console.log('[handleMeetEnd() failed!]', err)
        }
    }

    const flipRole = () => {
        toastNotification('Requesting to flip role.')
        socketFx.sendFlipRoleRequest(meeting.events.flipRole, token)
    }

    const handleDrawingBoardToggle = () => {
        if (meeting.roomUsersCount < 2) return
        socketFx.sendDrawingBoardSignal(
            events.drawingBoardSignal,
            token,
            !meeting.drawingBoard
        )
    }

    const handleQuestionsSidebarToggle = () => {
        dispatch(toggleQuestionsSidebar())
    }

    return (
        <>
            <Grid container className="meetFooter" alignItems="center">
                <Grid
                    item
                    md={2}
                    xs={12}
                    className="meetFooter__actionsLeft"
                    align="left"
                >
                    {isTester && (
                        <Tooltip title="Question To Ask" placement="top" arrow>
                            <div className="mobile-hide">
                                <ButtonComp
                                    handleOnClick={handleQuestionsSidebarToggle}
                                    modifyClass="meetFooter__questionsButton"
                                >
                                    Question <ContactSupportIcon color="secondary" />
                                </ButtonComp>
                            </div>
                        </Tooltip>
                    )}
                    <Timer />
                </Grid>
                <Grid
                    item
                    md={8}
                    xs={12}
                    align="center"
                    className="meetFooter__actionButton"
                >
                    <Tooltip title="Mic on/off" placement="top" arrow>
                        <IconButton
                            size="large"
                            className={`meetFooter__circleButton ${
                                !meeting.audio && 'meetFooter__circleButton--off'
                            }`}
                            onClick={handleToggleAudio}
                        >
                            {meeting.audio ? (
                                <MicNoneOutlinedIcon color="secondary" />
                            ) : (
                                <MicOffOutlinedIcon color="secondary" />
                            )}
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Camera on/off" placement="top" arrow>
                        <IconButton
                            size="large"
                            className={`meetFooter__circleButton ${
                                !meeting.video && 'meetFooter__circleButton--off'
                            }`}
                            onClick={handleToggleVideo}
                        >
                            {meeting.video ? (
                                <VideocamIcon color="secondary" />
                            ) : (
                                <VideocamOffIcon color="secondary" />
                            )}
                        </IconButton>
                    </Tooltip>

                    {!meeting.isFlip && (
                        <Tooltip title="Swap role" placement="top" arrow>
                            <IconButton
                                size="large"
                                className={`meetFooter__circleButton meetFooter__circleButton--off'
                            }`}
                                onClick={flipRole}
                            >
                                <FlipCameraAndroidIcon />
                            </IconButton>
                        </Tooltip>
                    )}

                    <Tooltip title="WhiteBoard" placement="top" arrow>
                        <IconButton
                            size="large"
                            className={`meetFooter__circleButton meetFooter__circleButton--whiteboard meetFooter__circleButton--mobile-hide ${
                                meeting.drawingBoard && 'meetFooter__circleButton--off'
                            }  ${
                                meeting.roomUsersCount < 2 &&
                                'meetFooter__circleButton--disabled'
                            }`}
                            onClick={handleDrawingBoardToggle}
                        >
                            {getIcon('WHITEBOARD')}
                        </IconButton>
                    </Tooltip>

                    {isUserScreenAvailable && (
                        <Tooltip
                            title={
                                meeting.userShareScreen || disabledScreenShare
                                    ? "Can't share screen now!"
                                    : meeting.shareScreen
                                    ? 'Stop sharing screen'
                                    : 'Share your screen'
                            }
                            placement="top"
                            arrow
                        >
                            <IconButton
                                size="large"
                                className={`meetFooter__circleButton meetFooter__circleButton--mobile-hide ${
                                    (meeting.userShareScreen || disabledScreenShare) &&
                                    'meetFooter__circleButton--disabled'
                                } ${
                                    meeting.shareScreen &&
                                    'meetFooter__circleButton--sharing'
                                }`}
                                onClick={handleToggleScreenShare}
                            >
                                {meeting.shareScreen ? (
                                    <CancelPresentationIcon color="secondary" />
                                ) : (
                                    <ScreenShareIcon color="secondary" />
                                )}
                            </IconButton>
                        </Tooltip>
                    )}

                    <Tooltip title="End call" placement="top" arrow>
                        <IconButton
                            size="large"
                            aria-label="End Interview"
                            className="meetFooter__circleButton meetFooter__circleButton--end"
                            onClick={() => {
                                setIsConfirmOpen(true)
                            }}
                        >
                            <CallEndIcon color="secondary" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item md={2} className="meetFooter__actionsRight" align="right">
                    <Chat />
                    <ReportForm />
                </Grid>
            </Grid>

            <ConfirmModal
                isOpen={isConfirmOpen}
                setIsOpen={setIsConfirmOpen}
                handleOnConfirm={handleMeetEnd}
            />

            <ConfirmModal
                title="Other participant has left!"
                body="The other participant has left the interview deliberately and he/she won't join again, so you can also leave the interview."
                isOpen={endMeetForce}
                setIsOpen={setEndMeetForce}
                showCancel={false}
                handleOnConfirm={handleMeetEnd}
                confirmButtonText="Okay leave!"
            />
        </>
    )
}

export default MeetFooter

import React from 'react'
import { ButtonBase, Typography } from '@mui/material'

import './ButtonComp.css'

const ButtonComp = ({
    children = 'Button Text',
    type = 'button',
    variant = 'primary',
    size = '',
    modifyClass = '',
    handleOnClick = () => {
        console.log(`[Button Clicked!] ${children}`)
    },
    disabled = false,
}) => {
    const onClickHandler = (e) => {
        if (disabled) return
        handleOnClick(e)
    }

    return (
        <ButtonBase
            type={type}
            className={`buttonComp ${variant} ${size} ${modifyClass} ${
                disabled && 'disabled'
            }`}
            onClick={onClickHandler}
            disabled={disabled}
        >
            <Typography variant="body1" fontWeight="500">
                {children}
            </Typography>
        </ButtonBase>
    )
}

export default ButtonComp

import { useEffect, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toggleScheduleAnotherMeetingModal } from '../../store/actions/ui'
import ScheduleAnotherMeetingModal from './ScheduleAnotherMeetingModal/ScheduleAnotherMeetingModal'
import axios from 'axios'

import LoaderScreen from '../utils/Loader/Loader'
import { enterInterview } from '../../store/actions/meeting'

const MeetVerify = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { roomID } = useParams()

    const { user } = useSelector((state) => state.userInfo)

    const meetVerify = useCallback(async () => {
        try {
            if (user && user.id) {
                const resp = await axios.post(
                    `${process.env.REACT_APP_SERVERDOM}/meet-info`,
                    {
                        userID: Number(user.id),
                        roomID: Number(roomID),
                    },
                    {
                        withCredentials: true,
                    }
                )
                const { ok, token, events } = resp.data

                if (!ok) throw Error('Invalid request')
                dispatch(
                    enterInterview({
                        token,
                        events,
                        userID: user.id,
                    })
                )
                window.meetingId = roomID
                history.push(`/interview-gateway/${roomID}`)
            }
        } catch (e) {
            if (e.response.data.message==="Interview is Expired"){
                dispatch(toggleScheduleAnotherMeetingModal())
            }else{
                history.push({
                    pathname: '/404',
                    state: { message: e.response.data }
                })
            }
        }
    }, [history, dispatch, roomID, user])

    useEffect(() => {
        if (!navigator.onLine) return history.push('/404')
        meetVerify()
    }, [meetVerify, history])

    return (
        <div className="custom-bg" style={{ minHeight: '100vh' }}>
            <ScheduleAnotherMeetingModal/>
            <LoaderScreen />
        </div>
    )
}

export default MeetVerify

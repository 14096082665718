import { useContext, useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { MeetContext } from '../../Meeting/MeetContext'
import './CanvasElement.css'

const CanvasElement = ({ elementID }) => {
    const { userVideoRef } = useContext(MeetContext)
    const { isBackgroundBlur } = useSelector((state) => state.meeting)

    const [width, setWidth] = useState(userVideoRef.current?.clientWidth)
    const [height, setHeight] = useState(userVideoRef.current?.clientHeight)

    const updateCanvasSize = useCallback(() => {
        setWidth(userVideoRef.current?.clientWidth)
        setHeight(userVideoRef.current?.clientHeight)
    }, [userVideoRef])

    useEffect(() => {
        updateCanvasSize()

        window.addEventListener('resize', updateCanvasSize)
        return () => {
            window.removeEventListener('resize', updateCanvasSize)
        }
    }, [updateCanvasSize])

    return (
        <canvas
            id={elementID}
            width={width}
            height={height}
            className={!isBackgroundBlur ? 'hidden' : ''}
        />
    )
}

export default CanvasElement

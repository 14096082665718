import { useState, useContext, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import DrawingBoard from 'react-drawing-board'
import { useParams } from 'react-router-dom'

import { getDrawingImage } from '../../../store/actions/meeting'
import { MeetContext } from '../MeetContext'
import { onDrawingBoardImage, sendDrawingBoardImage } from '../socket'
import './DrawingBoard.css'

const DrawingBoardComp = () => {
    const { roomID } = useParams()
    const dispatch = useDispatch()

    const { testerId, events, token } = useSelector((state) => state.meeting)
    const { clearCode } = useSelector((state) => state.code)

    const { meetInfo } = useContext(MeetContext)
    const [operations, setOperations] = useState([])

    const drawingSaveId =
        meetInfo.userID === testerId ? meetInfo.remoteUser.id : meetInfo.userID

    useEffect(() => {
        onDrawingBoardImage(events.drawingBoard, (drawingBoardImage) => {
            setOperations(drawingBoardImage)
        })
    }, [events.drawingBoard])

    //clear drawing board on user role flip
    useEffect(() => {
        if (clearCode) {
            setOperations([])
        }
    }, [clearCode])

    //fetch drawing Image from server
    useEffect(() => {
        const reqData = {
            meetingId: roomID,
            drawingSaveId: drawingSaveId,
        }
        dispatch(getDrawingImage(reqData)).then((res) => {
            setOperations(res.drawingImage)
        })
    }, [dispatch, drawingSaveId, roomID])

    return (
        <div className="drawingBoardComp">
            <DrawingBoard
                toolbarPlacement="top"
                userId={meetInfo.userID}
                operations={operations}
                onChange={(newOperation, afterOperation) => {
                    setOperations(afterOperation)
                    sendDrawingBoardImage(
                        events.drawingBoard,
                        token,
                        afterOperation,
                        drawingSaveId
                    )
                    if (newOperation.tool === 'Clear') {
                        setOperations([])
                        sendDrawingBoardImage(
                            events.drawingBoard,
                            token,
                            [],
                            drawingSaveId
                        )
                    }
                }}
            />
        </div>
    )
}

export default DrawingBoardComp

import * as actionTypes from '../actionTypes'
import Axios from '../../service/axios'
import toastNotification from '../../components/utils/Notification/Notification'
import { getUpcomingInterviews } from './interview'
import { addInterviewToGoogleCalendar } from '../../utils/addToCalenderUtils'

export const scheduleInterview = (reqBody) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({ type: actionTypes.SCHEDULER_REQUEST })
        const interviewDetails = { starttime: new Date(reqBody.StartTime) / 1000, type: reqBody.Type, status: 0 }
        Axios.post('api/v1/mock-interviews/schedule', reqBody)
            .then((res) => {
                dispatch(getUpcomingInterviews())

                dispatch({ 
                    type: actionTypes.SCHEDULER_SUCCESS,
                })

                toastNotification(
                    'Interview request submitted! You will get the interview link after match.'
                )
                addInterviewToGoogleCalendar(interviewDetails)
                resolve(res)
            })
            .catch((err) => {
                const errorMessage = err?.response?.data?.message
                    ? err?.response?.data?.message
                    : "Can't schedule your interview! Please try again."
                dispatch({
                    type: actionTypes.SCHEDULER_FAIL,
                    response: errorMessage,
                })

                toastNotification(errorMessage)
                reject(err)
            })

            .finally(() => {
                dispatch({ type: actionTypes.TOGGLE_SCHEDULER_MODAL })
            })
    })
}

import { createTheme } from '@mui/material'

const theme = createTheme({
    typography: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),
    },
    palette: {
        primary: {
            light: '#BDE5FF',
            main: '#0294F4',
            dark: '#01375A',
            contrastText: '#F9FAFB',
        },
        secondary: {
            main: '#F9FAFB',
            contrastText: '#01375A',
        },
        error: {
            main: '#D1003A',
        },
        success: {
            main: '#64CF0A',
        },
        blue: '#005AC2',
        white: '#FFFFFF',
        black: '#000000',
        grey: {
            light: '#f9fafb',
            medium: '#9ca3af',
            main: '#6b7280',
            dark: '#111827',
        },
        indigo: {
            900: '#01375a',
            800: '#005288',
            700: '#00609f',
            600: '#0071bc',
            500: '#0088e2',
            400: '#0294f4',
            300: '#54bbff',
            200: '#7acaff',
            100: '#bde5ff',
            50: '#f6fcff',
        },
        red: {
            400: '#d1003a',
            100: '#e64975',
        },
    },
})

export default theme

// Meeting *************************************************************************
export const TOGGLE_VIDEO = 'TOGGLE_VIDEO'
export const TOGGLE_AUDIO = 'TOGGLE_AUDIO'
export const TOGGLE_SHARE_SCREEN = 'TOGGLE_SHARE_SCREEN'
export const TOGGLE_USER_SHARE_SCREEN = 'TOGGLE_USER_SHARE_SCREEN'
export const TOGGLE_LOADING = 'TOGGLE_LOADING'
export const ENTER_INTERVIEW = 'ENTER_INTERVIEW'
export const TOGGLE_FLIP_ROLE = 'TOGGLE_FLIP_ROLE'
export const TOGGLE_TESTER = 'TOGGLE_TESTER'
export const TOGGLE_FREEZED_SCREEN = 'TOGGLE_FREEZED_SCREEN'
export const MEET_START_TIME = 'MEET_START_TIME'
export const TESTER_ID = 'TESTER_ID'
export const SHOW_ROLE_MODAL = 'SHOW_ROLE_MODAL'
export const ROOM_USERS_COUNT = 'ROOM_USERS_COUNT'
export const SET_INTERVIEW_ENDED = 'SET_INTERVIEW_ENDED'
export const SET_INTERVIEW_QUESTION = 'SET_INTERVIEW_QUESTION'
export const TOGGLE_DRAWING_BOARD = 'TOGGLE_DRAWING_BOARD'
export const TOGGLE_BACKGROUND_BLUR = 'TOGGLE_BACKGROUND_BLUR'

// Scheduler *************************************************************************
export const SCHEDULER_REQUEST = 'SCHEDULER_REQUEST'
export const SCHEDULER_SUCCESS = 'SCHEDULER_SUCCESS'
export const SCHEDULER_FAIL = 'SCHEDULER_FAIL'

// Upcoming Interviews *****************************************************************
export const GET_UPCOMING_INTERVIEWS_REQUEST = 'GET_UPCOMING_INTERVIEWS_REQUEST'
export const GET_UPCOMING_INTERVIEWS_SUCCESS = 'GET_UPCOMING_INTERVIEWS_SUCCESS'
export const GET_UPCOMING_INTERVIEWS_FAIL = 'GET_UPCOMING_INTERVIEWS_FAIL'
export const CANCEL_INTERVIEW_REQUEST = 'CANCEL_INTERVIEW_REQUEST'
export const CANCEL_INTERVIEW_SUCCESS = 'CANCEL_INTERVIEW_SUCCESS'
export const CANCEL_INTERVIEW_FAIL = 'CANCEL_INTERVIEW_FAIL'
export const REMOVE_CANCELLED_INTERVIEW = 'REMOVE_CANCELLED_INTERVIEW'


// User Info **********************************************************************
export const SAVE_USER = 'SAVE_USER'
export const SIGN_OUT = 'SIGN_OUT'

// Meeting Chat *******************************************************************
export const UNREAD_MESSAGE_COUNT = 'UNREAD_MESSAGE_COUNT'
export const CHAT_MESSAGE_PUSH = 'CHAT_MESSAGE_PUSH'
export const CHAT_INITIAL_FETCH = 'CHAT_INITIAL_FETCH'

// Interview Feedback **************************************************************
export const SET_FEEDBACK_COMMENTS = 'SET_FEEDBACK_COMMENTS'
export const FEEDBACK_FORM_RESET = 'FEEDBACK_FORM_RESET'
export const SAVE_FEEDBACK_DATA_REQUEST = 'SAVE_FEEDBACK_DATA_REQUEST'
export const SAVE_FEEDBACK_DATA_SUCCESS = 'SAVE_FEEDBACK_DATA_SUCCESS'
export const SAVE_FEEDBACK_DATA_FAIL = 'SAVE_FEEDBACK_DATA_FAIL'
export const GET_SAVED_FEEDBACK_REQUEST = 'GET_SAVED_FEEDBACK_REQUEST'
export const GET_SAVED_FEEDBACK_SUCCESS = 'GET_SAVED_FEEDBACK_SUCCESS'
export const GET_SAVED_FEEDBACK_FAIL = 'GET_SAVED_FEEDBACK_FAIL'

// Interview Question ********************************************************************
export const GET_ASKED_QUESTION_REQUEST = 'GET_ASKED_QUESTION_REQUEST'
export const GET_ASKED_QUESTION_SUCCESS = 'GET_ASKED_QUESTION_SUCCESS'
export const GET_ASKED_QUESTION_FAIL = 'GET_ASKED_QUESTION_FAIL'

// All Meetings ********************************************************************
export const GET_ALL_MEETINGS_REQUEST = 'GET_ALL_MEETINGS_REQUEST'
export const GET_ALL_MEETINGS_SUCCESS = 'GET_ALL_MEETINGS_SUCCESS'
export const GET_ALL_MEETINGS_FAIL = 'GET_ALL_MEETINGS_FAIL'

// Interview History Feedback ******************************************************
export const GET_HISTORY_FEEDBACK_REQUEST = 'GET_HISTORY_FEEDBACK_REQUEST'
export const GET_HISTORY_FEEDBACK_SUCCESS = 'GET_HISTORY_FEEDBACK_SUCCESS'
export const GET_HISTORY_FEEDBACK_FAIL = 'GET_HISTORY_FEEDBACK_FAIL'

// Interview History Chat **********************************************************
export const GET_MEETING_CHAT_REQUEST = 'GET_MEETING_CHAT_REQUEST'
export const GET_MEETING_CHAT_SUCCESS = 'GET_MEETING_CHAT_SUCCESS'
export const GET_MEETING_CHAT_FAIL = 'GET_MEETING_CHAT_FAIL'

// Interview Code **********************************************************
export const GET_INTERVIEW_CODE_REQUEST = 'GET_INTERVIEW_CODE_REQUEST'
export const GET_INTERVIEW_CODE_SUCCESS = 'GET_INTERVIEW_CODE_SUCCESS'
export const GET_INTERVIEW_CODE_FAIL = 'GET_INTERVIEW_CODE_FAIL'
export const RUN_INTERVIEW_CODE_REQUEST = 'RUN_INTERVIEW_CODE_REQUEST'
export const RUN_INTERVIEW_CODE_SUCCESS = 'RUN_INTERVIEW_CODE_SUCCESS'
export const RUN_INTERVIEW_CODE_FAIL = 'RUN_INTERVIEW_CODE_FAIL'
export const GET_CODE_COMPILE_RESULT_REQUEST = 'GET_CODE_COMPILE_RESULT_REQUEST'
export const GET_CODE_COMPILE_RESULT_SUCCESS = 'GET_CODE_COMPILE_RESULT_SUCCESS'
export const GET_CODE_COMPILE_RESULT_FAIL = 'GET_CODE_COMPILE_RESULT_FAIL'
export const SET_CODE_COMPILE_REQUEST_TIMEOUT = 'SET_CODE_COMPILE_REQUEST_TIMEOUT'
export const TOGGLE_CODE_THEME = 'TOGGLE_CODE_THEME'
export const CODE_LANGUAGE_CHANGE = 'CODE_LANGUAGE_CHANGE'
export const TOGGLE_CLEAR_CODE = 'TOGGLE_CLEAR_CODE'
export const SET_SYNCED_CODE_STORE_DATA = 'SET_SYNCED_CODE_STORE_DATA'

// User Ratings ********************************************************************
export const GET_USER_RATING_REQUEST = 'GET_USER_RATING_REQUEST'
export const GET_USER_RATING_SUCCESS = 'GET_USER_RATING_SUCCESS'
export const GET_USER_RATING_FAIL = 'GET_USER_RATING_FAIL'

// Platform Rating *****************************************************************
export const SET_FEEDBACK_SUBMITTED = 'SET_FEEDBACK_SUBMITTED'
export const SAVE_PLATFORM_RATING_REQUEST = 'SAVE_PLATFORM_RATING_REQUEST'
export const SAVE_PLATFORM_RATING_SUCCESS = 'SAVE_PLATFORM_RATING_SUCCESS'
export const SAVE_PLATFORM_RATING_FAIL = 'SAVE_PLATFORM_RATING_FAIL'

// Mobile Update OTP ***************************************************************
export const OTP_SEND_REQUEST = 'OTP_SEND_REQUEST'
export const OTP_SEND_SUCCESS = 'OTP_SEND_SUCCESS'
export const OTP_SEND_FAIL = 'OTP_SEND_FAIL'
export const OTP_SEND_COUNT_INCREMENT = 'OTP_SEND_COUNT_INCREMENT'
export const OTP_EDIT_PHONE = 'OTP_EDIT_PHONE'
export const OTP_VERIFY_REQUEST = 'OTP_VERIFY_REQUEST'
export const OTP_VERIFY_SUCCESS = 'OTP_VERIFY_SUCCESS'
export const OTP_VERIFY_FAIL = 'OTP_VERIFY_FAIL'
export const OTP_STORE_RESET = 'OTP_STORE_RESET'

// UI ********************************************************************************
export const TOGGLE_SCHEDULER_MODAL = 'TOGGLE_SCHEDULER_MODAL'
export const TOGGLE_PROFILE_SIDEBAR = 'TOGGLE_PROFILE_SIDEBAR'
export const TOGGLE_QUESTIONS_SIDEBAR = 'TOGGLE_QUESTIONS_SIDEBAR'
export const SHOW_LOADING = 'SHOW_LOADING' //  linear loader
export const TOGGLE_PROFILE_MODAL = 'TOGGLE_PROFILE_MODAL'
export const TOGGLE_CODE_RESULT_PANEL = 'TOGGLE_CODE_RESULT_PANEL'
export const TOGGLE_SCHEDULEANOTHERMEETING_MODAL = 'TOGGLE_SCHEDULEANOTHERMEETING_MODAL'

// Instant Interview ******************************************************************
export const SET_INSTANT_INTERVIEW_REQUEST_STATUS = 'SET_INSTANT_INTERVIEW_REQUEST_STATUS'

// Promotion Banner *******************************************************************
export const SET_PROMOTION_BANNERS = 'SET_PROMOTION_BANNERS'
export const CLOSE_PROMOTION_BANNER = 'CLOSE_PROMOTION_BANNER'

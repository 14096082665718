import {
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    Select,
    TextField,
    InputLabel,
    FormControl,
    MenuItem,
    DialogActions,
    Tooltip,
} from '@mui/material'
import { useEffect, useState, useContext } from 'react'
import ReportIcon from '@mui/icons-material/Report'

import Axios from '../../../service/axios'
import ButtonComp from '../../utils/ButtonComp/ButtonComp'
import toastNotification from '../../utils/Notification/Notification'
import { MeetContext } from '../MeetContext'
import './ReportForm.css'

const ReportForm = ({ isButton }) => {
    const { meetInfo } = useContext(MeetContext)

    const [count, setCount] = useState(0)
    const [form, setForm] = useState(false)
    const [desc, setDesc] = useState('')
    const [factor, setFactor] = useState('')
    const [error, setError] = useState(false)

    useEffect(() => {
        if (meetInfo.report_submitted === 'true') {
            setCount(1)
        }
    }, [count, meetInfo.report_submitted])

    const formSubmit = () => {
        if (factor.length === 0) toastNotification("Error: Report Base can't be empty!")
        else {
            const data = {
                type: factor,
                report_message: desc,
                meeting: meetInfo.roomID,
                reported_for: meetInfo.remoteUser.id,
            }

            //api calling
            Axios.post('/api/v1/report/create', data)
                .then((res) => {
                    if (res) {
                        toastNotification('Interview Reported.')
                        setCount(1)
                    }
                })
                .catch((err) => {
                    toastNotification('Error: Please try again later!')
                })
        }
    }

    const handleReportButtonClick = () => {
        if (count === 0) {
            setForm((prev) => !prev)
        } else {
            toastNotification('Error: You can report a meet once in a session!')
        }
    }

    const handleOnCancel = () => {
        setForm(!form)
        setError(false)
        setFactor('')
        setDesc('')
    }

    const handleOnConfirm = () => {
        setForm(false)
        formSubmit()
    }

    return (
        <>
            {isButton ? (
                <ButtonComp
                    handleOnClick={handleReportButtonClick}
                    modifyClass="buttonComp__variant--grey"
                >
                    <ReportIcon />
                    &nbsp;Report User
                </ButtonComp>
            ) : (
                <Tooltip title="Report interview" placement="top" arrow>
                    <IconButton
                        aria-label="Report meet"
                        onClick={handleReportButtonClick}
                    >
                        <ReportIcon className="reportForm__button" fontSize="large" />
                    </IconButton>
                </Tooltip>
            )}

            <Dialog open={form} fullWidth maxWidth="sm">
                <DialogTitle>Report Interview</DialogTitle>
                <DialogContent>
                    <FormControl autoFocus fullWidth margin="dense">
                        <InputLabel htmlFor="reportTypeID">Report Base On</InputLabel>
                        <Select
                            required
                            label="Report Base On"
                            labelId="reportTypeID"
                            value={factor}
                            onChange={(e) => setFactor(e.target.value)}
                        >
                            <MenuItem value={'Cheating'}>Cheating</MenuItem>
                            <MenuItem value={'Spamming'}>Spam</MenuItem>
                            <MenuItem value={'Inappropriate Behaviour'}>
                                Inappropriate Behaviour
                            </MenuItem>
                            <MenuItem value={'Threatening'}>Threatening</MenuItem>
                            <MenuItem value={'Left Meet Earlier'}>
                                Left Meet Early
                            </MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        fullWidth
                        error={error}
                        helperText={desc.length + '/250 char'}
                        margin="dense"
                        label="Description"
                        multiline
                        rows={4}
                        type="text"
                        variant="filled"
                        color="primary"
                        value={desc}
                        onChange={(e) => {
                            setDesc(e.target.value)
                            if (
                                e.target.value.length <= 250 ||
                                e.target.value.length >= 25
                            )
                                setError(false)
                            else setError(true)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <ButtonComp variant="secondary" handleOnClick={handleOnCancel}>
                        Cancel
                    </ButtonComp>
                    <ButtonComp
                        handleOnClick={handleOnConfirm}
                        disabled={desc.length < 25 || !factor}
                    >
                        Report
                    </ButtonComp>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ReportForm

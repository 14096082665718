import React from 'react'
import FilledInput from '@mui/material/FilledInput'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'

import './TesterNotes.css'

const FeedbackInput = ({
    type = 'other',
    title = 'Other Comments',
    description = 'Take some notes about the candidate',
    inputVal = '',
    handleOnChange = () => {},
}) => {
    return (
        <div className="feedbackInput">
            <FormControl variant="filled" fullWidth margin="normal">
                <InputLabel htmlFor="feedbackInputID">{title}</InputLabel>
                <FilledInput
                    multiline
                    id="feedbackInputID"
                    minRows={5}
                    name={type}
                    value={inputVal}
                    onChange={handleOnChange}
                    aria-describedby="feedbackInputHelperTextID"
                />
                <FormHelperText id="feedbackInputHelperTextID">
                    {description}
                </FormHelperText>
            </FormControl>
        </div>
    )
}

export default FeedbackInput

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { toggleShowRoleModal } from '../../../store/actions/meeting'
import ConfirmModal from '../../utils/ConfirmModal/ConfirmModal'

const RoleModal = () => {
    const { showRoleModal, isTester, isFlip } = useSelector((state) => state.meeting)
    const dispatch = useDispatch()

    const handleOnConfirm = () => {
        dispatch(toggleShowRoleModal())
    }

    return (
        <ConfirmModal
            isOpen={showRoleModal}
            title={
                isTester
                    ? `You're ${isFlip ? 'now' : ''} the interviewer now`
                    : `You're ${isFlip ? 'now' : ''} the candidate now`
            }
            body={
                isTester
                    ? 'Ask questions! Be professional.'
                    : 'Best of luck! Give your best.'
            }
            confirmButtonText="Okay!"
            showCancel={false}
            handleOnConfirm={handleOnConfirm}
        />
    )
}

export default RoleModal

import { useState } from 'react'
import { useSelector } from 'react-redux'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/theme-github'
import CloseIcon from '@mui/icons-material/Close'
import {
    Rating,
    IconButton,
    Typography,
    TextField,
    Modal,
    Box,
    CircularProgress,
} from '@mui/material'
import moment from 'moment'
import ReactMarkdown from 'react-markdown'

import ChatMessage from '../../Meeting/Chat/ChatMessage'
import ButtonComp from '../../utils/ButtonComp/ButtonComp'

const Loader = () => (
    <Box
        sx={{
            display: 'grid',
            placeItems: 'center',
            minHeight: '500px',
            width: '100%',
        }}
    >
        <CircularProgress />
    </Box>
)

const PastInterviewsModal = ({ isModalOpen, setIsModalOpen, selectedInterviewData }) => {
    const {
        historyFeedback,
        loading: loadingFeedback,
        askedQuestion,
        loadingQuestion,
    } = useSelector((state) => state.feedback)
    const { chatData, loading: loadingChat } = useSelector((state) => state.chat)
    const { loading: loadingCode } = useSelector((state) => state.code)

    const [section, setSection] = useState('COMMENTS')

    const calculateOverallScore = () => {
        const total = historyFeedback.reduce((acc, curr) => acc + curr.score, 0)
        return total / 3
    }

    const handleModalClose = () => {
        setIsModalOpen(false)
        setSection('COMMENTS')
    }

    const selectedButtonVariant = (sectionButton) =>
        sectionButton === section ? 'primary' : 'secondary'

    const SelectedSection = () => {
        switch (section) {
            case 'COMMENTS':
                return (
                    <div className="pastInterviews__feedbackTab">
                        {loadingFeedback ? (
                            <Loader />
                        ) : historyFeedback.length === 0 ? (
                            <Typography
                                variant="h3"
                                fontSize="1rem"
                                fontWeight="400"
                                align="center"
                                mt="2rem"
                                style={{ color: 'var(--grey)' }}
                            >
                                No feedback found for this interview!
                            </Typography>
                        ) : (
                            historyFeedback.map((feedback) => (
                                <div
                                    className="pastInterviews__commentWrapper"
                                    key={feedback.skill}
                                >
                                    <Typography
                                        variant="h3"
                                        fontSize="1rem"
                                        fontWeight="400"
                                        mb="0.5rem"
                                    >
                                        {feedback.skill}
                                    </Typography>
                                    <TextField
                                        label="."
                                        className="pastInterviews__commentField"
                                        placeholder={`The interviewer's comments on your ${feedback.skill}.`}
                                        multiline
                                        rows={4}
                                        value={feedback.comment}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{ shrink: false }}
                                    />
                                    <div className="pastInterviews__rating">
                                        Score:{' '}
                                        <Rating readOnly value={feedback.score || 0} />
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                )

            case 'CHAT':
                return (
                    <div className="pastInterviews__chatsTab">
                        {loadingChat ? (
                            <Loader />
                        ) : chatData.length === 0 ? (
                            <Typography
                                variant="h3"
                                fontSize="1rem"
                                fontWeight="400"
                                align="center"
                                mt="2rem"
                                style={{ color: 'var(--grey)' }}
                            >
                                No chat found for this interview!
                            </Typography>
                        ) : (
                            <div className="pastInterviews__chatsWrapper">
                                {chatData.map((message, index) => (
                                    <ChatMessage
                                        message={message}
                                        lastMessage={index > 0 && chatData[index - 1]}
                                        key={index}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                )

            case 'CODE':
                return (
                    <div className="pastInterviews__codeTab">
                        {loadingCode ? (
                            <Loader />
                        ) : !selectedInterviewData.code ? (
                            <Typography
                                variant="h3"
                                fontSize="1rem"
                                fontWeight="400"
                                align="center"
                                mt="2rem"
                                style={{ color: 'var(--grey)' }}
                            >
                                No code found for this interview!
                            </Typography>
                        ) : (
                            <AceEditor
                                height="90vh"
                                theme="github"
                                readOnly
                                name="ace__editor"
                                className="ace__editor"
                                fontSize={16}
                                placeholder="// your interview code will appear here..."
                                value={selectedInterviewData.code}
                                showPrintMargin={false}
                                showGutter={true}
                                setOptions={{
                                    showLineNumbers: true,
                                    tabSize: 2,
                                }}
                            />
                        )}
                    </div>
                )

            case 'QUESTION':
                return (
                    <div className="pastInterviews__questionTab">
                        {loadingQuestion ? (
                            <Loader />
                        ) : !askedQuestion ? (
                            <Typography
                                variant="h3"
                                fontSize="1rem"
                                fontWeight="400"
                                align="center"
                                mt="2rem"
                                style={{ color: 'var(--grey)' }}
                            >
                                No question found for this interview!
                            </Typography>
                        ) : (
                            <>
                                <Typography
                                    variant="h2"
                                    fontSize="1.1rem"
                                    fontWeight="400"
                                    letterSpacing="-0.025em"
                                    mt="1rem"
                                    ml="1.6rem"
                                    color="grey.dark"
                                >
                                    {askedQuestion.name}
                                </Typography>
                                <div className="pastInterviews__markdownWrapper">
                                    <ReactMarkdown>
                                        {askedQuestion.description}
                                    </ReactMarkdown>
                                </div>
                            </>
                        )}
                    </div>
                )

            default:
                return null
        }
    }

    return (
        <Modal open={isModalOpen} onClose={handleModalClose}>
            <Box className="pastInterviews__modal">
                <div className="pastInterviews__modalContentWrapper">
                    <div className="pastInterviews__left">{SelectedSection()}</div>

                    <div className="pastInterviews__right">
                        <IconButton
                            className="pastInterviews__closeButton"
                            onClick={handleModalClose}
                        >
                            <CloseIcon />
                        </IconButton>
                        <div className="pastInterviews__overall">
                            <div>
                                {moment(selectedInterviewData.start_time * 1000).format(
                                    'HH:mm A'
                                )}
                            </div>
                            <div>
                                {moment(selectedInterviewData.start_time * 1000).format(
                                    'DD MMM YYYY'
                                )}
                            </div>
                            <div>{selectedInterviewData.type}</div>
                            <div className="pastInterviews__overallRating">
                                <Rating
                                    size="large"
                                    readOnly
                                    precision={0.1}
                                    value={calculateOverallScore()}
                                />
                            </div>
                        </div>
                        <div className="pastInterviews__modalButtonWrapper">
                            <ButtonComp
                                variant={selectedButtonVariant('COMMENTS')}
                                handleOnClick={() => {
                                    setSection('COMMENTS')
                                }}
                            >
                                Detailed Feedback
                            </ButtonComp>
                        </div>
                        <div className="pastInterviews__modalButtonWrapper">
                            <ButtonComp
                                variant={selectedButtonVariant('CHAT')}
                                handleOnClick={() => {
                                    setSection('CHAT')
                                }}
                            >
                                View Chat
                            </ButtonComp>
                        </div>
                        <div className="pastInterviews__modalButtonWrapper">
                            <ButtonComp
                                variant={selectedButtonVariant('QUESTION')}
                                handleOnClick={() => {
                                    setSection('QUESTION')
                                }}
                            >
                                View Asked Question
                            </ButtonComp>
                        </div>
                        <div className="pastInterviews__modalButtonWrapper">
                            <ButtonComp
                                variant={selectedButtonVariant('CODE')}
                                handleOnClick={() => {
                                    setSection('CODE')
                                }}
                            >
                                View Your Code
                            </ButtonComp>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default PastInterviewsModal

import { useState, useRef, useEffect, useContext, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import * as socketFx from './socket'
import * as actions from '../../store/actions/meeting'
import { codeLanguageChange, syncCodeStoreData } from '../../store/actions/code'
import { toggleCodeResultPanel } from '../../store/actions/ui'
import { MeetContext } from './MeetContext'
import blackSilence from './blackSilence'
import ConfirmModal from '../utils/ConfirmModal/ConfirmModal'
import toastNotification from '../utils/Notification/Notification'
import InternetSpeedPopup from './InternetSpeedPopup/InternetSpeedPopup'
import Meeting from './Meeting'
import { segment } from '../../utils/selfieSegmentation'

const MeetingConnection = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { roomID } = useParams()

    const {
        userVideoRef,
        remoteVideoRef,
        userScreenRef,
        remoteScreenRef,
        videoDevice,
        audioDevice,
        meetInfo,
    } = useContext(MeetContext)

    const code = useSelector((state) => state.code)
    const meeting = useSelector((state) => state.meeting)
    const { token, events, isInterviewEnded, isBackgroundBlur } = meeting

    if (meeting.token === null) {
        window.location.href = `/interview-verify/${roomID}`
    }

    const [remoteAudio, setRemoteAudio] = useState(true)
    const [remoteVideo, setRemoteVideo] = useState(true)
    const [isOnline, setIsOnline] = useState(true)
    const [flip, setFlip] = useState(false)
    const [isCodeStoreSync, setIsCodeStoreSync] = useState(false)
    const rtcPeerConnection = useRef()
    const rtcPeerConnectionScreen = useRef()

    /* to stop user from re-entering the expired meet by pressing the browser's back button
     when the user is on feedback page */
    useEffect(() => {
        if (isInterviewEnded) {
            history.push('/feedback')
        }
    }, [isInterviewEnded, history])

    useEffect(() => {
        const reqData = {
            MeetId: roomID,
            UserId: meeting.userID,
        }
        dispatch(actions.toggleShowRoleModal())
        dispatch(actions.getSocketData(reqData)).then((data) => {
            setRemoteAudio(data.socketData.audio)
            setRemoteVideo(data.socketData.video)
            dispatch(codeLanguageChange(data.socketData.lang))
        })
    }, [roomID, meeting.userID, dispatch])

    const startLocalStream = useCallback(async () => {
        userScreenRef.current.srcObject = blackSilence()
        remoteScreenRef.current.srcObject = blackSilence()

        await navigator.mediaDevices
            .getUserMedia({
                video: {
                    deviceId: videoDevice,
                    width: { ideal: 640 },
                    height: { ideal: 360 },
                },
                audio: { deviceId: audioDevice },
            })
            .then((stream) => {
                userVideoRef.current.srcObject = stream
                userVideoRef.current.srcObject
                    .getAudioTracks()
                    .forEach((track) => (track.enabled = meeting.audio))
                userVideoRef.current.srcObject
                    .getVideoTracks()
                    .forEach((track) => (track.enabled = meeting.video))
            })
            .catch((error) => {
                console.error('Could not get user media', error)
            })
    }, [
        videoDevice,
        audioDevice,
        userScreenRef,
        remoteScreenRef,
        userVideoRef,
        meeting.audio,
        meeting.video,
    ])

    useEffect(() => {
        if (!isBackgroundBlur) return

        const videoElement = userVideoRef.current
        if (videoElement) {
            videoElement.onplaying = async () => {
                let lastTime = new Date()

                async function getFrames() {
                    const now = videoElement.currentTime
                    if (now > lastTime) {
                        await segment({
                            videoElement,
                            canvasElementID: 'meetingCanvasID',
                        })
                    }
                    lastTime = now
                    requestAnimationFrame(getFrames)
                }

                await getFrames()
            }
        }
    }, [userVideoRef, isBackgroundBlur])

    // to sending current state to room
    useEffect(() => {
        socketFx.myAudio(meeting.events.audio, token, meeting.audio)
        socketFx.myVideo(meeting.events.video, token, meeting.video)
    }, [token, meeting.audio, meeting.events.audio, meeting.events.video, meeting.video])

    const startLocalScreen = useCallback(async () => {
        try {
            navigator.mediaDevices
                .getDisplayMedia({
                    video: true,
                })
                .then((stream) => {
                    stream.getVideoTracks()[0].addEventListener('ended', () => {
                        dispatch(actions.toggleShareScreen())
                        socketFx.leaveRoom(events.leaveScreen, token, false)
                        socketFx.myScreen(meeting.events.screenShare, token)
                    })

                    userScreenRef.current.srcObject = stream
                    socketFx.startCallScreen(events.startCallScreen, token)
                })
                .catch((err) => {
                    console.log("Didn't got the screen to share! ", err)
                    dispatch(actions.toggleShareScreen())
                    socketFx.myScreen(meeting.events.screenShare, token)
                })
        } catch (e) {
            console.log('Unable to acquire screen capture: ' + e)
        }
    }, [
        dispatch,
        events.leaveScreen,
        events.startCallScreen,
        meeting.events.screenShare,
        token,
        userScreenRef,
    ])

    const startRemoteStream = useCallback(
        (event) => {
            remoteVideoRef.current.srcObject = event.streams[0]
        },
        [remoteVideoRef]
    )

    const startRemoteScreen = useCallback(
        (event) => {
            remoteScreenRef.current.srcObject = event.streams[0]
        },
        [remoteScreenRef]
    )

    const addLocalTracks = useCallback(() => {
        if (userVideoRef.current.srcObject) {
            if (isBackgroundBlur) {
                const canvasElement = document.getElementById('meetingCanvasID')
                const processedStream = canvasElement.captureStream(30) // 30 FPS

                processedStream.getTracks().forEach((track) => {
                    rtcPeerConnection.current.addTrack(track, processedStream)
                })
            } else {
                userVideoRef.current.srcObject.getTracks().forEach((track) => {
                    rtcPeerConnection.current.addTrack(
                        track,
                        userVideoRef.current.srcObject
                    )
                })
            }
        }
    }, [userVideoRef, isBackgroundBlur])

    const addLocalScreenTracks = useCallback(() => {
        userScreenRef.current.srcObject.getTracks().forEach((track) => {
            rtcPeerConnectionScreen.current.addTrack(
                track,
                userScreenRef.current.srcObject
            )
        })
    }, [userScreenRef])

    const updateUserOnlineStatus = () => {
        setIsOnline(navigator.onLine)
    }

    useEffect(() => {
        window.addEventListener('online', updateUserOnlineStatus)
        window.addEventListener('offline', updateUserOnlineStatus)
        return () => {
            window.removeEventListener('online', updateUserOnlineStatus)
            window.removeEventListener('offline', updateUserOnlineStatus)
        }
    }, [])

    useEffect(() => {
        if (meeting.shareScreen) {
            startLocalScreen()
        }
    }, [meeting.shareScreen, startLocalScreen])

    useEffect(() => {
        //screen share functions here
        socketFx.onRoomCreateScreen(events.roomCreatedScreen, () => {
            socketFx.startCallScreen(events.startCallScreen, token)
        })

        socketFx.onRoomJoinScreen(events.roomJoinedScreen, () => {
            socketFx.startCallScreen(events.startCallScreen, token)
        })

        socketFx.onStartCallScreen(
            events.startCallScreen,
            meeting.roomCreator,
            async (iceServers) => {
                rtcPeerConnectionScreen.current = new RTCPeerConnection(iceServers)
                await addLocalScreenTracks()
                rtcPeerConnectionScreen.current.ontrack = startRemoteScreen
                rtcPeerConnectionScreen.current.onicecandidate = (e) => {
                    socketFx.sendIceCandidateScreen(
                        events.webrtcIceCandidateScreen,
                        e,
                        token
                    )
                }
                await socketFx.sendWebRTCOfferScreen(
                    events.webrtcOfferScreen,
                    rtcPeerConnectionScreen.current,
                    token
                )
            }
        )

        socketFx.onWebRTCOfferScreen(
            events.webrtcOfferScreen,
            meeting.roomCreator,
            async (event, iceServers) => {
                rtcPeerConnectionScreen.current = new RTCPeerConnection(iceServers)
                await addLocalScreenTracks()
                rtcPeerConnectionScreen.current.ontrack = startRemoteScreen
                rtcPeerConnectionScreen.current.onicecandidate = (e) => {
                    socketFx.sendIceCandidateScreen(
                        events.webrtcIceCandidateScreen,
                        e,
                        token
                    )
                }
                rtcPeerConnectionScreen.current.setRemoteDescription(
                    new RTCSessionDescription(event)
                )
                await socketFx.sendWebRTCAnswerScreen(
                    events.webrtcAnswerScreen,
                    rtcPeerConnectionScreen.current,
                    token
                )
            }
        )

        socketFx.onIceCandidateScreen(events.webrtcIceCandidateScreen, (event) => {
            const candidate = new RTCIceCandidate({
                sdpMLineIndex: event.label,
                candidate: event.candidate,
            })
            rtcPeerConnectionScreen.current.addIceCandidate(candidate)
        })

        socketFx.onWebRTCAnswerScreen(events.webrtcAnswerScreen, (event) => {
            rtcPeerConnectionScreen.current.setRemoteDescription(
                new RTCSessionDescription(event)
            )
        })
    }, [
        addLocalScreenTracks,
        events.roomCreatedScreen,
        events.roomJoinedScreen,
        events.startCallScreen,
        events.webrtcAnswerScreen,
        events.webrtcIceCandidateScreen,
        events.webrtcOfferScreen,
        startRemoteScreen,
        token,
        meeting.roomCreator,
    ])

    useEffect(() => {
        socketFx.onRoomCreate(events.roomCreated, async () => {
            await startLocalStream()
        })

        socketFx.onRoomJoin(events.roomJoined, async () => {
            await startLocalStream()
            socketFx.startCall(events.startCall, token)
        })

        socketFx.onStartCall(events.startCall, meeting.roomCreator, (iceServers) => {
            rtcPeerConnection.current = new RTCPeerConnection(iceServers)
            addLocalTracks()

            rtcPeerConnection.current.ontrack = startRemoteStream // track of other meeting here

            rtcPeerConnection.current.onicecandidate = (e) => {
                socketFx.sendIceCandidate(events.webrtcIceCandidate, e, token)
            }

            rtcPeerConnection.current.oniceconnectionstatechange = function () {
                if ('disconnected' === rtcPeerConnection.current.iceConnectionState) {
                    rtcPeerConnection.current.close()
                }
            }

            socketFx.sendWebRTCOffer(events.webrtcOffer, rtcPeerConnection.current, token)
        })

        socketFx.onWebRTCOffer(
            events.webrtcOffer,
            meeting.roomCreator,
            (event, iceServers) => {
                rtcPeerConnection.current = new RTCPeerConnection(iceServers)
                rtcPeerConnection.current.ontrack = startRemoteStream

                rtcPeerConnection.current.onicecandidate = (e) => {
                    socketFx.sendIceCandidate(events.webrtcIceCandidate, e, token)
                }
                rtcPeerConnection.current.setRemoteDescription(
                    new RTCSessionDescription(event)
                )

                addLocalTracks()

                rtcPeerConnection.current.oniceconnectionstatechange = function () {
                    if ('disconnected' === rtcPeerConnection.current.iceConnectionState) {
                        rtcPeerConnection.current.close()
                    }
                }

                socketFx.sendWebRTCAnswer(
                    events.webrtcAnswer,
                    rtcPeerConnection.current,
                    token
                )
            }
        )

        socketFx.onIceCandidate(events.webrtcIceCandidate, (event) => {
            const candidate = new RTCIceCandidate({
                sdpMLineIndex: event.label,
                candidate: event.candidate,
            })
            rtcPeerConnection.current.addIceCandidate(candidate)
        })

        socketFx.onWebRTCAnswer(events.webrtcAnswer, (event) => {
            rtcPeerConnection.current.setRemoteDescription(
                new RTCSessionDescription(event)
            )
        })
    }, [
        addLocalTracks,
        events.roomCreated,
        events.roomJoined,
        events.startCall,
        events.webrtcAnswer,
        events.webrtcIceCandidate,
        events.webrtcOffer,
        startLocalStream,
        startRemoteStream,
        token,
        meeting.roomCreator,
    ])

    useEffect(() => {
        socketFx.getFlipRoleRequest(events.flipRole, () => {
            setFlip(true)
        })
        socketFx.kickOut(events.kickOut, () => {
            toastNotification('You are connected from other device.')
            window.location.href = '/'
        })
        socketFx.onDrawingBoardSignal(events.drawingBoardSignal, (isWhiteBoardOn) => {
            if (isWhiteBoardOn) {
                toastNotification('Opening Whiteboard.')

                // stopping screen share if it is on
                if (meeting.shareScreen) {
                    userScreenRef.current.srcObject
                        .getTracks()
                        .forEach((track) => track.stop())
                    dispatch(actions.toggleShareScreen())
                    socketFx.myScreen(meeting.events.screenShare, token)
                }
            } else {
                toastNotification('Closing Whiteboard.')
            }
            dispatch(actions.toggleDrawingBoard(isWhiteBoardOn))
        })

        socketFx.getFlipAction(events.flipRoleAction, (userID, value) => {
            if (value) {
                dispatch(actions.swapRole(meetInfo))
            } else {
                toastNotification('Other user declined from flipping roles!')
            }
        })
        socketFx.userAudio(meeting.events.audio, (audioVar) => {
            setRemoteAudio(audioVar)
        })
        socketFx.userVideo(meeting.events.video, (videoVar) => {
            setRemoteVideo(videoVar)
        })
        socketFx.userScreen(meeting.events.screenShare, () => {
            dispatch(actions.toggleUserShareScreen())
        })

        socketFx.onInvalid(events.invalid, () => {
            toastNotification('Error: invalid')
        })

        socketFx.onCodeCompile(events.compileCode, () => {
            dispatch(toggleCodeResultPanel(true))
        })

        socketFx.onSyncCodeStore(events.syncCodeStore, (codeStoreData) => {
            dispatch(syncCodeStoreData(codeStoreData))
        })

        socketFx.joinRoom(events.join, token)
    }, [
        dispatch,
        events.drawingBoardSignal,
        events.flipRole,
        events.flipRoleAction,
        events.invalid,
        events.join,
        events.kickOut,
        events.compileCode,
        events.syncCodeStore,
        token,
        meeting.events.audio,
        meeting.events.screenShare,
        meeting.events.video,
        meetInfo.roomID,
        meetInfo,
        meeting.shareScreen,
        userScreenRef,
    ])

    // to sync the code store data in realtime
    useEffect(() => {
        if (code.compileCodeLoading) setIsCodeStoreSync(true)
        else setIsCodeStoreSync(false)

        if (isCodeStoreSync) {
            const codeStoreDataToSync = {
                compileCodeLoading: code.compileCodeLoading,
                compileStatus: code.compileStatus,
                result: code.result,
            }
            socketFx.emitCodeStoreSync(events.syncCodeStore, token, codeStoreDataToSync)
        }
    }, [
        code.result,
        code.compileStatus,
        code.compileCodeLoading,
        events.syncCodeStore,
        token,
        isCodeStoreSync,
    ])

    // to show notifications to remote user for screen share
    useEffect(() => {
        if (meeting.userShareScreen) {
            toastNotification('Other user has started sharing screen.')
        }
    }, [meeting.userShareScreen])

    return (
        <>
            <Meeting
                remoteAudio={remoteAudio}
                remoteVideo={remoteVideo}
                isOnline={isOnline}
            />

            <ConfirmModal
                isOpen={flip}
                handleOnCancel={() => {
                    socketFx.sendFlipAction(meeting.events.flipRoleAction, token, false)
                }}
                setIsOpen={setFlip}
                title="The other user want to switch the roles are you sure ?"
                handleOnConfirm={() => {
                    socketFx.sendFlipAction(meeting.events.flipRoleAction, token, true)
                    dispatch(actions.swapRole(meetInfo))
                }}
            />

            <InternetSpeedPopup />
        </>
    )
}

export default MeetingConnection

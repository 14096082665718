const FEATURES_DATA = [
    {
        id: 1,
        icon: 'VIDEO_CAMERA',
        title: 'Live Video',
        content: 'Video, audio and screen sharing capabilities over a secure network.',
    },
    {
        id: 2,
        icon: 'BEST_MATCH',
        title: 'Advanced Matching',
        content:
            'Our algorithm factors your experience, availability and preferences to set up the ideal interview.',
    },
    {
        id: '3',
        icon: 'CODE_EDITOR',
        title: 'Collaborative Code Editor',
        content: 'A collaborative code editor supporting multiple programming languages.',
    },
    {
        id: '4',
        icon: 'MONITOR_ROOM',
        title: 'Verified users',
        content: 'Users are vetted with both email and phone verification.',
    },
    {
        id: '5',
        icon: 'RATING',
        title: 'Ratings',
        content: 'Your rating changes after every interview based on your performance.',
    },
    {
        id: '6',
        icon: 'FREE',
        title: 'Free',
        content: "That's right, it's FREE! No subscriptions needed.",
    },
    {
        id: '7',
        icon: 'DETAILED_FEEDBACK',
        title: 'Detailed Feedback',
        content:
            'Analyse your mistakes and track your progress with detailed feedback and reports.',
    },
    {
        id: '8',
        icon: 'CLOCK',
        title: 'Gentle Reminders',
        content:
            'Set time on your calendar and never miss an interview with our email reminders.',
    },
    {
        id: '9',
        icon: 'SWAP_ARROWS',
        title: 'Two-Way Interviews',
        content: 'You get to be interviewer for one half, and interviewee for the other!',
    },
]

export default FEATURES_DATA

import React from 'react'
import { Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import ButtonComp from '../../utils/ButtonComp/ButtonComp'
import { toggleSchedulerModal } from '../../../store/actions/ui'
import './CallToAction.css'

const CallToAction = () => {
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.userInfo)

    return (
        <div className="callToAction">
            <Typography
                variant="h2"
                color="grey.dark"
                align="center"
                fontSize="2.25rem"
                letterSpacing="-0.025em"
                fontWeight="800"
                mb="2rem"
                className="callToAction__heading"
                data-testid="want-to-get-interviewready"
            >
                Want to get interview ready?
            </Typography>

            <div className="callToAction__buttonWrapper" data-testid="schedule-interview">
                <ButtonComp
                    size="lg"
                    handleOnClick={() => {
                        dispatch(toggleSchedulerModal())
                    }}
                    disabled={user?.mockInterviewBan}
                >
                    Schedule An Interview!
                </ButtonComp>
            </div>
        </div>
    )
}

export default CallToAction

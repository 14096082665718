import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Box,
    Grid,
    Typography,
    Modal,
    LinearProgress,
    Paper,
    IconButton,
    Select,
    MenuItem,
} from '@mui/material'
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'

import { cancelInterview } from '../../../store/actions/interview'
import ButtonComp from '../../utils/ButtonComp/ButtonComp'
import { CANCELLATION_REASONS } from '../../../constants/vars'

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
}))

const InterviewActionModal = ({
    isInterviewActionModalOpen,
    selectedInterviewDetail,
    closeInterviewActionModal,
}) => {
    const dispatch = useDispatch()

    const { cancelInterviewLoading, cancelInterviewSuccess } = useSelector(
        (state) => state.interview
    )

    const [reason, setReason] = useState('')

    useEffect(() => {
        if (cancelInterviewSuccess) {
            closeInterviewActionModal()
        }
    }, [cancelInterviewSuccess, closeInterviewActionModal])

    // when countdown is ended
    const handleCancelInterviewSubmit = () => {
        const reqBody = {
            interviewID: selectedInterviewDetail.id,
            interviewStatus: selectedInterviewDetail.status,
            cancelReason: reason,
        }
        dispatch(cancelInterview(reqBody))
    }

    return (
        <Modal
            open={isInterviewActionModalOpen}
            onClose={(_, reason) => {
                if (reason !== 'backdropClick') {
                    closeInterviewActionModal()
                }
            }}
        >
            <Box className="upcomingInterviews__modalWrapper">
                {cancelInterviewLoading && <LinearProgress />}
                <div className="upcomingInterviews__paddingBox">
                    <IconButton
                        onClick={closeInterviewActionModal}
                        className="upcomingInterviews__modalCloseButton"
                    >
                        <CloseIcon color="grey" />
                    </IconButton>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Item
                                className="upcomingInterviews__modalDateBox"
                                sx={{
                                    background: selectedInterviewDetail.status
                                        ? 'var(--indigo-700)'
                                        : 'var(--indigo-100)',
                                    color: selectedInterviewDetail.status
                                        ? 'var(--white)'
                                        : 'var(--black)',
                                }}
                            >
                                <Typography>
                                    {moment(
                                        selectedInterviewDetail.starttime * 1000
                                    ).format('MMMM')}
                                </Typography>
                                <Typography fontSize="1.2rem">
                                    {moment(
                                        selectedInterviewDetail.starttime * 1000
                                    ).format('DD')}
                                </Typography>
                                <Typography>
                                    {moment(
                                        selectedInterviewDetail.starttime * 1000
                                    ).format('YYYY')}
                                </Typography>
                            </Item>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Item>
                                <Typography variant="body1">Interview Type</Typography>
                                <Typography
                                    variant="body1"
                                    component="h2"
                                    color="primary"
                                    data-cy="interview-details-type"
                                    className="upcomingInterviews__modalInterviewType"
                                    align="center"
                                >
                                    {selectedInterviewDetail.type}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    color="grey"
                                    data-cy="interview-details-time"
                                >
                                    {moment(
                                        selectedInterviewDetail.starttime * 1000
                                    ).format('hh:mm A')}
                                </Typography>
                            </Item>
                        </Grid>
                    </Grid>
                    {selectedInterviewDetail.status === 1 && (
                        <div className="upcomingInterviews__cancellationReason">
                            <div className="upcomingInterviews__cancellationText">
                                <Typography variant="body1" component="h2" align="left">
                                    Please mention the reason of your cancellation
                                </Typography>
                            </div>
                            <Select
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                                label="reason"
                            >
                                {CANCELLATION_REASONS.map((data) => (
                                    <MenuItem key={data} value={data}>
                                        {data}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    )}
                    <div className="upcomingInterviews__modalActionButtonsWrapper">
                        {
                            <ButtonComp
                                data-cy="cancel-interview"
                                variant="secondary"
                                handleOnClick={handleCancelInterviewSubmit}
                                disabled={!reason}
                            >
                                {selectedInterviewDetail.status === 0
                                    ? 'Cancel this interview request'
                                    : 'Cancel this interview'}
                            </ButtonComp>
                        }
                        {selectedInterviewDetail.status === 1 && (
                            <a
                                className="upcomingInterviews__modalJoinButton"
                                href={`${window.location.origin}/interview-verify/${selectedInterviewDetail.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Join now
                            </a>
                        )}
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default InterviewActionModal

import * as actionTypes from '../actionTypes'
import {
    parseFeedbackData,
    retrieveFeedbackData,
    retrieveReceivedFeedbackData,
} from '../../utils/getFeedbackParams'
import axiosInstance from '../../service/axios'
import toastNotification from '../../components/utils/Notification/Notification'

export const feedbackSetData = (feedbackComments) => (dispatch) => {
    dispatch({ type: actionTypes.SET_FEEDBACK_COMMENTS, payload: feedbackComments })
}

export const feedbackSave =
    ({ feedbackData, meetInfo, autosave = true }) =>
    async (dispatch) => {
        const parsedFeedbackBody = parseFeedbackData({ ...feedbackData, meetInfo })

        try {
            dispatch({
                type: actionTypes.SAVE_FEEDBACK_DATA_REQUEST,
                payload: parsedFeedbackBody,
            })

            // api call here
            await axiosInstance.post('api/v1/feedback/add', parsedFeedbackBody)

            if (!autosave) {
                dispatch({ type: actionTypes.SAVE_FEEDBACK_DATA_SUCCESS })
            }
        } catch (err) {
            dispatch({ type: actionTypes.SAVE_FEEDBACK_DATA_FAIL, payload: err.message })
        }
    }

export const feedbackResetForm = () => async (dispatch) => {
    dispatch({ type: actionTypes.FEEDBACK_FORM_RESET })
}

export const getReceivedFeedback =
    ({ meetingID, userID, interviewType }) =>
    async (dispatch) => {
        try {
            dispatch({
                type: actionTypes.GET_HISTORY_FEEDBACK_REQUEST,
                payload: { meetingID, userID, interviewType },
            })

            // api call here
            const { data } = await axiosInstance.get(`api/v1/feedback/${meetingID}`)

            // extracting the feedback received by other user
            const retrievedFeedbackBody = retrieveReceivedFeedbackData({
                responseBody: data,
                userID,
                interviewType,
            })

            dispatch({
                type: actionTypes.GET_HISTORY_FEEDBACK_SUCCESS,
                payload: retrievedFeedbackBody,
            })
        } catch (err) {
            dispatch({
                type: actionTypes.GET_HISTORY_FEEDBACK_FAIL,
                payload: err.message,
            })
            toastNotification(
                'Error in fetching feedback for this interview! Try again later.'
            )
        }
    }
export const feedbackGetSaved = (meetInfo) => async (dispatch) => {
    const meetingID = meetInfo.roomID

    try {
        dispatch({ type: actionTypes.GET_SAVED_FEEDBACK_REQUEST, payload: { meetingID } })

        // api call here
        const { data } = await axiosInstance.get(`api/v1/feedback/${meetingID}`)

        // extracting the feedback given to other user
        const retrievedFeedbackBody = retrieveFeedbackData({
            responseBody: data,
            meetInfo,
        })

        dispatch({
            type: actionTypes.GET_SAVED_FEEDBACK_SUCCESS,
            payload: retrievedFeedbackBody,
        })
    } catch (err) {
        dispatch({ type: actionTypes.GET_SAVED_FEEDBACK_FAIL, payload: err.message })
    }
}

export const getAskedQuestion = (questionID) => async (dispatch) => {
    if (Number(questionID) === 0) {
        dispatch({
            type: actionTypes.GET_ASKED_QUESTION_FAIL,
            payload: 'No question found!',
        })
        return
    }

    try {
        dispatch({ type: actionTypes.GET_ASKED_QUESTION_REQUEST })
        const { data } = await axiosInstance.get(`/api/v1/question/${questionID}`)
        dispatch({ type: actionTypes.GET_ASKED_QUESTION_SUCCESS, payload: data })
    } catch (err) {
        dispatch({ type: actionTypes.GET_ASKED_QUESTION_FAIL, payload: err.message })
        toastNotification(
            'Error in fetching your question for this interview! Try again later.'
        )
    }
}

import * as actionTypes from '../actionTypes'
import { INTERVIEW_TYPES } from '../../constants/vars'

const initialRatings = Object.keys(INTERVIEW_TYPES).reduce(
    (acc, curr) => ({ ...acc, [INTERVIEW_TYPES[curr]]: [] }),
    {}
)

const initialState = {
    loading: false,
    error: null,
    ratings: initialRatings,
}

export const ratingReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_RATING_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case actionTypes.GET_USER_RATING_SUCCESS:
            const ratingsData = Object.keys(action.payload).reduce(
                (acc, curr) => ({
                    ...acc,
                    [curr]: action.payload[curr] ? action.payload[curr] : [],
                }),
                {}
            )
            return {
                ...state,
                loading: false,
                ratings: ratingsData,
            }

        case actionTypes.GET_USER_RATING_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }

        default:
            return state
    }
}

export default ratingReducer

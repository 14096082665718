import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Paper,
    Rating,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    CircularProgress,
    Grid,
    Pagination,
} from '@mui/material'
import moment from 'moment'

import { getMeetingChat } from '../../../store/actions/chat'
import { getInterviewCode } from '../../../store/actions/code'
import { getReceivedFeedback, getAskedQuestion } from '../../../store/actions/feedback'
import ButtonComp from '../../utils/ButtonComp/ButtonComp'
import PastInterviewsModal from './PastInterviewsModal'
import './PastInterviews.css'

const getInterviewer = (users, loggedInUserID) => {
    return users.filter((user) => user.id !== loggedInUserID)[0]
}

const PastInterviews = () => {
    const dispatch = useDispatch()

    const { expiredMeetings, loading: loadingMeetings } = useSelector(
        (state) => state.meetings
    )
    const { user } = useSelector((state) => state.userInfo)
    const { code } = useSelector((state) => state.code)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [pageNo, setPageNo] = useState(1)
    const [selectedInterviewData, setSelectedInterviewData] = useState({})

    const handleViewFeedback = (meeting) => {
        setSelectedInterviewData(meeting)
        setIsModalOpen(true)
        const reqBody = {
            meetingID: meeting.id,
            userID: user.id,
        }
        dispatch(getReceivedFeedback(reqBody))
        dispatch(getMeetingChat(meeting.id))
        dispatch(getInterviewCode(reqBody))
        dispatch(getAskedQuestion(meeting.question_id))
    }

    useEffect(() => {
        setSelectedInterviewData((data) => ({
            ...data,
            code: code || 'No code found!',
        }))
    }, [code])

    return (
        <>
            <div className="pastInterviews">
                <Typography
                    variant="h1"
                    fontSize="1.8rem"
                    fontWeight="500"
                    mb="2rem"
                    align="center"
                    className="mobile-heading"
                >
                    Interview History
                </Typography>

                {loadingMeetings ? (
                    <Box
                        sx={{ display: 'grid', placeItems: 'center', minHeight: '100px' }}
                    >
                        <CircularProgress />
                    </Box>
                ) : expiredMeetings.length === 0 ? (
                    <Typography
                        variant="h3"
                        fontSize="1.1rem"
                        fontWeight="400"
                        align="center"
                        style={{ color: 'var(--grey)' }}
                        mb="2rem"
                    >
                        No interviews taken yet. Go and book one now!
                    </Typography>
                ) : (
                    <div className="pastInterviews__tableWrapper">
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>S. No.</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Time</TableCell>
                                        <TableCell>Interview Type</TableCell>
                                        <TableCell>Interviewer</TableCell>
                                        <TableCell>Overall Score</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {expiredMeetings.map(
                                        (meeting, key) =>
                                            (pageNo - 1) * 10 <= key &&
                                            pageNo * 10 > key && (
                                                <TableRow key={meeting.id}>
                                                    <TableCell component="th" scope="row">
                                                        {key + 1}
                                                    </TableCell>
                                                    <TableCell>
                                                        {moment(
                                                            meeting.start_time * 1000
                                                        ).format('DD/MM/YYYY')}
                                                    </TableCell>
                                                    <TableCell>
                                                        {moment(
                                                            meeting.start_time * 1000
                                                        ).format('hh:mm a')}
                                                    </TableCell>
                                                    <TableCell>{meeting.type}</TableCell>
                                                    <TableCell>
                                                        {
                                                            getInterviewer(
                                                                [
                                                                    meeting.candidate,
                                                                    meeting.interviewer,
                                                                ],
                                                                user.id
                                                            ).firstName
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        <Rating
                                                            readOnly
                                                            precision={0.1}
                                                            value={meeting.stars}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <ButtonComp
                                                            variant="secondary"
                                                            handleOnClick={() =>
                                                                handleViewFeedback(
                                                                    meeting
                                                                )
                                                            }
                                                        >
                                                            View Feedback
                                                        </ButtonComp>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Grid
                            mt={2}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Pagination
                                count={Math.ceil(expiredMeetings.length / 10)}
                                page={pageNo}
                                onChange={(e, val) => {
                                    setPageNo(val)
                                }}
                                shape="rounded"
                            />
                        </Grid>
                    </div>
                )}
            </div>

            <PastInterviewsModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                selectedInterviewData={selectedInterviewData}
            />
        </>
    )
}

export default PastInterviews

import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import meetingReducer from './reducers/meeting'
import uiReducer from './reducers/ui'
import interviewReducer from './reducers/interview'
import schedulerReducer from './reducers/scheduler'
import ratingReducer from './reducers/rating'
import meetingsReducer from './reducers/meetings'
import userInfoReducer from './reducers/userInfo'
import feedbackReducer from './reducers/feedback'
import platformRatingReducer from './reducers/platformRating'
import chatReducer from './reducers/chat'
import codeReducer from './reducers/code'
import otpReducer from './reducers/otp'
import instantInterviewReducer from './reducers/instantInterview'
import promotionBannerReducer from './reducers/promotionBanner'

const rootReducer = combineReducers({
    ui: uiReducer,
    meeting: meetingReducer,
    interview: interviewReducer,
    scheduler: schedulerReducer,
    rating: ratingReducer,
    userInfo: userInfoReducer,
    feedback: feedbackReducer,
    platformRating: platformRatingReducer,
    meetings: meetingsReducer,
    chat: chatReducer,
    code: codeReducer,
    otp: otpReducer,
    instantInterview: instantInterviewReducer,
    promotionBanner: promotionBannerReducer,
})

const middleware = [thunk]

let middlewareWrapper

if (process.env.REACT_APP_ENV === 'DEV') {
    middlewareWrapper = composeWithDevTools(applyMiddleware(...middleware))
} else {
    middlewareWrapper = applyMiddleware(...middleware)
}

const store = createStore(rootReducer, middlewareWrapper)

export default store

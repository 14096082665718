import { useState, useRef, useEffect, useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    IconButton,
    Badge,
    Drawer,
    Typography,
    TextField,
    InputAdornment,
    Tooltip,
} from '@mui/material'
import ChatIcon from '@mui/icons-material/Chat'
import SendIcon from '@mui/icons-material/Send'
import CloseIcon from '@mui/icons-material/Close'

import { CHAT_MESSAGE_LENGTH } from '../../../constants/vars'
import { sendMessage, onNewMessage } from '../socket'
import ChatMessage from './ChatMessage'
import * as actions from '../../../store/actions/meeting'
import { MeetContext } from '../MeetContext'
import toastChatNotification from '../../utils/Notification/ChatNotification'
import notificationAudio from '../../../assets/audio/notification.mp3'
import './Chat.css'

const Chat = () => {
    const dispatch = useDispatch()
    const { meetInfo } = useContext(MeetContext)

    const meeting = useSelector((state) => state.meeting)
    const { token, events, chat } = meeting

    const messageEnd = useRef(null)
    const [msg, setMsg] = useState('')
    const [chatDisabled, setChatDisabled] = useState(false)
    const [isChatOpen, setIsChatOpen] = useState(false)

    const scrollToLatest = useCallback(() => {
        if (messageEnd && messageEnd.current) {
            messageEnd.current.scrollIntoView({ behaviour: 'smooth' })
        }
    }, [messageEnd])

    useEffect(() => {
        onNewMessage(events.newMessage, (userID, message, timestamp, senderName) => {
            dispatch(
                actions.messagePush({
                    type: 'message',
                    name: senderName,
                    message,
                    timestamp,
                })
            )
            if (!isChatOpen && senderName !== meetInfo.localUser.name) {
                toastChatNotification({ senderName, message })
                try {
                    const tone = new Audio(notificationAudio)
                    tone.play()
                } catch (err) {
                    console.log('New message received!')
                }
                dispatch(actions.messageUnreadCount(1))
            }
        })
    }, [events.newMessage, token, dispatch, isChatOpen, meetInfo])

    const sendMsg = () => {
        if (msg.trim().length > 0 && msg.length <= CHAT_MESSAGE_LENGTH) {
            setChatDisabled(true)
            sendMessage(events.newMessage, token, msg, meetInfo.localUser.name)
            setMsg('')
            let chatTimeDisabled = setTimeout(() => {
                setChatDisabled(false)
                clearTimeout(chatTimeDisabled)
            }, 1000)
        }
    }

    useEffect(() => {
        scrollToLatest()
    }, [chat, scrollToLatest, isChatOpen])

    const handleChatToggle = () => {
        setIsChatOpen((prev) => !prev)
        dispatch(actions.messageUnreadCount(0))
    }

    // to fetch earlier chats on meet re-join
    useEffect(() => {
        if (meetInfo && meetInfo.roomID) {
            dispatch(actions.chatInitialFetch(meetInfo.roomID))
        }
    }, [dispatch, meetInfo])

    const renderMessages = useCallback(
        () =>
            chat
                .filter((message) => message.type === 'message')
                .map((message, index) => (
                    <ChatMessage
                        message={message}
                        lastMessage={index > 0 && chat[index - 1]}
                        key={index}
                    />
                )),
        [chat]
    )

    return (
        <>
            <Tooltip title="Chat with peer" placement="top" arrow>
                <IconButton aria-label="Chat" onClick={handleChatToggle}>
                    <Badge badgeContent={meeting.unreadMessageCount} color="primary">
                        <ChatIcon fontSize="large" className="chat__chatButton" />
                    </Badge>
                </IconButton>
            </Tooltip>

            <Drawer
                anchor="right"
                ModalProps={{
                    keepMounted: true,
                }}
                variant="temporary"
                open={isChatOpen}
                onClose={handleChatToggle}
            >
                <div className="chat">
                    <div className="chat__header">
                        <Typography
                            variant="h1"
                            color="grey.dark"
                            letterSpacing="-0.025em"
                            fontSize="1.125rem"
                            lineHeight="1.75rem"
                            fontWeight="500"
                        >
                            In-Call Messages
                        </Typography>
                        <IconButton onClick={handleChatToggle}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div className="chat__messagesWrapper">
                        {renderMessages()}
                        <div ref={messageEnd} />
                    </div>
                    <div className="chat__inputWrapper">
                        <TextField
                            fullWidth
                            inputRef={(input) => input && input.focus()}
                            label={
                                msg.length > CHAT_MESSAGE_LENGTH
                                    ? 'Your message is too long...'
                                    : 'Your message'
                            }
                            error={msg.length > CHAT_MESSAGE_LENGTH}
                            variant="filled"
                            disabled={chatDisabled}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={sendMsg}
                                            disabled={
                                                !msg || msg.length > CHAT_MESSAGE_LENGTH
                                            }
                                        >
                                            <SendIcon
                                                color={
                                                    msg &&
                                                    msg.length <= CHAT_MESSAGE_LENGTH
                                                        ? 'primary'
                                                        : ''
                                                }
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            value={msg}
                            onChange={(e) => {
                                setMsg(e.target.value)
                            }}
                            onKeyDown={(e) => {
                                if (e.code === 'Enter') {
                                    sendMsg()
                                }
                            }}
                        />
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default Chat

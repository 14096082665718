import { useContext } from 'react'
import { useSelector } from 'react-redux'

import MeetNavbar from './MeetNavbar/MeetNavbar'
import Questions from './Questions/Questions'
import Freezed from './Freezed/Freezed'
import RoleModal from './RoleModal/RoleModal'
import MeetFooter from './MeetFooter/MeetFooter'
import CodeEditor from './CodeEditor/CodeEditor'
import InstantInterviewTimer from './InstantInterviewTimer/InstantInterviewTimer'
import { MeetContext } from './MeetContext'
import DrawingBoard from './DrawingBoard/DrawingBoard'
import ScreenShareVideo from './ScreenShareVideo/ScreenShareVideo'
import UserVideo from './UserVideo/UserVideo'
import Offline from './Offline/Offline'
import './Meeting.css'

const Meeting = ({ isOnline, remoteAudio, remoteVideo }) => {
    const { userVideoRef, remoteVideoRef, userScreenRef, remoteScreenRef, meetInfo } =
        useContext(MeetContext)

    const meeting = useSelector((state) => state.meeting)

    return (
        <div className="meeting">
            {meeting.freezedScreen && <Freezed />}
            {meeting.showRoleModal && <RoleModal />}
            {!isOnline && <Offline />}
            <>
                <MeetNavbar />
                <Questions>
                    <div className="meeting__container">
                        <div className="meeting__leftWrapper">
                            {meeting.drawingBoard && <DrawingBoard />}
                            <CodeEditor />
                            <ScreenShareVideo
                                isVisible={meeting.shareScreen}
                                videoRef={userScreenRef}
                            />
                            <ScreenShareVideo
                                isVisible={meeting.userShareScreen}
                                videoRef={remoteScreenRef}
                            />
                        </div>
                        <div className="meeting__rightWrapper">
                            {/* other user card */}
                            {meeting.roomUsersCount > 1 ? (
                                <UserVideo
                                    name={meetInfo.remoteUser.name}
                                    image={meetInfo.remoteUser.image}
                                    video={remoteVideo}
                                    audio={remoteAudio}
                                    videoRef={remoteVideoRef}
                                    isTester={!meeting.isTester}
                                />
                            ) : (
                                <InstantInterviewTimer />
                            )}
                            {/* own card */}
                            <UserVideo
                                isMute
                                needBlurCanvas
                                name={meetInfo.localUser.name}
                                image={meetInfo.localUser.image}
                                video={meeting.video}
                                audio={meeting.audio}
                                videoRef={userVideoRef}
                                isTester={meeting.isTester}
                            />
                        </div>
                    </div>
                </Questions>
                <MeetFooter />
            </>
        </div>
    )
}

export default Meeting

import * as actionTypes from '../actionTypes'

const initialState = {
    loading: false,
    status: '',
    meetingID: '',
}

const instantInterviewReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_INSTANT_INTERVIEW_REQUEST_STATUS:
            return {
                ...state,
                status: action.payload,
                loading: action.payload === 'REQUESTED',
                meetingID: action.payload === 'MATCHED' ? action.meetingID : '',
            }

        default:
            return state
    }
}

export default instantInterviewReducer
